import { BigNumber, ethers } from 'ethers'

export type Nullable<T> = T | null | undefined

export type BNish = BigNumber | string | number
export type BN = BigNumber

const Q112 = BigNumber.from(2).pow(112)

export function formattedDecimals(amount: BNish, decimals: number, symbol?: string): string {
  const units = parseFloat(ethers.utils.formatUnits(amount === '' ? '0' : amount, decimals)).toFixed(2)
  if (symbol && symbol.length > 0) {
    return `${units} ${symbol}`
  }
  return units
}

export function fromBNish(amount: BNish): BigNumber {
  return BigNumber.from(amount)
}

export function fromDecimals(amount: string, decimals: number): BigNumber {
  const _amount = amount.trim()
  return ethers.utils.parseUnits(_amount === '' ? '0' : _amount, decimals)
}

export function safeFromDecimals<T>(amount: string, decimals: number, defaultValue: T): BigNumber | T {
  try {
    return fromDecimals(amount, decimals)
  } catch (e) {
    console.log('safeFromDecimals', e)
    return defaultValue
  }
}

export function convertSellTokenToBuyToken(sellAmount: BNish, priceBuyInSellUQ: BNish): BigNumber {
  return BigNumber.from(sellAmount).mul(priceBuyInSellUQ).div(Q112)
}
