import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  defaultButton: {
    padding: 4,
    width: '100%',
    height: '78px',
    marginTop: '15px',
    background: colors.yellowLinearGradient,
    borderRadius: '10px',
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '1rem',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      background: colors.yellowLinearGradient,
      opacity: 0.6,
    },
    '&:active': {
      background: colors.yellowLinearGradient,
      opacity: 0.3,
    },
  },
  disabledButtonInner: {
    backgroundColor: colors.defaultBlack,
    width: '100%',
    height: '100%',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonTextDisabled: {
    fontSize: '1.5rem',
    fontWeight: 500,
    lineHeight: '29px',
    color: colors.primaryYellowFirst,
  },
  buttonText: {
    fontSize: '1.5rem',
    fontWeight: 500,
    lineHeight: '29px',
    color: colors.defaultWhite,
  },
  disabledButtonWrapper: {
    width: '100%',
    height: '74px',
    marginTop: '15px',
    background: colors.yellowLinearGradient,
    borderRadius: '10px',
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '1rem',
    padding: 2,
    cursor: 'pointer',

    '&:hover': {
      background: colors.yellowLinearGradient,
      opacity: 0.6,
    },
    '&:active': {
      background: colors.yellowLinearGradient,
      opacity: 0.3,
    },
  },
})
