import { BigNumber, BigNumberish, ethers } from 'ethers'
import { keccak256, solidityPack } from 'ethers/lib/utils'
import { TFunction } from 'i18next'
import withdrawEvents from '../assets/users/withdrawEvents.json'
import withdrawEvents2 from '../assets/users/withdrawEvents2.json'
import { MerkleTree } from './merkelTree'
import { expandTo18Decimals } from './NumberFormatter'

export const TOTAL_PAD = expandTo18Decimals(80000000)
export const VESTING_NUMERATOR = '19801980198'
export const VESTING_DENOMINATOR = '100000000000'
export const CLIFF_NUMERATOR = '20792079207'
export const CLIFF_DENOMINATOR = '100000000000'
export const ALL_TOKENS = '4522700274596000000000'
export const ALL_ETH = '2837222288120000000000'
export const SEED_ROUND_NUMERATOR = '180924353093'
export const SEED_ROUND_DENOMINATOR = '10000000'
export const CREATOR_CLIFF_PERCENTAGE = 10000
export const CREATOR_VESTING_DURATION = 0
export const CREATOR_VESTING_INTERVAL = 0

type WithdrawEvent = {
  amount: string
  sender: string
}

export function merklePresaleHash(
  address: string,
  totalAmount: BigNumberish,
  cliffAmount: BigNumberish,
  vestedPerInterval: BigNumberish,
): Buffer {
  const packed = solidityPack(
    ['address', 'uint256', 'uint256', 'uint256'],
    [address, totalAmount, cliffAmount, vestedPerInterval],
  )
  return Buffer.from(ethers.utils.arrayify(keccak256(packed)))
}

export function getVestingValues(user: string, total: string): [string, string] {
  const withdraw: WithdrawEvent[] = [...withdrawEvents]
  const withdraw2: WithdrawEvent[] = [...withdrawEvents2]

  const totalInPAD = BigNumber.from(total)

  if (totalInPAD.eq(BigNumber.from(0))) {
    return ['0', '0']
  }

  const totalCliffAmountInPAD = TOTAL_PAD.mul(CLIFF_NUMERATOR).div(CLIFF_DENOMINATOR)
  const totalVestedPerIntervalInPAD = TOTAL_PAD.mul(VESTING_NUMERATOR).div(VESTING_DENOMINATOR)
  const numberOfIntervals = TOTAL_PAD.sub(totalCliffAmountInPAD).div(totalVestedPerIntervalInPAD).toNumber()
  let vestedPerIntervalInPAD = totalInPAD.mul(VESTING_NUMERATOR).div(VESTING_DENOMINATOR)
  let cliffAmountInPADWithDust = totalInPAD.sub(vestedPerIntervalInPAD.mul(BigNumber.from(numberOfIntervals)))

  const withdrawEvent = withdraw.find((e) => e.sender.toLowerCase() == user.toLowerCase())
  const withdrawEvent2 = withdraw2.find((e) => e.sender.toLowerCase() == user.toLowerCase())

  if (withdrawEvent != null || withdrawEvent2 != null) {
    if (withdrawEvent == null && withdrawEvent2 != null) {
      cliffAmountInPADWithDust = cliffAmountInPADWithDust.sub(BigNumber.from(withdrawEvent2?.amount ?? 0))
      vestedPerIntervalInPAD = totalInPAD
        .sub(cliffAmountInPADWithDust)
        .sub(BigNumber.from(withdrawEvent2?.amount ?? 0))
        .div(BigNumber.from(numberOfIntervals))
    } else {
      vestedPerIntervalInPAD = totalInPAD
        .sub(BigNumber.from(withdrawEvent?.amount ?? 0))
        .sub(BigNumber.from(withdrawEvent2?.amount ?? 0))
        .div(BigNumber.from(numberOfIntervals))

      cliffAmountInPADWithDust = BigNumber.from(0)
    }
  }

  return [cliffAmountInPADWithDust.toString(), vestedPerIntervalInPAD.toString()]
}

export function merklePoolHash(address: string, totalAmount: BigNumberish): Buffer {
  const packed = solidityPack(['address', 'uint256'], [address, totalAmount])
  return Buffer.from(ethers.utils.arrayify(keccak256(packed)))
}

export function merklePoolParticipantMaxAllocationHash(participant: string, maxAllocation: BigNumberish): Buffer {
  // console.log('1111', maxAllocation)
  const packed = solidityPack(['address', 'uint256'], [participant, maxAllocation])
  return Buffer.from(ethers.utils.arrayify(keccak256(packed)))
}

export enum WhitelistErrorCode {
  ERROR_EMPTY_URL = 0,
  ERROR_UNABLE_TO_FETCH = 1,
  ERROR_MALFORMED = 2,
  ERROR_INVALID_ADDRESS = 3,
  ERROR_INVALID_ALLOCATION = 4,
  ERROR_EMPTY_LIST = 5,
}

export interface WhitelistResult {
  success: WhitelistSuccessRecord | null
  error: WhitelistRecordError | null
}

export interface WhitelistRecordError {
  code: WhitelistErrorCode
  line: number
  value: string
}

export interface WhitelistSuccessRecord {
  merkleTree: MerkleTree
  participants: PoolParticipant[]
}

export interface PoolParticipant {
  address: string
  maxAllocation: BigNumberish
}

export async function requestWhitelist(URL: string | null): Promise<WhitelistResult | string> {
  if (URL === null) {
    return { success: null, error: { code: WhitelistErrorCode.ERROR_EMPTY_URL, line: 0, value: '' } }
  }
  try {
    const response = await fetch(URL)
    return await response.text()
  } catch (e) {
    return { success: null, error: { code: WhitelistErrorCode.ERROR_UNABLE_TO_FETCH, line: 0, value: '' } }
  }
}

export function computeWhitelist(requestResult: WhitelistResult | string, decimals: number): WhitelistResult {
  if (typeof requestResult !== 'string') {
    return requestResult
  }
  let participantsRows: string[][] = []
  try {
    participantsRows = requestResult.split('\n').map((row) =>
      row
        .trim()
        .replace(/(?:\r\n|\r|\n)/g, '')
        .split(','),
    )
  } catch (e) {
    return { success: null, error: { code: WhitelistErrorCode.ERROR_MALFORMED, line: 0, value: '' } }
  }

  const participants: PoolParticipant[] = []
  for (let i = 0; i < participantsRows.length; ++i) {
    const [addressString, maxAllocationString] = participantsRows[i]
    const line = i + 1
    let address: string
    let maxAllocation: BigNumber
    try {
      address = ethers.utils.getAddress(addressString)
    } catch (e) {
      return { success: null, error: { code: WhitelistErrorCode.ERROR_INVALID_ADDRESS, line, value: addressString } }
    }
    try {
      maxAllocation = ethers.utils.parseUnits(maxAllocationString, decimals)
    } catch (e) {
      return {
        success: null,
        error: { code: WhitelistErrorCode.ERROR_INVALID_ALLOCATION, line, value: maxAllocationString },
      }
    }
    participants.push({ address, maxAllocation })
  }
  if (participants.length === 0) {
    return { success: null, error: { code: WhitelistErrorCode.ERROR_EMPTY_LIST, line: 0, value: '' } }
  }
  const treeHashes = participants.map((p) => merklePoolParticipantMaxAllocationHash(p.address, p.maxAllocation))
  const merkleTree = new MerkleTree(treeHashes)
  return {
    success: {
      merkleTree: merkleTree,
      participants: participants,
    },
    error: null,
  }
}

export async function whitelistCheckError(
  whitelist: string,
  buyTokenDecimals: number,
  setErrorText: React.Dispatch<React.SetStateAction<string>>,
  setRoot: React.Dispatch<React.SetStateAction<string>>,
  t: TFunction,
) {
  const whitelistResult = await requestWhitelist(whitelist)
  const computedWhitelistResult = await computeWhitelist(whitelistResult, buyTokenDecimals)
  if (computedWhitelistResult.error != null) {
    const error = computedWhitelistResult.error
    let text = ''
    switch (error.code) {
      case WhitelistErrorCode.ERROR_EMPTY_URL: {
        text = t('summary_error_empty_url')
        break
      }
      case WhitelistErrorCode.ERROR_UNABLE_TO_FETCH: {
        text = t('summary_error_cannot_get_url')
        break
      }
      case WhitelistErrorCode.ERROR_MALFORMED: {
        text = t('summary_error_malformed_file')
        break
      }
      case WhitelistErrorCode.ERROR_INVALID_ADDRESS: {
        text = t('summary_error_invalid_address_in_whitelist', {
          address: error.value ?? '',
          line: error.line ?? '',
        })
        break
      }
      case WhitelistErrorCode.ERROR_INVALID_ALLOCATION: {
        text = t('summary_error_invalid_allocation_in_whitelist', {
          address: error.value ?? '',
          line: error.line ?? '',
        })
        break
      }
      case WhitelistErrorCode.ERROR_EMPTY_LIST: {
        text = t('summary_error_empty_list')
        break
      }
    }
    setErrorText(text)
    return false
  }
  const root = computedWhitelistResult.success?.merkleTree.getHexRoot() ?? ''
  setRoot(root)
  return true
}
