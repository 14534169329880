import { NETWORK_TYPE } from 'services/Provider'

export interface SetInterface {
  WETH: string
  USDT: string
  USDC: string
  presale: string
  vesting: string
  pad: string
  staking: string
  escrow: string
  poolFactory: string
  fixedPool: string
  linearPool: string
  whitelist: string
  subgraph: string
}

export const web3Sets: { [key in NETWORK_TYPE]: SetInterface } = {
  // TODO: set valid Mainnet addresses
  Mainnet: {
    WETH: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    USDT: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    USDC: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    presale: '0x22ebc88055f1E8205978ae9997cBEfdf79E8b1E0',
    pad: '0x5067006F830224960Fb419D7f25a3a53e9919BB0',
    vesting: '0x8233b679944D3c8572e55B2cCB8928E106eB1243',
    escrow: '0x0a1290643c309ab260466FB84903cb1a52983d76',
    staking: '0x105aaB1C3314D61282b4FBF598D07302cf731EF3',
    poolFactory: '0x07985dD44c5eC117A50ab8Cf83E7E5aBd9721b06',
    fixedPool: '0x8a9C8Cc267e0C81c8ab9B82c6668b292C59508b2',
    linearPool: '0xFf7a0cf1E16be6e7613f56af2C9D89A1F54f2dc6',
    whitelist: '0x8D4Da7AF782C25b39a2fdFD7478F49e3be30Dc50',
    subgraph: 'https://api.thegraph.com/subgraphs/name/meold/smartpad',
  },
  Rinkeby: {
    WETH: '0x2FCC4dBa284dcF665091718E4d0DAB53A416dfe7',
    USDT: '0x1277E5785Af2135253535b9619025f9B81c62801',
    USDC: '0x1277E5785Af2135253535b9619025f9B81c62801',
    presale: '',
    pad: '0xCeB692f877a3dF2aBe7685799605ce43a515e78A',
    vesting: '',
    escrow: '',
    staking: '',
    poolFactory: '0xcD3886497AD889AB813d8e35287AF176e582A580',
    fixedPool: '0xe770A9098bC4C07DB02f0c09957652a2AdC47183', //
    linearPool: '0x9d794821330fa53B7554A8e32cdA1525D273786D', //
    whitelist: '0xF049Affc46A744c9d72737F6974d9490B24De509',//
    subgraph: '',
  },
  AuroraTestnet: {
    WETH: '0x2FCC4dBa284dcF665091718E4d0DAB53A416dfe7',
    USDT: '0x1c53E7467C85Bd7Cde87921cF6370445C01c2e97',
    USDC: '0x1c53E7467C85Bd7Cde87921cF6370445C01c2e97',
    presale: '0x22ebc88055f1E8205978ae9997cBEfdf79E8b1E0', //we don't use
    pad: '0x4f24C9F62A5478f5b746c33b5F3E8f37474c4982',
    vesting: '0x272f09198489Dbbb6961F260D27EfC0bf519ECa0', //we don't use
    escrow: '0x37EFaA7C91265bc8Ec52494c58e995A88F823709', //we don't use
    staking: '0x976Dfd54D6bb4dab7528B9a7B6f8F5c9451C2EBB', //aPad address
    poolFactory: '0xB04c5d81AE724803Fc8C016d0c4e1143Bd55610e',
    fixedPool: '0x00fcF8E3cb0a4311C1313ce24A01aC2988A66eA4',
    linearPool: '0x38fBbf39fEed9FcDee309d980f6219591CFeA6a0',
    whitelist: '0x0B1B2bA31Ef62a4a2Aa4253488784321088Df42D',
    subgraph: '',
  },

  Aurora: {
    WETH: '0xC9BdeEd33CD01541e1eeD10f90519d2C06Fe3feB', //
    USDT: '0x4988a896b1227218e4A686fdE5EabdcAbd91571f',
    USDC: '0x4988a896b1227218e4A686fdE5EabdcAbd91571f', //
    presale: '', //we don't use
    pad: '0x0fAD0ED848A7A16526E8a7574e418B015Dbf41B5',
    vesting: '', //we don't use
    escrow: '', //we don't use
    staking: '0x3e2F9dE8c59CdCd1fff14963A9c0155410e8Bb07', //aPad address
    poolFactory: '0x6a6C671628E667bB9931Fa4216774BfbAe4778Fa',
    fixedPool: '0x1595aBaa52F87Fe0C50bceC0b5B0355D8A51055f',
    linearPool: '0xa4FdB07EB0067cD6D790b5D52B41bDB5cC491893',
    whitelist: '0x77b29eBd777f4402Dd3C7E015C191cA8ca9da004',
    subgraph: ''
  },
  PolygonTestnet: {
    WETH: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619', //
    USDT: '0x926676f3aEA48Ccc6c413FFB043dC936252070De',
    USDC: '0x926676f3aEA48Ccc6c413FFB043dC936252070De', //
    presale: '', //we don't use
    pad: '0xCeB692f877a3dF2aBe7685799605ce43a515e78A',
    vesting: '', //we don't use
    escrow: '', //we don't use
    staking: '', //aPad address
    poolFactory: '0x95FD7f8ECEce4cc6Eba9E60d7230cb97E02642bA',
    fixedPool: '0x62540996982B631D1F55b540cd8F7Dee7Eb22f81',
    linearPool: '0x8D2B17844b06586263F08447121E8e38fd2E9981',
    whitelist: '0xb4b1A767b217bf22a2b8F797dfA6aa132C6a852A',
    subgraph: ''
  },
  Polygon: {
    WETH: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619', //
    USDT: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
    USDC: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f', //
    presale: '', //we don't use
    pad: '0x0Ad2Eff7F37E0037B5E30C1947f31ABdf11461e4', //we don't use
    vesting: '', //we don't use
    escrow: '', //we don't use
    staking: '0xa2269805f2Fd714ea0205d44c816cD0ea6f85BdC', //aPad address
    poolFactory: '0x32ad959b6d04954fF82d311d1CfcD0265967dA87',
    fixedPool: '0x1C475da2EF76a530358292D9C59e4F605c6052F7',
    linearPool: '0xc490A386046342dEc80B84580Bb02bc3D8D79519',
    whitelist: '0x638F90567286d9d4aEf646C6FeF84fE02d9816e6',
    subgraph: ''
  }
}
