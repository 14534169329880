export const urlInputs = (t: (id: string) => string) => [
  { name: t('provide_pools_website_url'), id: PoolFields.WEBSITE_URL, isRequired: true },
  { name: t('provide_pools_pool_description_url'), id: PoolFields.DESCRIPTION_URL, isRequired: true },
  { name: t('provide_pools_application_form_link'), id: PoolFields.APPLICATION_FORM_URL, isRequired: true },
  { name: t('provide_pools_pool_image_url'), id: PoolFields.IMAGE_URL, isRequired: true },
  { name: t('provide_pools_discord'), id: PoolFields.DISCORD_URL, isRequired: false },
  { name: t('provide_pools_twitter'), id: PoolFields.TWITTER_URL, isRequired: false },
  { name: t('provide_pools_whitepaper'), id: PoolFields.WHITEPAPER_URL, isRequired: false },
  { name: t('provide_pools_medium'), id: PoolFields.MEDIUM_URL, isRequired: false },
  { name: t('provide_pools_telegram'), id: PoolFields.TELEGRAM_URL, isRequired: false },
  { name: t('provide_pools_github'), id: PoolFields.GITHUB_URL, isRequired: false },
]

export enum PoolFields {
  TITLE = 0,
  DESCRIPTION_URL = 1,
  WEBSITE_URL = 2,
  APPLICATION_FORM_URL = 3,
  IMAGE_URL = 4,
  DISCORD_URL = 5,
  TWITTER_URL = 6,
  WHITEPAPER_URL = 7,
  MEDIUM_URL = 8,
  TELEGRAM_URL = 9,
  GITHUB_URL = 10,
}