import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  cardContainer: {
    //background: colors.defaultBlack,
    maxWidth: '300px',
    minWidth: '300px',
    borderRadius: '20px',
    padding: '31px 31px 42px 41px',
    margin: '0 10px',
    cursor: 'pointer',
    background: 'rgba(255, 255, 255, 0.08)',
    backdropFilter: 'blur(17px)',
  },
  columnBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '29px',
    alignItems: 'center',
  },
  rowBlock: {
    display: 'flex',
    flexDirection: 'column',
    '&>img': {
      width: '50px',
      height: '50px',
    },
  },
  progressYellowBlock: {
    background: colors.cardYellow,
    height: '100%',
    padding: '5px 10px',
    width: '110px',
    borderRadius: '18px',
    fontSize: '0.75rem',
    textAlign: 'center',
  },
  progressGreenBlock: {
    background: colors.cardGreen,
    height: '100%',
    padding: '5px 10px',
    width: '110px',
    borderRadius: '18px',
    fontSize: '0.75rem',
    textAlign: 'center',
  },
  progressRedBlock: {
    background: colors.redButton,
    height: '100%',
    padding: '5px 10px',
    width: '110px',
    borderRadius: '18px',
    fontSize: '0.75rem',
    textAlign: 'center',
  },
  progressVioletBlock: {
    background: colors.violet,
    height: '100%',
    padding: '5px 10px',
    width: '110px',
    borderRadius: '18px',
    fontSize: '0.75rem',
    textAlign: 'center',
  },
  titleText: {
    color: colors.primaryYellowSecond,
    fontWeight: 400,
    fontSize: '1.7rem',
    lineHeight: '44px',
    maxWidth: '225px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  goldInfo: {
    color: colors.primaryYellowFirst,
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: '22px',
    margin: '7px 0 0 2px',
  },
  smallGoldText: {
    color: colors.primaryYellowFirst,
    fontWeight: 400,
    fontSize: '1.75rem',
    lineHeight: '44px',
  },
  mediumGoldText: {
    color: colors.primaryYellowSecond,
    fontWeight: 400,
    fontSize: '1.5rem',
    lineHeight: '30px',
  },
  largeGoldText: {
    color: colors.primaryYellowSecond,
    fontWeight: 400,
    fontSize: '2rem',
    lineHeight: '40px',
  },
  whiteText: {
    fontSize: '0.9rem',
    fontWeight: 400,
    lineHeight: '17px',
    color: colors.white,
  },
  blackText: {
    fontSize: '0.9rem',
    fontWeight: 400,
    lineHeight: '17px',
    color: colors.defaultWhite,
  },
  greyText: {
    fontSize: '0.9rem',
    fontWeight: 400,
    lineHeight: '17px',
    color: colors.defaultGrey,
  },
  endDate: {
    fontSize: '1.2rem',
    fontWeight: 400,
    lineHeight: '17px',
    color: colors.defaultGrey,
    marginTop: '10px',
  },
  endGoldDate: {
    fontSize: '1.2rem',
    fontWeight: 400,
    lineHeight: '17px',
    color: colors.primaryYellowSecond,
    marginTop: '10px',
  },
  defaultButton: {
    width: '100%',
    height: '60px',
    padding: '0px 20px',
    background: colors.yellowLinearGradient,
    borderRadius: '10px',
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '1.5rem',
    cursor: 'pointer',
    '&:hover': {
      background: colors.yellowLinearGradient,
      opacity: 0.6,
    },
    '&:active': {
      background: colors.yellowLinearGradient,
      opacity: 0.3,
    },
  },
  headerRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    alignItems: 'center',
  },
  footerContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: '30px',
  },
})
