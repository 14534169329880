import React, { useContext, useEffect, useState } from 'react'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'
import { POOL_TYPES, SUMMARY, WHITELIST } from 'routes'
import { StoreContext } from 'utils/store'
import TitleHeader from 'components/title_header'
import { useHistory } from 'react-router'
import { Input } from '../../components/inputs/input'
import { Button } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Provider, sellTokens } from 'services/Provider'
import { useWeb3React } from '@web3-react/core'
import { getTokenSymbol } from 'hooks'
import { colors } from 'utils/mui'
import { PoolTypeState } from 'pages/pool_types'
import { tokenToDisplay } from 'utils/tokenFormatter'

export interface PriceInfoProps {
  title: string
  amountBuyToken: string
  setAmountBuyToken: React.Dispatch<React.SetStateAction<string>>
  amountSellToken: string
  setAmountSellToken: React.Dispatch<React.SetStateAction<string>>
  sellPrice: string
  buyPrice: string
}

const TokenInfo = (): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const { library, chainId } = useWeb3React()
  const [isError, setIsError] = useState<boolean>(false)
  const [isCliffVestNull, setIsCliffVestNull] = useState<boolean>(false)
  const [isCliffDisabled, setIsCliffDisabled] = useState<boolean>(false)
  const [isVestDisabled, setIsVestDisabled] = useState<boolean>(false)
  const [isVestNull, setIsVestNull] = useState<boolean>(false)

  const provider: Provider = new Provider(library.provider, chainId)
  const store = useContext(StoreContext)
  const isDisabledFixed =
    !store.vestPercent ||
    !store.cliffPercent ||
    !store.raiseAmount ||
    Number(store.raiseAmount) === 0 ||
    !store.sellTokenSymbol ||
    !store.buyTokenSymbol ||
    !store.buyTokenAddress ||
    !store.sellAcceptedToken ||
    !store.cliffDayDuration ||
    !store.cliffWeekDuration ||
    !store.vestDayInterval ||
    !store.vestWeekInterval ||
    !store.fixedBuyPrice ||
    !store.fixedSellPrice ||
    isError ||
    isVestNull
  const isDisabledLinear =
    !store.sellStartPrice ||
    !store.buyStartPrice ||
    !store.sellEndPrice ||
    !store.buyEndPrice ||
    !store.vestPercent ||
    !store.cliffPercent ||
    !store.raiseAmount ||
    Number(store.raiseAmount) === 0 ||
    !store.sellTokenSymbol ||
    !store.buyTokenSymbol ||
    !store.buyTokenAddress ||
    !store.sellAcceptedToken ||
    !store.cliffDayDuration ||
    !store.cliffWeekDuration ||
    !store.vestDayInterval ||
    !store.vestWeekInterval ||
    isError ||
    isVestNull

  const redirect =
    !store.poolType ||
    !store.listOption ||
    !store.poolTitle ||
    !store.urls[0] ||
    !store.urls[1] ||
    !store.urls[2] ||
    !store.urls[3] ||
    !store.startDate ||
    !store.endDate ||
    !store.whiteListUrl

  const cliffDuration = [
    {
      title: t('common_days'),
      value: store.cliffDayDuration,
      setValue: store.setCliffDayDuration,
      balance: '0',
    },
    { title: t('common_weeks'), value: store.cliffWeekDuration, setValue: store.setCliffWeekDuration, balance: '0' },
  ]
  const vestInterval = [
    { title: t('common_days'), value: store.vestDayInterval, setValue: store.setVestDayInterval, balance: '0' },
    { title: t('common_weeks'), value: store.vestWeekInterval, setValue: store.setVestWeekInterval, balance: '0' },
  ]

  const priceInfoFixed = [
    {
      title: t('token_info_fixed_prise'),
      amountBuyToken: store.fixedBuyPrice,
      setAmountBuyToken: store.setFixedBuyPrice,
      amountSellToken: store.fixedSellPrice,
      setAmountSellToken: store.setFixedSellPrice,
      sellPrice: '0.00',
      buyPrice: '0.00',
    },
  ]

  const priceInfoLinear = [
    {
      title: t('token_info_start_price'),
      amountBuyToken: store.buyStartPrice,
      setAmountBuyToken: store.setBuyStartPrice,
      amountSellToken: store.sellStartPrice,
      setAmountSellToken: store.setSellStartPrice,
      sellPrice: '0.00',
      buyPrice: '0.00',
    },
    {
      title: t('token_info_end_prise'),
      amountBuyToken: store.buyEndPrice,
      setAmountBuyToken: store.setBuyEndPrice,
      amountSellToken: store.sellEndPrice,
      setAmountSellToken: store.setSellEndPrice,
      sellPrice: '0.00',
      buyPrice: '0.00',
    },
  ]

  const updateTokenInfo = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const buyTokenAddress = e.target.value
    store.setBuyTokenAddress(buyTokenAddress)
    if (!buyTokenAddress) return
    const buyTokenSymbol = await getTokenSymbol(buyTokenAddress, provider)
    store.setBuyTokenSymbol(buyTokenSymbol)
    if (buyTokenAddress.length == 0) {
      store.setBuyTokenSymbol('')
    }
  }

  useEffect(() => {
    const updateSellSymbol = async () => {
      try {
        const sellSymbol = await getTokenSymbol(store.sellAcceptedToken, provider)
        store.setSellTokenSymbol(sellSymbol)
      } catch (e){
        console.log(e)
      }
    }

    updateSellSymbol()
  }, [store.sellAcceptedToken])

  const priceInputs = (priceInfo: PriceInfoProps[]) => {
    return priceInfo.map((el, index) => (
      <span key={index}>
        <div className={classes.segmentTokenSliders}>
          <div className={classes.segmentTokenContent}>
            <span className={classes.goldTitle}>{el.title} </span>
          </div>
        </div>
        <div className={classes.segmentTokenSliders}>
          <div className={classes.segmentTokenContent}>
            <div className={classes.goldBorderInput}>
              <Input
                value={el.amountSellToken}
                onUserInput={el.setAmountSellToken}
                className={classes.goldInputLabel}
              />
              <span className={classes.padGoldLabel}>{tokenToDisplay(store.sellTokenSymbol, t)}</span>
            </div>
          </div>

          <div className={classes.segmentTokenContent}>
            <span className={classes.padGoldLabel} style={{ margin: 0 }}>
              =
            </span>
          </div>

          <div className={classes.segmentTokenContent}>
            <div className={classes.goldBorderInput}>
              <Input value={el.amountBuyToken} onUserInput={el.setAmountBuyToken} className={classes.goldInputLabel} />
              <span className={classes.padGoldLabel}>
                {/* TODO: Fix layout, everything messes up without text */}
                {store.buyTokenSymbol ?? ' '}
              </span>
            </div>
          </div>
        </div>
        <div className={classes.segmentTokenSliders}>
          <div className={classes.segmentTokenContent}>
            <span className={classes.grayInfo}>
              {t('common_price_eth_and_tkn', {
                amountBuy: el.amountBuyToken,
                symbolBuy: store.buyTokenSymbol,
                amountSell: el.amountSellToken,
                symbolSell: tokenToDisplay(store.sellTokenSymbol, t),
              })}
            </span>
          </div>
        </div>
      </span>
    ))
  }

  useEffect(() => {
    if (Number(store.cliffPercent) + Number(store.vestPercent) > 100) {
      setIsError(true)
    } else {
      setIsError(false)
    }

    if (
      Number(store.cliffPercent) + Number(store.vestPercent) == 0 &&
      store.cliffPercent.length != 0 &&
      store.vestPercent.length != 0
    ) {
      setIsCliffVestNull(true)
    } else {
      setIsCliffVestNull(false)
    }

    if (Number(store.cliffPercent) == 0 && store.cliffPercent.length != 0) {
      store.setCliffDayDuration('0')
      store.setCliffWeekDuration('0')
      setIsCliffDisabled(true)
    } else {
      setIsCliffDisabled(false)
    }
    if (Number(store.vestPercent) == 0 && store.vestPercent.length != 0) {
      store.setVestDayInterval('0')
      store.setVestWeekInterval('0')
      store.setCliffDayDuration('')
      store.setCliffWeekDuration('')
      store.setCliffPercent('100')
      setIsVestDisabled(true)
    } else {
      setIsVestDisabled(false)
    }
    if (
      Number(store.vestDayInterval) + Number(store.vestWeekInterval) == 0 &&
      store.vestDayInterval.length != 0 &&
      store.vestWeekInterval.length != 0 &&
      Number(store.vestPercent) !== 0 &&
      store.vestPercent.length !== 0 &&
      Number(store.cliffPercent) == 0
    ) {
      setIsVestNull(true)
    } else {
      setIsVestNull(false)
    }
    if (
      Number(store.vestDayInterval) + Number(store.vestWeekInterval) == 0 &&
      store.vestDayInterval.length != 0 &&
      store.vestWeekInterval.length != 0 &&
      Number(store.vestPercent) !== 0 &&
      store.vestPercent.length !== 0 &&
      Number(store.cliffPercent) !== 0
    ) {
      setIsVestNull(true)
    } else {
      setIsVestNull(false)
    }
  }, [store.cliffPercent, store.vestPercent, store.vestDayInterval, store.vestWeekInterval])

  if (redirect) history.push(POOL_TYPES)

  const tokensName = sellTokens[provider.currentNetwork]

  return (
    <div className={classes.rootContainer}>
      <TitleHeader
        title={t('token_info_title')}
        info={t('token_info_title_info')}
        path={WHITELIST}
        currentStep={'5'}
        buttonTitle={t('token_info_back_button')}
      />

      <div className={classes.contentContainer}>
        <div className={classes.segmentBox}>
          <span className={classes.grayTitle}>{t('token_info_title_table')}</span>
          <div className={classes.segmentSliders}>
            <div className={classes.segmentContent}>
              <Autocomplete
                options={tokensName}
                getOptionLabel={(option) => option.name}
                className={classes.input}
                style={{ width: 300 }}
                onChange={(e, newValue) => {
                  if (!newValue) return
                  store.setSellAcceptedToken(newValue.address)
                }}
                renderInput={(params) => (
                  <TextField {...params} label={t('pool_creation_select_token')} variant="outlined" />
                )}
              />
            </div>

            <div className={classes.column}>
              <div className={classes.segmentSliders}>
                <div className={classes.segmentTitleContent}>
                  <span className={classes.goldTitle}> {t('token_info_token_address')}</span>
                </div>
                <div className={classes.segmentTitleContent}>
                  <span className={classes.grayHeaderInfo}>{t('token_info_token_info')} </span>
                </div>
              </div>
              <div className={classes.segmentContent}>
                {/* TODO: add a check for the validity of the address and not a valid red address */}
                <input
                  className={classes.goldInput}
                  value={store.buyTokenAddress}
                  placeholder={t('pool_creation_enter_contract_address')}
                  onChange={updateTokenInfo}
                />
              </div>
            </div>
          </div>
          {store.poolType == PoolTypeState.Linear ? priceInputs(priceInfoLinear) : priceInputs(priceInfoFixed)}
        </div>

        <div className={classes.segmentBox}>
          <span className={classes.grayTitle}>{t('token_info_raise_amount_')} </span>
          <div className={classes.input}>
            <Input value={store.raiseAmount} onUserInput={store.setRaiseAmount} style={{ fontSize: '2.25rem' }} />
            <span className={classes.padLabel}>{store.buyTokenSymbol ?? ''}</span>
          </div>
          <div style={{ margin: '10px' }} />
        </div>

        <div className={classes.segmentBox}>
          <span className={classes.grayTitle}>{t('token_info_vesting')} </span>
          <div className={classes.vestContainer}>
            <div className={classes.segmentContent}>
              <span className={classes.goldTitle}>{t('token_info_cliff_duration')} </span>
              <div className={classes.segmentSliders}>
                {cliffDuration.map((el, index) => (
                  <div className={classes.segmentContent} key={index}>
                    <span className={classes.grayInfo}>{el.title} </span>
                    <Input
                      value={el.value}
                      onUserInput={el.setValue}
                      className={classes.weekInput}
                      placeholder={' '}
                      disabled={isCliffDisabled}
                    />
                  </div>
                ))}
              </div>
              <span className={classes.goldTitle}>{t('token_info_cliff_unlock')} </span>
              <Input
                value={store.cliffPercent}
                onUserInput={store.setCliffPercent}
                balance={'100'}
                className={classes.weekInput}
                placeholder={' '}
                style={{ color: isError ? colors.redError : colors.primaryYellowSecond }}
              />
            </div>
            <div className={classes.segmentContent}>
              <span className={classes.goldTitle}>{t('token_info_vesting_interval')} </span>
              <div className={classes.segmentSliders}>
                {vestInterval.map((el, index) => (
                  <div className={classes.segmentContent} key={index}>
                    <span className={classes.grayInfo}>{el.title} </span>
                    <Input
                      value={el.value}
                      onUserInput={el.setValue}
                      className={classes.weekInput}
                      placeholder={' '}
                      disabled={isVestDisabled}
                    />
                  </div>
                ))}
              </div>
              <span className={classes.goldTitle}>{t('token_info_vesting_unlock')} </span>
              <Input
                value={store.vestPercent}
                onUserInput={store.setVestPercent}
                balance={'100'}
                className={classes.weekInput}
                placeholder={' '}
                style={{ color: isError ? colors.redError : colors.primaryYellowSecond }}
              />
            </div>
            <div className={classes.segmentContent}>
              <span className={classes.whiteTitle}>{t('token_info_vesting_info')} </span>
            </div>
          </div>
          {isError ? (
            <div className={classes.vestContainer}>
              <span className={classes.error}>{t('token_info_percent_error')} </span>
            </div>
          ) : (
            <div style={{ display: 'none' }} />
          )}

          {isCliffVestNull ? (
            <div className={classes.vestContainer}>
              <span className={classes.error}>{t('token_info_percent_error_null')} </span>
            </div>
          ) : (
            <div style={{ display: 'none' }} />
          )}

          {isVestNull ? (
            <div className={classes.vestContainer}>
              <span className={classes.error}>{t('token_info_vesting__error_null')} </span>
            </div>
          ) : (
            <div style={{ display: 'none' }} />
          )}
        </div>

        <div className={classes.buttonContainer}>
          <Button
            onClick={() => history.push(SUMMARY)}
            className={classes.defaultButton}
            disabled={store.poolType == PoolTypeState.Linear ? isDisabledLinear : isDisabledFixed}
          >
            {t('common_next_button')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default TokenInfo
