import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  contentContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '65vh',
  },
}))

const Loader = () => {
  const classes = useStyles()

  return (
    <div className={classes.contentContainer}>
      <div className={classes.root}>
        <CircularProgress />
      </div>
    </div>
  )
}

export default Loader
