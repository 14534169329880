import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  paperWidthSm: {
    background: `linear-gradient(180deg, ${colors.primaryYellowFirst} 22.4%, ${colors.primaryYellowSecond} 66.15%)`,
    boxShadow: `0px 0px 25px ${colors.blackShadow_50}`,
    borderRadius: '20px',
    padding: '2px',
    minWidth: '460px',
  },
  blackBg: {
    background: colors.defaultBlack,
    borderRadius: '20px',
    padding: '15px 35px 50px',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    marginTop: '6px',
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  title: {
    fontSize: '1.125rem',
    lineHeight: '22px',
    fontWeight: 600,
    color: colors.primaryYellowSecond,
  },
  subTitle: {
    fontSize: '2.25rem',
    lineHeight: '44px',
    color: colors.primaryYellowSecond,
    width: '425px',
  },
  buttonContainer: {
    width: '100%',
    height: '80px',
    padding: '2px',
    borderRadius: '10px',
    background: `linear-gradient(180deg, ${colors.primaryYellowFirst} 22.4%, ${colors.primaryYellowSecond} 66.15%)`,
  },
  defaultButton: {
    width: '100%',
    height: '100%',
    background: `linear-gradient(180deg, ${colors.primaryYellowFirst} 22.4%, ${colors.primaryYellowSecond} 66.15%)`,
    borderRadius: '10px',
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '1.5rem',
    cursor: 'pointer',
    color: colors.defaultWhite,
    '&:hover': {
      background: colors.primaryYellowSecond,
      opacity: 0.9,
    },
    '&:active': {
      background: colors.primaryYellowSecond,
      opacity: 0.8,
    },
    '&:disabled': {
      background: colors.defaultBlack,
      color: colors.primaryYellowSecond,
    },
  },
  dialogContent: {
    padding: 0,
    overflowY: 'initial',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  goldInputLabel: {
    color: colors.primaryYellowSecond,
    background: 'transparent',
    border: 'none',
    outline: 'none',
    fontSize: '2.25rem',
    maxWidth: '330px',
    '&::placeholder': {
      fontSize: '2.25rem',
      color: colors.defaultWhite,
    },
  },
  goldBorderInput: {
    height: '77px',
    background: 'transparent',
    border: `2px solid ${colors.defaultWhite}`,
    borderRadius: '15px',
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '5px',
    boxSizing: 'border-box',
    alignItems: 'center',
    fontSize: '2.25rem',
    '&::placeholder': {
      fontSize: '2.25rem',
    },
  },
  padGoldLabel: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '0.5rem',
    fontWeight: 500,
    userSelect: 'none',
    marginRight: '12px',
    color: colors.primaryYellowSecond,
    fontSize: '1.75rem',
  },
})
