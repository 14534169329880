import { Button } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import { Input } from 'components/inputs/input'
import { ethers } from 'ethers'
import { useAllowance } from 'hooks/useERC20'
import { Pool, useBuyAmountOf, usePoolPriceBuyInSellUQ } from 'hooks/usePoolDetails'
import { setTransaction } from 'hooks/useTransactions'
import { useAnyBalance, useIsValue } from 'hooks/useValue'
import { useBuyWithProof } from 'hooks/useWhitelist'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { chainIdMap, Provider } from 'services/Provider'
import { useRefresh } from 'services/RefreshContext'
import { colors } from 'utils/mui'
import { Transaction } from 'utils/store'
import { merklePoolParticipantMaxAllocationHash, WhitelistSuccessRecord } from 'utils/treeUtils'
import {
  BN,
  BNish,
  convertSellTokenToBuyToken,
  formattedDecimals,
  fromBNish,

  Nullable,
  safeFromDecimals
} from 'utils/tsUtils'
import { State, stateErrorMessage, validateState } from './buyUtils'
import { useStyles } from './styles'
import moment from 'moment'

interface ModalProps {
  handleClose: (txResult: Nullable<Transaction>) => void
  open: boolean
  pool: Pool
  account: string
  provider: Provider
  whitelistSuccess: WhitelistSuccessRecord
  buyAllocation: BNish
}

export const BuyModal = ({
  handleClose,
  open,
  pool,
  account,
  provider,
  whitelistSuccess,
  buyAllocation,
}: ModalProps): JSX.Element => {
  const {
    paperWidthSm,
    title,
    subTitle,
    dialogContent,
    contentContainer,
    blackBg,
    buttonContainer,
    defaultButton,
    rowContainer,
    goldInputLabel,
    goldBorderInput,
    padGoldLabel,
    columnContainer,
  } = useStyles()
  const { t } = useTranslation()
  const [value, setValue] = useState<string>('')
  const [priceBuyInSellUQ, setPriceBuyInSellUQ] = usePoolPriceBuyInSellUQ(pool.contract)
  const [sellAllowance, approveCallback, requestAllowance] = useAllowance(
    provider,
    pool.sellToken,
    account,
    pool.whitelist.address,
  )
  const sellBalance = useAnyBalance(provider, pool.sellToken, account)
  let [buyTokenBought, refreshBuyTokenBought] = useBuyAmountOf(pool.contract, account)
  const isValueToken = useIsValue(provider, pool.sellToken)
  const buyWithProof = useBuyWithProof(provider, pool.sellToken, pool.whitelist)
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    if (open) {
      setValue('')
    }
  }, [open])

  useEffect(() => {
    setPriceBuyInSellUQ()
    refreshBuyTokenBought()
    if (!isValueToken) {
      requestAllowance()
    }
  }, [fastRefresh])

  const sellValue = safeFromDecimals(value, pool.sellToken.decimals, fromBNish(0))
  let buyValue: BN | null = null
  if (priceBuyInSellUQ) {
    buyValue = convertSellTokenToBuyToken(sellValue, priceBuyInSellUQ)
  }

  const approve = async () => {
    await approveCallback(pool.whitelist.address, ethers.constants.MaxUint256)
  }

  const onClose = () => {
    handleClose(null)
  }

  const buy = async () => {
    const signerHash = merklePoolParticipantMaxAllocationHash(account, buyAllocation)
    const proof = whitelistSuccess.merkleTree.getHexProof(signerHash)

    const tx = await buyWithProof(
      proof,
      account,
      buyAllocation,
      sellValue,
      chainIdMap[provider.currentNetwork],
      setTransaction,
    )
    handleClose(tx)
  }


  const now = moment(new Date())
  const endDate = moment(pool.endTimestamp.getTime())

  // const timeInMinutesToEnd = moment(endDate).diff(now, 'minutes')
  const timeInMillisecondsToEnd = moment(endDate).diff(now, 'milliseconds')

  let parsedBuyValue: BN | null = buyValue

  if (pool.whitelist && timeInMillisecondsToEnd <= 1000* 60 * 60 * 4) {
    parsedBuyValue = fromBNish(0)
  }

  let state = State.SUCCESS

  if (pool.contract.options.address !== '0x8aBd6F5fFe3Dcc3552C1b25da232Be525AF75BaF') {
    state = validateState(
      parsedBuyValue,
      buyAllocation,
      buyTokenBought,
      sellValue,
      sellAllowance,
      sellBalance,
      isValueToken,
      whitelistSuccess.merkleTree.getHexRoot(),
      pool.whitelist.root,
      timeInMillisecondsToEnd <= 1000* 60 * 60 * 4
    )
  }

  const errorMessage = stateErrorMessage(state, t)

  return (
    <Dialog classes={{ paperWidthSm: paperWidthSm }} maxWidth="sm" onClose={onClose} open={open}>
      <div className={blackBg}>
        <MuiDialogContent classes={{ root: dialogContent }}>
          <div className={contentContainer}>
            <span className={title}>{t('common_amount_to_join')}</span>
            <div className={goldBorderInput}>
              <Input
                autoFocus
                value={value}
                placeholder={t('pool_card_amount_placeholder')}
                onUserInput={setValue}
                className={goldInputLabel}
              />
              <span className={padGoldLabel}>{pool.sellToken.symbol}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <span className={title}>
                {t('pool_card_balance', {
                  amount: formattedDecimals(sellBalance ?? 0, pool.sellToken.decimals, pool.sellToken.symbol),
                })}
              </span>
            </div>
            <div className={columnContainer}>
              <span className={title}>{t('common_you_get')}</span>
              <span className={subTitle}>
                {formattedDecimals(buyValue ?? 0, pool.buyToken.decimals, pool.buyToken.symbol)}
              </span>
            </div>
            <div className={rowContainer}>
              {!isValueToken ? (
                <div className={buttonContainer}>
                  <Button
                    className={defaultButton}
                    onClick={approve}
                    disabled={state !== State.ERROR_INSUFFICIENT_ALLOWANCE}
                  >
                    <span>{t('stake_approve')}</span>
                  </Button>
                </div>
              ) : null}
              <div className={buttonContainer}>
                <Button className={defaultButton} onClick={buy} disabled={state !== State.SUCCESS}>
                  <span>{t('common_buy_button')}</span>
                </Button>
              </div>
            </div>
            {errorMessage !== null ? (
              <div className={columnContainer}>
                <span className={title} style={{ textAlign: 'center', color: colors.redButton }}>
                  {errorMessage}
                </span>
              </div>
            ) : null}
          </div>
        </MuiDialogContent>
      </div>
    </Dialog>
  )
}
