import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  rootContainer: {
    padding: '10px 50px',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '100px',
    justifyContent: 'center',
    padding: '120px 0 0',
    '@media (max-width: 1024px)': {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  defaultButton: {
    width: '306px',
    height: '100px',
    marginTop: '20px',
    background: colors.yellowLinearGradient,
    borderRadius: '10px',
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '1.5rem',
    cursor: 'pointer',
    color: colors.defaultBlack,
    '&:hover': {
      background: colors.yellowLinearGradient,
      opacity: 0.9,
    },
    '&:active': {
      background: colors.yellowLinearGradient,
      opacity: 0.8,
    },
  },
  contentContainer: {
    padding: '0 12%',
    display: 'flex',
    flexDirection: 'column',
  },
  vestContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  error: {
    color: colors.redError,
    fontSize: '1rem',
  },
  input: {
    height: '77px',
    background: `linear-gradient(180deg, ${colors.primaryYellowFirst} 22.4%, ${colors.primaryYellowSecond} 66.15%)`,
    boxShadow: `0px 4px 5px ${colors.blackShadow_25}`,
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'row',
    padding: '2px 10px',
    boxSizing: 'border-box',
    alignItems: 'center',
    fontSize: '2.25rem',
    '&::placeholder': {
      fontSize: '2.25rem',
    },
  },
  inputFont: {
    fontSize: '2.25rem',
    '&::placeholder': {
      fontSize: '2.25rem',
    },
  },
  goldTitle: {
    fontSize: '1.125rem',
    margin: '0 10px 10px 0',
    lineHeight: '22px',
    width: 'max-content',
    color: colors.primaryYellowSecond,
  },
  grayTitle: {
    fontSize: '2.25rem',
    lineHeight: '44px',
    color: colors.defaultGrayText,
    fontWeight: 500,
  },
  grayInfo: {
    fontSize: '1rem',
    color: colors.defaultGrayText,
    margin: '10px 0',
  },
  grayHeaderInfo: {
    fontSize: '0.75rem',
    color: colors.defaultGrayText,
    marginBottom: '10px',
    marginLeft: '40px',
  },
  whiteTitle: {
    fontSize: '1.125rem',
    lineHeight: '22px',
    color: colors.white,
    margin: '0 0 5px 5px',
  },
  padLabel: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '0.5rem',
    fontWeight: 500,
    userSelect: 'none',
    marginRight: '12px',
    color: colors.defaultBlack,
    fontSize: '2.25rem',
  },
  segmentBox: {
    backgroundColor: colors.defaultBlack,
    width: '100%',
    borderRadius: '20px',
    marginTop: '40px',
    padding: '40px 55px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  segmentContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    margin: '0 20px 28px 0',
  },
  segmentTitleContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: '10px',
  },
  segmentTokenContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  segmentSliders: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  segmentTokenSliders: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    gap: '30px',
  },
  weekInput: {
    width: '70px',
    backgroundColor: colors.defaultBlack,
    color: colors.primaryYellowSecond,
    outline: 'none',
    height: '40px',
    border: `2px solid ${colors.primaryYellowSecond}`,
    borderRadius: '15px',
    fontSize: '1.3rem',
    lineHeight: '22px',
    fontWeight: 500,
    textAlign: 'start',
    paddingLeft: '10px',
    '&::placeholder': {
      background: colors.white,
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      opacity: 1,
    },
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
  },
  padGoldLabel: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '0.5rem',
    fontWeight: 500,
    userSelect: 'none',
    marginRight: '12px',
    color: colors.primaryYellowSecond,
    fontSize: '1.75rem',
  },
  goldBorderInput: {
    height: '77px',
    background: 'transparent',
    border: `2px solid ${colors.primaryYellowSecond}`,
    boxShadow: `0px 4px 5px ${colors.blackShadow_25}`,
    borderRadius: '15px',
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '5px',
    boxSizing: 'border-box',
    alignItems: 'center',
    fontSize: '2.25rem',
    maxWidth: '380px',
    '&::placeholder': {
      fontSize: '2.25rem',
    },
  },
  goldInputLabel: {
    color: colors.primaryYellowSecond,
    background: 'transparent',
    border: 'none',
    outline: 'none',
    fontSize: '2.25rem',
    maxWidth: '270px',
    '&::placeholder': {
      fontSize: '2.25rem',
      color: colors.primaryYellowSecond,
    },
  },
  goldInput: {
    width: '-webkit-fill-available',
    backgroundColor: colors.defaultBlack,
    color: colors.primaryYellowSecond,
    outline: 'none',
    height: '75px',
    border: '2px solid',
    borderRadius: '15px',
    fontSize: '1.5rem',
    lineHeight: '29px',
    fontWeight: 700,
    textAlign: 'center',
    '&::placeholder': {
      background: colors.yellowLinearGradient,
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      textAlign: 'center',
      opacity: 1,
    },
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
})
