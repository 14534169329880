import React from 'react'
import { IconButton } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { notify } from 'App'
import CopyIcon from 'assets/icons/CopyIcon.svg'
import { colors } from 'utils/mui'

import { transformAddress } from 'utils/NumberFormatter'
import { clearTransactions, useTransactions } from 'hooks/useTransactions'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
  root: {
    padding: 0,
    marginBottom: 40,
  },
  paperWidthSm: {
    background: `linear-gradient(180deg, ${colors.primaryYellowFirst} 22.4%, ${colors.primaryYellowSecond} 66.15%)`,
    boxShadow: `0px 0px 25px ${colors.blackShadow_50}`,
    borderRadius: '10px',
    padding: '35px',
    minWidth: '425px',
    maxHeight: '800px',
  },
  title: {
    fontSize: '2.25rem',
    lineHeight: '44px',
    fontWeight: 700,
    color: colors.defaultWhite,
    textTransform: 'uppercase',
    textAlign: 'center',
    fontFamily: 'Formular',
  },
  subtitle: {
    marginTop: '50px',
    fontSize: '1.13rem',
    lineHeight: '22px',
    fontWeight: 700,
    color: colors.defaultWhite,
    fontFamily: 'Formular',
  },
  dialogContent: {
    padding: 0,
    overflowY: 'initial',
  },
  addressBox: {
    borderRadius: '20px',
    width: '-webkit-fill-available',
    margin: '4px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  addressText: {
    textTransform: 'uppercase',
    background: colors.yellowLinearGradient,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: '1.5rem',
    lineHeight: '44px',
    fontWeight: 700,
    marginRight: '16px',
  },
  clearText: {
    background: colors.defaultBlack,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: '1.2rem',
    lineHeight: '44px',
    fontWeight: 700,
    marginRight: '16px',
  },
  blackContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: colors.defaultBlack,
    overflowY: 'auto',
    maxHeight: '200px',
    borderRadius: 15,
    marginTop: 15,
    marginBottom: 15,
  },
})

type ModalProps = {
  handleClose: () => void
  open: boolean
}

export const TransactionModal = ({ handleClose, open }: ModalProps): JSX.Element => {
  const { paperWidthSm, root, title, subtitle, dialogContent, addressBox, addressText, blackContainer, clearText } =
    useStyles()
  const transactions = useTransactions()
  const { t } = useTranslation()

  const copyToClipboard = (content: string) => {
    navigator.clipboard.writeText(content)
    notify(t('modal_transactions_transaction_copied'))
  }
  const clearHistory = () => clearTransactions()
  const pendingTransactions = transactions.filter((tx) => tx.status === 'pending')

  return (
    <Dialog classes={{ paperWidthSm: paperWidthSm }} maxWidth="sm" onClose={handleClose} open={open}>
      <MuiDialogTitle disableTypography className={root}>
        <Typography classes={{ root: title }}>{t('modal_transactions_title')}</Typography>
      </MuiDialogTitle>
      <MuiDialogContent classes={{ root: dialogContent }}>
        {pendingTransactions.length > 0 && (
          <>
            <span className={subtitle}>{t('modal_transactions_pending')}</span>
            <div className={blackContainer}>
              {pendingTransactions.map((el, index) => {
                return (
                  <div key={index} className={addressBox} onClick={() => copyToClipboard(el.hash)}>
                    <span className={addressText}>{transformAddress(el.hash)}</span>
                    <IconButton>
                      <img src={CopyIcon} />
                    </IconButton>
                  </div>
                )
              })}
            </div>
          </>
        )}
        {transactions.length > 0 && (
          <>
            <span className={subtitle}>{t('modal_transactions_previous')}</span>
            <div className={blackContainer}>
              {transactions
                .filter((el) => el.status !== 'pending')
                .map((el, index) => {
                  return (
                    <div key={index} className={addressBox} onClick={() => copyToClipboard(el.hash)}>
                      <span className={addressText}>{transformAddress(el.hash)}</span>
                      <IconButton>
                        <img src={CopyIcon} />
                      </IconButton>
                    </div>
                  )
                })}
            </div>
            <div className={addressBox} onClick={clearHistory}>
              <span className={clearText}>{t('modal_transactions_clear')}</span>
            </div>
          </>
        )}
      </MuiDialogContent>
    </Dialog>
  )
}
