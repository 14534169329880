import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStyles } from './styles'
import Big from 'big.js'
import { StatisticCard } from './card'
import userInfo from '../../assets/users/users.json'
import { useWeb3React } from '@web3-react/core'
import { fromBNValue } from '../../utils/NumberFormatter'
import { Provider } from 'services/Provider'
import { MerkleTree } from '../../utils/merkelTree'
import tree from '../../assets/tree/merkleTree.json'
import { getUnlockedAmount, isValidProof, withdrawWithProof } from 'hooks'
import { getVestingValues, merklePresaleHash } from 'utils/treeUtils'
import { setTransaction } from 'hooks/useTransactions'
//
const fillArray = (userInfo: User[], account?: string) => {
  const newArr = [...userInfo]
  for (let k = 1; k <= 5; k++) {
    const index = newArr.findIndex((el) => el.day === k)
    if (index < 0) {
      newArr.push({
        day: k,
        user: account ?? '0',
        spent: '0',
        dayBonus: '0',
        referralBonus: '0',
        total: '0',
        totalInPAD: '0',
        contractTotalInPAD: '0',
      })
    }
  }
  return newArr
}
export interface User {
  day: number
  user: string
  spent: string
  dayBonus: string
  referralBonus: string
  total: string
  totalInPAD: string
  contractTotalInPAD: string
}

const sumFunction = (arr: string[]) => arr.reduce((a, b) => new Big(a).plus(new Big(b)).toString())

const Statistics = (): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [userData, setUserData] = useState<User[]>(fillArray([]))
  const [contractTotalAmount, setContractTotalAmount] = useState('0')
  const [totalAmount, setTotalAmount] = useState('0')
  const [availableAmount, setAvailableAmount] = useState('0')
  const { account, library, chainId } = useWeb3React()

  const columnNames = [t('statistic_spent'), t('statistic_bonuses_received'), t('statistic_referral_bonuses_received')]

  useEffect(() => {
    if (!account) {
      setUserData(fillArray([]))
      return
    }
    const user: User[] = userInfo.filter(
      (obj: User) => obj.user.toLowerCase() === account.toLowerCase(),
    )
    const newInfo = fillArray(user, account).sort((a, b) => a.day - b.day)
    if (!user.length) {
      setTotalAmount('0')
      setContractTotalAmount('0')
      setUserData(newInfo)
      return
    }
    setTotalAmount(user[0].totalInPAD)
    setContractTotalAmount(user[0].contractTotalInPAD)
    setUserData(newInfo)
  }, [account])

  useEffect(() => {
    getAvailableClaimAmountWithRestricted(new Big(total), new Big(contractTotalAmount))
  }, [totalAmount, contractTotalAmount])

  if (!userData) {
    return <h1>{t('statistic_error')}</h1>
  }

  const spent = userData.map((el) => el.spent)
  const bonuses = userData.map((el) => el.dayBonus)
  const refs = userData.map((el) => el.referralBonus)
  const cellArray = [spent, bonuses, refs]
  const spentAmount = sumFunction(spent)
  const bonusesAmount = sumFunction(bonuses)
  const refsAmount = sumFunction(refs)

  const total = new Big(totalAmount)
  const contractTotal = new Big(contractTotalAmount)

  const onClaim = async () => {
    if (!account || !chainId) return

    const provider: Provider = new Provider(library.provider, chainId)
    const merkleTree = new MerkleTree(tree.map((el) => Buffer.from(el.slice(2), 'hex')))
    const [cliffAmount, vestingPerInterval] = getVestingValues(account, total.toFixed())
    const signerHash = merklePresaleHash(account, contractTotal.toFixed(), cliffAmount, vestingPerInterval)
    const proof = merkleTree.getHexProof(signerHash)
    const isValid = await isValidProof(
      provider,
      proof,
      account,
      contractTotal.toFixed(),
      cliffAmount,
      vestingPerInterval,
    )

    if (isValid) {
      await withdrawWithProof(
        provider,
        proof,
        account,
        contractTotal.toFixed(),
        cliffAmount,
        vestingPerInterval,
        chainId,
        setTransaction,
      )
    }
    getAvailableClaimAmountWithRestricted(total, contractTotal)
  }
  const getAvailableClaimAmount = async (total: Big, contractTotal: Big) => {
    if (!account) return
    const provider: Provider = new Provider(library.provider, chainId)
    const [cliffAmount, vestingPerInterval] = getVestingValues(account, total.toFixed())
    const newAmount = await getUnlockedAmount(
      provider,
      account,
      contractTotal.toFixed(),
      cliffAmount,
      vestingPerInterval,
    )
    newAmount && setAvailableAmount(newAmount)
  }

  const getAvailableClaimAmountWithRestricted = async (total: Big, contractTotal: Big) => {
    getAvailableClaimAmount(total, contractTotal)
  }

  return (
    <>
      <div className={classes.rootContainer}>
        <div className={classes.contentContainer}>
          <div className={classes.totalHeadingContainer}>
            <div className={classes.totalTitle}>{t('statistic_available_claim')}</div>

            <div className={classes.withdrawValueBox}>
              <span className={classes.withdrawValue}>{fromBNValue(availableAmount).toFixed(2)}</span>
              <span className={classes.withdrawValue} style={{ marginLeft: '5px' }}>
                {t('common_pad')}
              </span>
            </div>
            <button onClick={onClaim} className={classes.withdrawButton} disabled={Number(availableAmount) === 0}>
              {t('statistic_claim_button')}
            </button>
          </div>

          <div className={classes.claimContainerDesktop}>
            <div className={classes.table}>
              <div className={classes.dayColumn}>
                <div className={classes.headerCell}>{t('statistic_days')}</div>
                {userData.map((el, index) => (
                  <div className={classes.dayCell} key={index}>
                    {el.day}
                  </div>
                ))}
              </div>

              {cellArray.map((array, index) => {
                return (
                  <div key={index} className={classes.basicColumn}>
                    <div className={classes.headerCell}>{columnNames[index]}</div>
                    {array.map((el, index) => (
                      <div key={el + index}>
                        <div className={classes.basicCell}>
                          {t('common_eth', { amount: fromBNValue(el).toFixed(5) })}
                        </div>
                        {index !== 4 && <div className={classes.yellowLine} />}
                      </div>
                    ))}
                  </div>
                )
              })}
            </div>

            <div className={classes.totalBox}>
              {spentAmount && bonusesAmount && refsAmount && (
                <>
                  <div className={classes.totalSumBox}>
                    {t('common_eth', { amount: fromBNValue(spentAmount).toFixed(5) })}
                  </div>
                  <div className={classes.totalSumBox}>
                    {t('common_eth', { amount: fromBNValue(bonusesAmount).toFixed(5) })}
                  </div>
                  <div className={classes.totalSumBox}>
                    {t('common_eth', { amount: fromBNValue(refsAmount).toFixed(5) })}
                  </div>
                </>
              )}
            </div>
            <div className={classes.totalBox}>
              <div className={classes.totalValue}>
                <span>{t('statistic_total')}</span>
              </div>
              <div className={classes.totalContainer}>
                <span className={classes.withdrawValue}>{fromBNValue(totalAmount).toFixed(7)}</span>
                <span className={classes.withdrawValue} style={{ marginLeft: '5px' }}>
                  {t('common_pad')}
                </span>
              </div>
            </div>
          </div>
          <div className={classes.claimContainerMobile}>
            <div className={classes.totalMobile}>
              <span className={classes.totalMobileTitle}>{t('statistic_total')}</span>
              <button onClick={onClaim} className={classes.withdrawButton} disabled={Number(availableAmount) === 0}>
                {t('statistic_claim_button')}
              </button>
            </div>
            <div className={classes.withdrawMobileBox}>
              <span className={classes.withdrawValue}>{fromBNValue(availableAmount).toFixed(2)}</span>
              <span className={classes.withdrawCurrency}>{t('common_pad')}</span>
            </div>
            {cellArray.map((arr, index) => (
              <StatisticCard key={index} title={columnNames[index]} bonuses={arr} />
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default Statistics
