import React from 'react'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'
import Big from 'big.js'
import { fromBNValue } from 'utils/NumberFormatter'
export interface StatisticCardProps {
  title: string
  bonuses: string[]
}

export const StatisticCard = ({ title, bonuses }: StatisticCardProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  const totalBonus = bonuses.reduce((acc, value) => new Big(acc).add(new Big(value)), new Big(0)).toFixed()

  return (
    <div className={classes.rootContainer}>
      <div className={classes.rowContainer}>
        <div className={classes.rightTopContainer}>
          <div className={classes.goldRightBg}>
            <div className={classes.titleText}>{title}</div>
          </div>
        </div>
      </div>
      <div className={classes.rowContainer}>
        <div className={classes.contentContainer}>
          <div className={classes.goldBg}>
            <div className={classes.titleTransformText}>{t('statistic_days')}</div>
          </div>
        </div>

        <div className={classes.contentContainer}>
          {bonuses.map((el, index) => (
            <div className={classes.goldBg} key={index}>
              <span className={classes.daysText}>{index + 1}</span>
            </div>
          ))}
        </div>

        <div className={classes.goldBorder}>
          <div className={classes.contentContainer}>
            {bonuses.map((el, index) => (
              <span key={index}>
                <div className={classes.amountText}>{t('common_eth', { amount: fromBNValue(el).toFixed(5) })}</div>
                <div className={classes.goldSeparator} />
              </span>
            ))}
          </div>
        </div>
      </div>

      <div className={classes.rowContainer}>
        <div className={classes.totalAmountText}>{t('statistic_total')}</div>
        <div className={classes.goldRightBg}>
          <div className={classes.amountText} style={{ margin: '10px 0px' }}>
            {t('common_eth', { amount: fromBNValue(totalBonus).toFixed(5) })}
          </div>
        </div>
      </div>
    </div>
  )
}
