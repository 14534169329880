import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  contentContainer: {
    padding: '0 12%',
    display: 'flex',
    flexDirection: 'column',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '22px',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  titleText: {
    color: colors.defaultYellow,
    fontSize: '3rem',
    lineHeight: '58px',
    fontWeight: 500,
  },
  goldText: {
    color: colors.primaryYellowFirst,
    fontSize: '1.13rem',
    lineHeight: '22px',
    fontWeight: 700,
    textTransform: 'none',
  },
  infoText: {
    color: colors.defaultWhite,
    fontSize: '1.13rem',
    lineHeight: '22px',
  },
  stepsText: {
    color: colors.defaultWhite,
    fontSize: '2.25rem',
    lineHeight: '44px',
    fontWeight: 400,
  },
  backButtonStepContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '20px',
    marginTop: '25px',
  },
  rightContainer: {
    display: 'flex',
    width: '550px',
    alignItems: 'baseline',
  },
  feeContainer: {
    height: '40px',

    borderRadius: '10px',
    backgroundColor: colors.defaultBlack,
    display: 'flex',
    padding: '2px 12px',
    alignItems: 'center',
    transition: 'all .4s',
    justifyContent: 'flex-end',
    marginLeft: '10px',
  },
})
