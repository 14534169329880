import { useCallback, useEffect, useMemo, useState } from 'react'
import { PoolFields } from 'assets/constants'
import { PoolTypeState } from 'pages/pool_types'
import { NETWORK_TYPE, Provider } from 'services/Provider'
import { AbiFixedPool } from 'contracts/web3/typings/abi_fixed_pool'
import { AbiLinearPool } from 'contracts/web3/typings/abi_linear_pool'
import { abiFixedPool, abiLinearPool, abiPoolFactory } from 'contracts/web3'
import { AbiPoolFactory } from 'contracts/web3/typings/abi_pool_factory'
import { useWhitelist, Whitelist } from './useWhitelist'
import { ERC20, useERC20 } from './useERC20'
import { validURLOrNull } from 'utils/URLUtils'
import { GET_POOL_TYPE } from 'apollo/queries'
import { clientV2 } from 'apollo/client'
import { BNish, Nullable } from 'utils/tsUtils'

interface PoolInfo {
  _creator: string
  _sellToken: string
  _buyToken: string
  _distributedBuyAmount: string
  _startTimestamp: string
  _endTimestamp: string
  _totalBuyAmount: string
  _totalSellAmount: string
  _types: string[]
  _values: string[]
  _deactivated: boolean
}

export interface PoolURLs {
  description: string | null
  website: string | null
  applicationForm: string | null
  image: string | null
  discord: string | null
  twitter: string | null
  whitepaper: string | null
  medium: string | null
  telegram: string | null
  github: string | null
}

export interface Pool {
  contract: AbiFixedPool | AbiLinearPool
  address: string
  type: PoolTypeState
  title: string
  whitelist: Whitelist
  creator: string
  sellToken: ERC20
  buyToken: ERC20
  distributedBuyAmount: string
  startTimestamp: Date
  endTimestamp: Date
  totalBuyAmount: string
  totalSellAmount: string
  deactivated: boolean
  URLs: PoolURLs
}

export function usePoolContract(
  provider: Provider,
  poolAddress: string,
  type: PoolTypeState | null,
): AbiFixedPool | AbiLinearPool | null {
  const [providerContract, setProviderContract] = useState<AbiFixedPool | AbiLinearPool | null>(null)
  useEffect(() => {
    if (type === null) {
      return
    }
    if (type === PoolTypeState.Fixed) {
      setProviderContract(new provider.eth.Contract(abiFixedPool, poolAddress) as unknown as AbiFixedPool)
    } else {
      setProviderContract(new provider.eth.Contract(abiLinearPool, poolAddress) as unknown as AbiLinearPool)
    }
  }, [type])
  return providerContract
}

type RefreshPoolInfoCallback = () => void

function usePoolInfo(poolContract: AbiFixedPool | AbiLinearPool | null): [PoolInfo | null, RefreshPoolInfoCallback] {
  const [poolInfo, setPoolInfo] = useState<PoolInfo | null>(null)

  async function requestPoolInfo(poolContract: AbiFixedPool | AbiLinearPool) {
    const poolInfo: PoolInfo = await poolContract.methods.poolInfo().call()
    setPoolInfo(poolInfo)
  }

  const refreshCallback = useCallback<RefreshPoolInfoCallback>(() => {
    if (poolContract !== null) {
      requestPoolInfo(poolContract)
    }
  }, [poolContract])

  useEffect(() => {
    if (poolContract !== null) {
      requestPoolInfo(poolContract)
    }
  }, [poolContract])

  return [poolInfo, refreshCallback]
}

// [contract address][pool type]
const poolTypeCache: Record<string, Nullable<PoolTypeState>> = {}

export function usePoolType(provider: Provider, poolAddress: string): PoolTypeState | null {
  const [poolType, setPoolType] = useState<PoolTypeState | null>(poolTypeCache[poolAddress] ?? null)
  useEffect(() => {
    async function requestPoolType() {
      if (provider.currentNetwork === NETWORK_TYPE.Mainnet) { //NOTE: use thegraph
        const referrer = await clientV2(provider).query({
          query: GET_POOL_TYPE(poolAddress),
        })
        const type = referrer.data.poolEvents[0]?.poolPrototype

        const poolType: PoolTypeState =
          type?.toLowerCase() == provider.contractSet?.linearPool?.toLowerCase()
            ? PoolTypeState.Linear
            : PoolTypeState.Fixed

        poolTypeCache[poolAddress] = poolType
        setPoolType(poolType)

      } else { //NOTE: use PoolFactory pools info
        const contract = new provider.eth.Contract(abiPoolFactory, provider.contractSet.poolFactory) as any as AbiPoolFactory;

        const poolInfo = await contract.methods.poolInfoByPoolAddress(poolAddress).call();

        const poolType: PoolTypeState =
          poolInfo.poolPrototypeAddress?.toLowerCase() == provider.contractSet?.linearPool?.toLowerCase()
            ? PoolTypeState.Linear
            : PoolTypeState.Fixed

        poolTypeCache[poolAddress] = poolType
        setPoolType(poolType)
      }
    }

    if (poolType === null) {
      requestPoolType()
    }
  }, [poolAddress, provider.eth.Contract, provider, poolType])
  return poolType
}

type PoolPriceBuyInSellUQCallback = () => void

export function usePoolPriceBuyInSellUQ(
  poolContract: AbiFixedPool | AbiLinearPool,
): [BNish | null, PoolPriceBuyInSellUQCallback] {
  const [priceBuyInSellUQ, setPriceBuyInSellUQ] = useState<BNish | null>(null)

  async function request() {
    const currentPriceBuyInSellUQ = await poolContract.methods.currentPriceBuyInSellUQ().call()
    setPriceBuyInSellUQ(currentPriceBuyInSellUQ)
  }

  const setPriceBuyInSellUQCallback = useCallback<PoolPriceBuyInSellUQCallback>(() => {
    request()
  }, [])

  useEffect(() => {
    request()
  }, [])

  return [priceBuyInSellUQ, setPriceBuyInSellUQCallback]
}

// [contract address][pool]
const poolAddressCache: Record<string, Nullable<Pool>> = {}

export type PoolRefreshCallback = () => void

export function usePool(provider: Provider, poolAddress: string): [Pool | null, PoolRefreshCallback] {
  const poolType = usePoolType(provider, poolAddress)
  const poolContract = usePoolContract(provider, poolAddress, poolType)
  const [poolInfo, refreshPoolInfo] = usePoolInfo(poolContract)
  const sellToken = useERC20(provider, poolInfo?._sellToken)
  const buyToken = useERC20(provider, poolInfo?._buyToken)
  const whitelist = useWhitelist(provider, poolContract)

  const refresh = useCallback<PoolRefreshCallback>(() => {
    refreshPoolInfo()
  }, [poolInfo])

  // return pool
  const pool: Pool | null = useMemo(() => {
    if (!poolInfo || !whitelist || !poolContract || !poolType || !buyToken || !sellToken) {
      return poolAddressCache[poolAddress] ?? null
    }
    const title = poolInfo._values[PoolFields.TITLE]
    const URLs: PoolURLs = {
      description: validURLOrNull(poolInfo._values[PoolFields.DESCRIPTION_URL]),
      website: validURLOrNull(poolInfo._values[PoolFields.WEBSITE_URL]),
      applicationForm: validURLOrNull(poolInfo._values[PoolFields.APPLICATION_FORM_URL]),
      image: poolInfo._values[PoolFields.IMAGE_URL],
      whitepaper: validURLOrNull(poolInfo._values[PoolFields.WHITEPAPER_URL]),
      medium: validURLOrNull(poolInfo._values[PoolFields.MEDIUM_URL]),
      twitter: validURLOrNull(poolInfo._values[PoolFields.TWITTER_URL]),
      discord: validURLOrNull(poolInfo._values[PoolFields.DISCORD_URL]),
      telegram: validURLOrNull(poolInfo._values[PoolFields.TELEGRAM_URL]),
      github: validURLOrNull(poolInfo._values[PoolFields.GITHUB_URL]),
    }
    const _pool: Pool = {
      contract: poolContract,
      address: poolAddress,
      type: poolType,
      title: title,
      whitelist: whitelist,
      creator: poolInfo._creator,
      sellToken: sellToken,
      buyToken: buyToken,
      distributedBuyAmount: poolInfo._distributedBuyAmount,
      startTimestamp: new Date(Number.parseInt(poolInfo._startTimestamp) * 1000),
      endTimestamp: new Date(Number.parseInt(poolInfo._endTimestamp) * 1000),
      totalBuyAmount: poolInfo._totalBuyAmount,
      totalSellAmount: poolInfo._totalSellAmount,
      deactivated: poolInfo._deactivated,
      URLs: URLs,
    }
    poolAddressCache[poolAddress] = _pool
    return _pool
  }, [poolInfo, whitelist, poolType, sellToken, buyToken])

  return [pool, refresh]
}

// [pool address][description]
const poolDescriptionCache: Record<string, Nullable<string>> = {}

export function usePoolDescription(pool: Pool | null): string | null | undefined {
  const [description, setDescription] = useState<string | null | undefined>(poolDescriptionCache[pool?.address ?? ''])

  useEffect(() => {
    async function requestPoolDescription(pool: Pool, URL: string | null) {
      const cached = poolDescriptionCache[pool.address]
      if (URL == null) {
        setDescription(cached)
        return
      }
      try {
        const response = await fetch(URL)
        const text = await response.text()
        poolDescriptionCache[pool.address] = text
        setDescription(text)
      } catch (e) {
        setDescription(cached)
      }
    }
    if (pool) {
      requestPoolDescription(pool, pool.URLs.description)
    }
  }, [pool])

  return description
}

type BuyAmountOfCallback = () => void
export function useBuyAmountOf(
  poolContract: AbiFixedPool | AbiLinearPool,
  account: string,
): [BNish | null, BuyAmountOfCallback] {
  const [buyAmount, setBuyAmount] = useState<BNish | null>(null)

  async function request() {
    const _buyAmount = await poolContract.methods.buyAmountOf(account).call()
    setBuyAmount(_buyAmount)
  }

  const buyAmountRefresh = useCallback<BuyAmountOfCallback>(() => {
    request()
  }, [poolContract])

  useEffect(() => {
    request()
  }, [poolContract])
  return [buyAmount, buyAmountRefresh]
}
