import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  footerContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '40px 50px 67px 50px',
    alignItems: 'center',
    '@media (max-width: 500px)': {
      flexDirection: 'column',
      '&>div': {
        margin: 20,
      },
    },
    backgroundColor: colors.defaultBlack,
  },
  footerLinks: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '5%',
    '@media (max-width: 500px)': {
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '50%',
    },
  },
  socialContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  iconContainer: {
    margin: '0 15px !important',
  },
  link: {
    fontSize: '1.13rem',
    lineHeight: '22px',
    fontWeight: 700,
    color: colors.defaultWhite,
    cursor: 'pointer',
    transition: 'all 0.4s',
    '&:hover': {
      textDecoration: 'none',
      color: colors.defaultYellow,
    },
  },
  footerRightsText: {
    fontSize: '0.88rem',
    lineHeight: '17px',
    fontWeight: 700,
    color: colors.defaultWhite,
  },
})
