import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  backButton: {
    height: '40px',
    maxWidth: '265px',
    width: '-webkit-fill-available',
    borderRadius: '10px',
    color: colors.white,
    backgroundColor: colors.defaultBlack,
    fontSize: '1.13rem',
    lineHeight: '22px',
    fontWeight: 700,
    textTransform: 'none',
    transition: 'all .4s',
    '&:hover': {
      opacity: 0.5,
      backgroundColor: colors.defaultBlack,
    },
    '&:active': {
      opacity: 0.3,
      backgroundColor: colors.defaultBlack,
    },
  },
  backButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '&>img': {
      marginRight: '12px',
    },
  },
})
