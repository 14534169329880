import { colors } from 'utils/mui'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  borderContainer: {
    border: colors.blackBorder,
    borderRadius: '15px',
    padding: '10px 10px 10px 0',
  },
  rootContainer: {
    padding: '15px 60px',
    maxHeight: '300px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.3em',
      background: colors.defaultGrey,
      borderRadius: 10,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 10,
      backgroundColor: colors.globalBlack,
    },
  },

  column: {
    display: 'flex',
    gap: 5,
    justifyContent: 'space-between',
  },
  row: {
    fontSize: '1em',
    color: colors.black,
    display: 'flex',
    justifyContent: 'space-between',
    margin: '30px 0',
    '&:first-child': {
      margin: '0',
    },
    '&:last-child': {
      margin: '0',
    },
  },
})
