import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  rootBlackContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '43px 45px',
    boxShadow: `0px 4px 4px ${colors.blackShadow_30}`,
    background: colors.cardBlackBg,
    borderRadius: '20px',
    width: '412px',
    height: '255px',
    cursor: 'pointer',
    border: 'none',
    '&:hover': {
      opacity: 0.7,
    },
    '&:active': {
      opacity: 0.3,
    },
  },
  rootYellowContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '43px 45px',
    background: colors.yellowLinearGradient,
    boxShadow: `0px 4px 4px ${colors.blackShadow_30}`,
    borderRadius: '20px',
    width: '412px',
    height: '255px',
    cursor: 'pointer',
    border: 'none',
    '&:hover': {
      opacity: 0.7,
    },
    '&:active': {
      opacity: 0.3,
    },
  },
  titleBlackText: {
    color: colors.defaultWhite,
    fontSize: '3rem',
    lineHeight: '56px',
    fontWeight: 500,
    padding: '23px 0 26px',
  },
  infoBlackText: {
    color: colors.defaultWhite,
    fontSize: '1.13rem',
    lineHeight: '22px',
    fontWeight: 400,
  },
  titleWhiteText: {
    color: colors.defaultGrayText,
    fontSize: '3rem',
    lineHeight: '56px',
    fontWeight: 500,
    padding: '23px 0 26px',
  },
  infoWhiteText: {
    color: colors.defaultGrayText,
    fontSize: '1.13rem',
    lineHeight: '22px',
    fontWeight: 400,
  },
})
