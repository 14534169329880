import React, { useEffect, useContext, useState } from 'react'
import { useStyles } from './styles'
import { StoreContext } from 'utils/store'
import { toast } from 'react-toastify'
import web3 from 'web3'
import { useTranslation } from 'react-i18next'

const CSVTable = ({
  setIsError,
  list,
}: {
  setIsError?: React.Dispatch<React.SetStateAction<boolean>>
  list: string
}): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [result, setResult] = useState<string[] | null>(null)
  const [validError, setValidError] = useState(true)
  const notifyError = () => toast.dark(t('pool_whitelist_file_error'), { autoClose: false })
  const notifyFetchError = () => toast.dark(t('pool_whitelist_path_error'))

  useEffect(() => {
    async function getUrl() {
      // Link with test file 'https://ipfs.io/ipfs/QmRuJ39xSMX8Ds3xNiQ9hnaoYCDbN9vmLPBsx4bYYKtfUq/distribution_ETH.csv'
      fetch(list)
        .then((response) => response.text())
        .then((data) => data.split('\n'))
        .then((data) => {
          setResult(data)
          validateTableData(data)
        })
        .catch((error) => {
          setIsError && setIsError(true)
          notifyFetchError()
          console.error('Error', error)
        })
    }
    getUrl()
  }, [list])

  const RowParser = (row: string) => {
    const reg = /"(.*?)"/g
    const matches = row.match(reg)
    if (matches) {
      return matches.map((value) => {
        return value.substr(1, value.length - 1)
      })
    }
    return row.split(',')
  }

  const validateTableData = (table: string[]) => {
    table.map((row) => {
      const rowArray = row.split(',')
      if (!web3.utils.isAddress(rowArray[0]) && typeof Number(rowArray[1]) !== 'number') {
        setValidError(false)
      }
    })
    if (!validError) {
      notifyError()
    }
  }

  return (
    <div className={classes.borderContainer}>
      {result && result.length > 0 && (
        <div className={classes.rootContainer}>
          {result.map((row, index) => (
            <div key={index} className={classes.row}>
              {RowParser(row).map((value, index) => (
                <div key={index}>{value}</div>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default CSVTable
