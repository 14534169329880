import { Web3Provider } from '@ethersproject/providers'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { Web3ReactProvider } from '@web3-react/core'
import { client } from 'apollo/client'
import { Layout } from 'components/layout'
import Claim from 'pages/claim'
import Pool from 'pages/pools'
import { PoolDescriptionSwitch } from 'pages/pool_description'
import { PoolEditSwitch } from 'pages/pool_edit'
import ProvidePoolsInfo from 'pages/pool_info'
import Summary from 'pages/pool_summary'
import PoolTypes from 'pages/pool_types'
import Stake from 'pages/stake'
import Statistics from 'pages/statistics'
import TokenInfo from 'pages/token_info'
import Whitelist from 'pages/whitelist'
import WhiteListingOptions from 'pages/whitelistingOptions'
import React, { ReactText } from 'react'
import { ApolloProvider } from 'react-apollo'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  CLAIM,
  POOLS,
  POOL_DESCRIPTION,
  POOL_EDIT,
  POOL_TYPES,
  PROVIDE_POOLS_INFO,
  STAKE,
  STATISTICS,
  SUMMARY,
  TOKEN_INFO,
  WHITELIST,
  WHITELISTING_OPTIONS,
} from 'routes'
import BlockUpdater from 'services/BlockUpdater'
import ConnectionManager from 'services/connectionManager/ConnectionManager'
import 'services/i18n'
import { RefreshContextProvider } from 'services/RefreshContext'
import TransactionUpdater from 'services/TransactionUpdater'
import { themeConfig } from 'utils/mui'

export const notify = (message = 'Address was copied'): ReactText => toast.dark(message)
export const notifyInvalidPromo = (): ReactText => toast.dark('Invalid promo')

const App = (): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getLibrary = (provider: any): Web3Provider => {
    const library = new Web3Provider(provider)
    library.pollingInterval = 30000
    return library
  }

  const theme = createMuiTheme(themeConfig)

  return (
    <ApolloProvider client={client}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <RefreshContextProvider>
          <BlockUpdater />
          <TransactionUpdater />
          <MuiThemeProvider theme={theme}>
            <Router>
              <ConnectionManager>
                <Layout>
                  <Switch>
                    {/*<Route exact path={PRESALE} component={Presale} />*/}
                    {/*<Route exact path={ACCOUNT} component={Account} />*/}
                    {/*<Route path={PRESALE_WITH_PROMO} component={Presale} />*/}
                    <Route exact path={STATISTICS} component={Statistics} />
                    {/* <Route exact path={HOME} component={Home} /> */}
                    <Route exact path={POOLS} component={Pool} />
                    <Route path={CLAIM} component={Claim} />
                    {/* <Route path={STAKE} component={Stake} /> */}
                    <Route path={POOL_TYPES} component={PoolTypes} />
                    <Route path={WHITELISTING_OPTIONS} component={WhiteListingOptions} />
                    <Route path={PROVIDE_POOLS_INFO} component={ProvidePoolsInfo} />
                    <Route path={WHITELIST} component={Whitelist} />
                    <Route path={TOKEN_INFO} component={TokenInfo} />
                    <Route path={SUMMARY} component={Summary} />
                    <Route path={POOL_EDIT} component={PoolEditSwitch} />
                    <Route path={POOL_DESCRIPTION} component={PoolDescriptionSwitch} />

                    {/* <Route exact path={LANDING} component={Landing} /> */}
                    <Redirect to={POOLS} />
                  </Switch>
                </Layout>
              </ConnectionManager>
            </Router>
          </MuiThemeProvider>
        </RefreshContextProvider>
      </Web3ReactProvider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </ApolloProvider>
  )
}

export default App
