import { makeStyles } from '@material-ui/core'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  input: {
    border: colors.blackBorder,
    borderRadius: '15px',
    height: '70px',
    fontSize: '1.6rem',
    outline: 'none',
    backgroundColor: 'transparent',
    padding: '0 5px',
    margin: '15px 0 0',
    textAlign: 'center',
    '&::placeholder': {
      color: colors.defaultWhite,
      textAlign: 'center',
      fontSize: '2rem',
    },
  },
  errorInput: {
    border: `2px solid ${colors.redError}`,
    borderRadius: '15px',
    height: '70px',
    fontSize: '1.6rem',
    outline: 'none',
    textAlign: 'center',
    backgroundColor: 'transparent',
    padding: '0 5px',
    margin: '15px 0 0',
    '&::placeholder': {
      color: colors.defaultWhite,
      textAlign: 'center',
      fontSize: '2rem',
    },
  },
})
