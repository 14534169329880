import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  rootContainer: {
    display: 'flex',
    padding: '0 12%',
    flexDirection: 'column',
    gap: '30px',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '-webkit-fill-available',
    gap: '30px',
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  columnSecondContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '30px',
  },
  columnAmountContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '7px',
  },
  blackBgContainer: {
    boxShadow: `0px 4px 4px ${colors.blackShadow_30}`,
    background: '#111111',
    borderRadius: '20px',
    padding: '26px 34px',
    '& > img': {
      width: '105px',
    },
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '30px',
    justifyContent: 'space-between',
    width: '-webkit-fill-available',
  },
  rowSecondContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    gap: '10px',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    gap: '10px',
  },
  borderContainer: {
    border: colors.blackBorder,
    borderRadius: '20px',
    padding: '35px 55px 56px 52px',
    width: '400px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  largeBlackTitle: {
    fontSize: '2.5rem',
    lineHeight: '59px',
    fontWeight: 500,
    maxWidth: '330px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#FFF'
  },
  mediumBlackTitle: {
    fontSize: '2.25rem',
    lineHeight: '44px',
    fontWeight: 600,
    paddingBottom: '25px',
    color: '#FFF'
  },
  smallBlackTitle: {
    fontSize: '1.5rem',
    lineHeight: '30px',
    fontWeight: 600,
    width: 'max-content',
    color: '#FFF'
  },
  textInfo: {
    fontSize: '1.125rem',
    lineHeight: '22px',
    color: '#FFF'
  },
  separator: {
    borderBottom: colors.greyBorder,
    marginBottom: ' 25px',
    opacity: '20%'
  },
  defaultButton: {
    height: '60px',
    width: '192px',
    background: colors.yellowLinearGradient,
    borderRadius: '10px',
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '1.5rem',
    cursor: 'pointer',
    color: colors.defaultWhite,
    boxShadow: `0px 4px 4px ${colors.blackShadow_25}`,
    '&:hover': {
      background: colors.yellowLinearGradient,
      opacity: 0.9,
    },
    '&:active': {
      background: colors.yellowLinearGradient,
      opacity: 0.8,
    },
  },
  description: {
    width: '530px',
    overflowY: 'auto',
    height: '311px',
    '&::-webkit-scrollbar': {
      width: '0.3em',
      background: colors.defaultGrey,
      borderRadius: 10,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: `inset 0 0 6px ${colors.blackShadow_0}`,
      webkitBoxShadow: `inset 0 0 6px ${colors.blackShadow_0}`,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 10,
      backgroundColor: colors.globalBlack,
    },
  },
  link: {
    cursor: 'pointer',
    transition: 'all 0.4s',
    color: colors.defaultWhite,
    '&:hover': {
      textDecoration: 'none',
    },
  },
})
