import React from 'react'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'

const Claim = (): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dailyBonus: { day: string; bonus: string }[] = [
    {
      day: t('claim_first_day'),
      bonus: '1.5',
    },
    {
      day: t('claim_second_day'),
      bonus: '0',
    },
    {
      day: t('claim_third_day'),
      bonus: '2.5',
    },
    {
      day: t('claim_fourth_day'),
      bonus: '0.2',
    },
    {
      day: t('claim_fifth_day'),
      bonus: '0.002',
    },
  ]

  return (
    <>
      <div className={classes.rootContainer}>
        <div className={classes.contentContainer}>
          <div className={classes.claimContainer}>
            <div className={classes.titleBlock}> {t('claim_available')} </div>

            <div className={classes.claimAmount}>10000 PAD</div>
            <div className={classes.defaultButton}>
              <div className={classes.buttonTextContainer}>
                <div className={classes.buttonText}>{t('claim_claim_button')} </div>
              </div>
            </div>

            <div className={classes.bonusBg}>
              <div className={classes.bonusContainer}>
                <div className={classes.bonusGoldText}>{t('claim_daily_bonus')} </div>

                <div>
                  {dailyBonus.map((el, index) => (
                    <div className={classes.bonusDailyContainer} key={index}>
                      <div className={classes.bonusGoldText}>{el.day}</div>
                      <div className={classes.dailyBonusAmount}>
                        <span className={classes.bonusBlackText}>{el.bonus}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Claim
