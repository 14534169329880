import { useCallback, useState } from 'react'
import { Transaction } from 'utils/store'
import { singletonHook } from 'react-singleton-hook'

export const TRANSACTION_LOCALSTORAGE_KEY = 'TRANSACTIONS'

const transactionsInitialState =
  (JSON.parse(localStorage.getItem(TRANSACTION_LOCALSTORAGE_KEY) as string) as Transaction[]) || []
let _transactions = transactionsInitialState

type TransactionCallback = (transactions: Transaction[]) => void

let _setTransactions: TransactionCallback = () => {
  throw new Error('TransactionCallback: initialize')
}

export const useTransactions = singletonHook(transactionsInitialState, () => {
  const [transactions, setTransactions] = useState(transactionsInitialState)
  _transactions = transactions
  _setTransactions = setTransactions
  return transactions
})

export const setTransaction = (transaction: Transaction) => {
  let newState: Transaction[]
  const index = _transactions.findIndex((e) => e.hash === transaction.hash)
  if (index >= 0) {
    newState = [..._transactions]
    newState[index] = transaction
  } else {
    newState = [..._transactions, transaction]
  }
  localStorage.setItem(TRANSACTION_LOCALSTORAGE_KEY, JSON.stringify(newState))
  _setTransactions(newState)
}

export function clearTransactions(): void {
  localStorage.setItem(TRANSACTION_LOCALSTORAGE_KEY, JSON.stringify([]))
  _setTransactions([])
} 
