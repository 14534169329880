import React, { useContext } from 'react'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'
import { POOL_TYPES, PROVIDE_POOLS_INFO } from 'routes'
import { StoreContext } from 'utils/store'
import ChoosesPoolCard from 'components/chooses_pool_card'
import Lottery from 'assets/icons/Lottery.svg'
import WhiteList from 'assets/icons/WhiteList.svg'
import TitleHeader from 'components/title_header'
import { useHistory } from 'react-router'

export enum WhitelistState {
  Whitelist = 'Whitelist',
  Lottery = 'Lottery',
}

const WhiteListingOptions = (): JSX.Element => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const { setListOption, poolType } = useContext(StoreContext)

  const WhitelistingInfo = [
    {
      img: WhiteList,
      title: t('pool_whitelist_whitelist'),
      info: t('pool_whitelist_whitelist_info'),
      path: PROVIDE_POOLS_INFO,
      isDark: false,
      listingOption: WhitelistState.Whitelist,
      isDisabled: false,
    },
    {
      img: Lottery,
      title: t('pool_whitelist_lottery'),
      info: t('pool_whitelist_lottery_info'),
      path: PROVIDE_POOLS_INFO,
      isDark: true,
      listingOption: WhitelistState.Lottery,
      isDisabled: true,
    },
  ]

  if (!poolType) history.push(POOL_TYPES)

  return (
    <>
      <div className={classes.rootContainer}>
        <TitleHeader
          title={t('pool_whitelist_title')}
          info={t('pool_whitelist_title_info_')}
          path={POOL_TYPES}
          currentStep={'2'}
          buttonTitle={t('pool_whitelist_back_button')}
        />
      </div>
      <div className={classes.cardContainer}>
        {WhitelistingInfo.map((el, index) => (
          <div key={index}>
            <ChoosesPoolCard {...el} setListingOption={setListOption} />
          </div>
        ))}
      </div>
    </>
  )
}

export default WhiteListingOptions
