import { BytesLike } from 'ethers'
import { TFunction } from 'react-i18next'
import { BN, BNish } from 'utils/tsUtils'

export enum State {
  SUCCESS,
  ERROR_UNSET,
  ERROR_MERKLE_ROOT_MISMATCH,
  ERROR_INSUFFICIENT_ALLOWANCE,
  ERROR_INSUFFICIENT_BALANCE,
  ERROR_ALLOCATION_EXCEEDED,
}

export function validateState(
  buyValue: BN | null,
  buyAllocation: BNish,
  buyTokenBought: BNish | null,
  sellValue: BN,
  sellAllowance: BNish | null,
  sellBalance: BNish | null,
  isValueToken: boolean,
  merkleRoot: string,
  contractMerkleRoot: BytesLike,
  isFCFSRound: boolean
): State {
  if (merkleRoot !== contractMerkleRoot) {
    return State.ERROR_MERKLE_ROOT_MISMATCH
  } else
  if (
    sellAllowance === null ||
    buyValue === null ||
    buyTokenBought === null ||
    sellBalance === null ||
    sellValue.isZero()
  ) {
    return State.ERROR_UNSET
  } else if (sellValue.gt(sellBalance)) {
    return State.ERROR_INSUFFICIENT_BALANCE
  } else if (!isFCFSRound && buyValue.add(buyTokenBought).gt(buyAllocation)) {
    return State.ERROR_ALLOCATION_EXCEEDED
  } else if (!isValueToken && sellValue.gt(sellAllowance)) {
    return State.ERROR_INSUFFICIENT_ALLOWANCE
  } else {
    return State.SUCCESS
  }
}

export function stateErrorMessage(state: State, t: TFunction<'translation'>): string | null {
  switch (state) {
    case State.SUCCESS:
      return null
    case State.ERROR_UNSET:
      return null
    case State.ERROR_MERKLE_ROOT_MISMATCH:
      return t('pool_card_error_merkle_mismatch')
    case State.ERROR_INSUFFICIENT_ALLOWANCE:
      return null 
    case State.ERROR_INSUFFICIENT_BALANCE:
      return t('pool_card_error_insufficient_balance')
    case State.ERROR_ALLOCATION_EXCEEDED:
      return t('pool_card_error_allocation_exceeded')
    default:
      return null
  }
}
