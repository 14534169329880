import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  rootContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 50px',
    width: '100%',
  },
  primaryButton: {
    width: '130px',
    height: '33px',
    marginTop: '40px',
    background: `transparent`,
    border: `1px solid ${colors.defaultWhite}`,
    color: '#fff',
    borderRadius: '5px',
    textTransform: 'none',
    fontSize: '0.75rem',
    cursor: 'pointer',
    alignSelf: 'center',
    '&:hover': {
      opacity: 0.6,
    },
    '&:active': {
      opacity: 0.3,
    },
  },
  secondaryButton: {
    width: '211px',
    height: '30px%',
    marginTop: '10px',
    background: `transparent`,
    border: `1px solid ${colors.defaultWhite}`,
    borderRadius: '5px',
    textTransform: 'none',
    fontSize: '1.5rem',
    fontWeight: 400,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.6,
    },
    '&:active': {
      opacity: 0.3,
    },
    color: '#FFF'
  },
  filterButton: {
    width: '100px',
    height: '25px%',
    background: `transparent`,
    border: `1px solid ${colors.defaultWhite}`,
    borderRadius: '5px',
    textTransform: 'none',
    fontSize: '1rem',
    fontWeight: 400,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.6,
    },
    '&:active': {
      opacity: 0.3,
    },
  },
  filterButtonClicked: {
    width: '100px',
    height: '25px%',
    background: colors.defaultGrey,
    border: `1px solid ${colors.defaultWhite}`,
    borderRadius: '5px',
    textTransform: 'none',
    fontSize: '1rem',
    fontWeight: 400,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.6,
    },
    '&:active': {
      opacity: 0.3,
    },
  },
  contentContainer: {
    padding: '0 5%',
    display: 'flex',
    flexDirection: 'column',
  },
  headingContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: '50px 0',
    width: '100%',
    justifyContent: 'flex-start',
  },
  headingLeft: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    maxWidth: '520px',
  },
  headingRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '520px',
    marginLeft: '50px',
  },
  headingLeftTitle: {
    color: colors.defaultYellow,
    fontSize: '3rem',
    lineHeight: '58px',
    fontWeight: 500,
  },
  headingLeftText: {
    color: colors.defaultWhite,
    fontSize: '1.13rem',
    lineHeight: '22px',
  },
  filterText: {
    color: colors.defaultWhite,
    fontSize: '2rem',
    lineHeight: '40px',
    marginRight: '20px'
  },
  filterWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  cardWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: '10px',
    marginTop: '30px',
  },
  defaultTitleBox: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '104px',
  },
  defaultTitle: {
    fontSize: '3rem',
    maxWidth: '700px',
    textAlign: 'center',
    color: colors.defaultYellow,
  },
})
