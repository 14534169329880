import { Button, Link, Tooltip } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { useWeb3React } from '@web3-react/core'
import DiscordIcon from 'assets/icons/DiscordIcon.svg'
import GitHubIcon from 'assets/icons/GitHubIcon.svg'
import MediumIcon from 'assets/icons/MediumIcon.svg'
import SmallLogo from 'assets/icons/SmallLogo.svg'
import TelegramIcon from 'assets/icons/TelegramIcon.svg'
import TwitterIcon from 'assets/icons/TwitterIcon.svg'
import { useBuyWithProof } from 'hooks/useWhitelist'
import { setTransaction } from 'hooks/useTransactions'
import WhitePaperIcon from 'assets/icons/WhitePaperIcon.svg'
import { BackButton } from 'components/buttons'
import Loader from 'components/loader'
import { BuyModal } from 'components/modals/modal_buy'
import { WarningModal } from 'components/modals/modal_warning'
import { WithdrawModal } from 'components/modals/modal_withdraw'
import ProgressBar from 'components/progress_bar'
import { useDeactivatedPool } from 'hooks/useEditPool'
import { Pool, PoolRefreshCallback, useBuyAmountOf, usePool, usePoolDescription } from 'hooks/usePoolDetails'
import { useWhitelistAllocation, useWhitelistResult } from 'hooks/useWhitelist'
import { WhitelistState } from 'pages/whitelistingOptions'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Redirect, useRouteMatch } from 'react-router-dom'
import { POOLS, POOL_EDIT_TEMPLATE } from 'routes'
import { NETWORK_TYPE, Provider, chainIdMap } from 'services/Provider'
import { useRefresh } from 'services/RefreshContext'
import { getDateDiff } from 'utils/dateFormatter'
import { Transaction } from 'utils/store'
import { formattedDecimals, fromBNish, Nullable,safeFromDecimals } from 'utils/tsUtils'
import { useStyles } from './styles'
import moment from 'moment'
import { MerkleTree } from 'utils/merkelTree'

export interface SubgraphInfo {
  creator: string
  poolPrototype: string
  whitelist: string
}

interface SocialInfo {
  img: string
  alt: string
  path: string
}

export interface PoolDescriptionProps {
  account: string
  provider: Provider
  pool: Pool
  poolRefresh: PoolRefreshCallback
}

export const PoolDescriptionSwitch = (): JSX.Element => {
  const match = useRouteMatch<{ address: string }>()
  const { account, library, chainId } = useWeb3React()

  if (!account || !library || !library.provider) {
    return <Redirect to="/" />
  }
  const provider = new Provider(library.provider, chainId)
  if (!provider.utils.isAddress(match.params.address)) {
    return <Redirect to="/" />
  }
  //eslint-disable-next-line
  const [pool, refreshPool] = usePool(provider, match.params.address)
  if (!pool) {
    return <Loader />
  }
  return <PoolDescription account={account} provider={provider} pool={pool} poolRefresh={refreshPool} />
}

const PoolDescription = ({ account, provider, pool, poolRefresh }: PoolDescriptionProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()

  const match = useRouteMatch<{ address: string }>()
  const description = usePoolDescription(pool)

  let whitelistResult:any;

  if (pool.contract.options.address  === '0x8aBd6F5fFe3Dcc3552C1b25da232Be525AF75BaF') {
    whitelistResult = {
      success: {
        merkleTree: new MerkleTree([]),
        participants: []
      },
      error: null
    }
  } else {
    //eslint-disable-next-line
    whitelistResult = useWhitelistResult(provider, pool.buyToken.address, pool.whitelist)
  }
  // account
  const allocationDetails = useWhitelistAllocation(whitelistResult, account, pool.contract.options.address  === '0x8aBd6F5fFe3Dcc3552C1b25da232Be525AF75BaF')

  const [buyTokensBought, refreshBuyTokensBought] = useBuyAmountOf(pool.contract, account)
  const [openWithdrawModal, setOpenWithdrawModal] = useState<boolean>(false)
  const [openBuyModal, setOpenBuyModal] = useState<boolean>(false)
  const [txResult, setTxResult] = useState<Nullable<Transaction>>(null)
  const [txModalOpen, setTxModalOpen] = useState<boolean>(false)
  const { slowRefresh } = useRefresh()
  const [isDeleted, setIsDeleted] = useState<boolean>(false)
  //const buyWithProof = useBuyWithProof(provider, pool.sellToken, pool.whitelist)

  useEffect(() => {
    const deletePool = async () => {
      //eslint-disable-next-line
      const info = await useDeactivatedPool(pool.contract)
      setIsDeleted(info)
    }
    deletePool()
  })

  useEffect(() => {
    refreshBuyTokensBought()
    poolRefresh()
  }, [slowRefresh])

  const [poolImage, setPoolImage] = useState<string>(SmallLogo)

  const handleOpenWithdraw = () => {
    setOpenWithdrawModal(true)
  }
  const handleOpenBuy = async () => {
    // const tx = await buyWithProof(
    //   ['aaa'],
    //   account,
    //   safeFromDecimals('0.0125', 18, fromBNish(0)),
    //   safeFromDecimals('0.0125', 18, fromBNish(0)),
    //   chainIdMap[provider.currentNetwork],
    //   setTransaction,
    // )
    // handleClose(tx)
    setOpenBuyModal(true)
  }

  const onTransactionAlertClosed = () => {
    setTxModalOpen(false)
  }

  const handleClose = (tx: Nullable<Transaction>) => {
    setOpenWithdrawModal(false)
    setOpenBuyModal(false)

    if (tx) {
      setTxResult(tx)
      setTxModalOpen(true)
    }
  }

  const socialInfo: SocialInfo[] = [
    // { img: DiscordIcon, alt: 'discord icon', path: pool.URLs.discord },
    { img: TwitterIcon, alt: 'twitter icon', path: pool.URLs.twitter },
    // { img: WhitePaperIcon, alt: 'whitepaper icon', path: pool.URLs.whitepaper },
    { img: MediumIcon, alt: 'medium icon', path: pool.URLs.medium },
    { img: TelegramIcon, alt: 'telegram icon', path: pool.URLs.telegram },
    // { img: GitHubIcon, alt: 'github icon', path: pool.URLs.github },
  ]
    .filter((s) => s.path !== null)
    .map<SocialInfo>((s) => ({ ...s, path: s.path ?? '' }))

  const handleImageError = () => {
    setPoolImage(SmallLogo)
  }

  useEffect(() => {
    setPoolImage(pool?.URLs.image ?? SmallLogo)
  }, [pool])

  const startDate = pool.startTimestamp.getTime()
  const endDate = pool.endTimestamp.getTime()
  const currentDate = new Date().getTime()
  let dateTitle = ''
  let date: string | undefined = ''

  if (currentDate < startDate) {
    date = getDateDiff(startDate, t)
    dateTitle = t('pool_card_starts_in')
  } else if (currentDate >= endDate) {
    date = t('pool_edit_completed')
    dateTitle = t('pool_edit_status')
  } else {
    date = getDateDiff(endDate, t)
    dateTitle = t('pool_card_ends')
  }
  if (isDeleted) {
    date = t('pool_edit_deleted')
    dateTitle = t('pool_edit_status')
  }

  const showButton = (startDate: number, endDate: number, isDeleted: boolean) => {
    const isUser = pool.contract.options.address  === '0x8aBd6F5fFe3Dcc3552C1b25da232Be525AF75BaF' ? true : (allocationDetails !== null)
    const isCreator = account.toLowerCase() == pool.creator.toLowerCase()
    const currentDate = new Date().getTime()

    const now = moment(new Date())
    const timeInMillisecondsToEnd = moment(endDate).diff(now, 'milliseconds')

    const allAllocationBought =
      allocationDetails !== null &&
      buyTokensBought !== null &&
      timeInMillisecondsToEnd > 1000 * 60 * 60 * 4 ? fromBNish(buyTokensBought).gte(allocationDetails.allocation) : false
    if (isDeleted) {
      return (
        <div className={classes.rowContainer}>
          <div className={classes.columnAmountContainer}>
            <span className={classes.textInfo}>{t('pool_edit_status')}</span>
            <span className={classes.smallBlackTitle}>{t('pool_edit_deleted')}</span>
          </div>
        </div>
      )
    }
    if (isCreator) {
      if (currentDate < startDate) {
        return (
          <div className={classes.buttonContainer}>
            <Button
              className={classes.defaultButton}
              onClick={() => history.push(POOL_EDIT_TEMPLATE(match.params.address))}
            >
              {t('pool_card_edit_button')}
            </Button>
          </div>
        )
      }
      if (currentDate < endDate) {
        return (
          <div className={classes.rowContainer}>
            <div className={classes.columnAmountContainer}>
              <span className={classes.textInfo}>{t('pool_edit_your_satus')}</span>
              <span className={classes.smallBlackTitle}>{t('pool_edit_creator')}</span>
            </div>
          </div>
        )
      } else {
        return (
          <div className={classes.buttonContainer}>
            <Button className={classes.defaultButton} onClick={handleOpenWithdraw}>
              {t('pool_card_withdraw_button')}
            </Button>
          </div>
        )
      }
    }

    if (isUser) {
        return (
          <div className={classes.rowContainer}>
            <div className={classes.columnAmountContainer}>
              <span className={classes.textInfo}>{t('pool_edit_your_satus')}</span>
              <span className={classes.smallBlackTitle}>{pool.contract.options.address  !== '0x8aBd6F5fFe3Dcc3552C1b25da232Be525AF75BaF' ? t('pool_edit_in_whitelist') : 'Participant'}</span>
            </div>
            {allocationDetails !== null ? (
              <div className={classes.columnAmountContainer}>
                <span className={classes.textInfo}>{t('pool_card_bought')}</span>
                <span className={classes.smallBlackTitle}>
                  {`${formattedDecimals(buyTokensBought ?? 0, pool.buyToken.decimals, pool.buyToken.symbol)}`}
                  {pool.contract.options.address  !== '0x8aBd6F5fFe3Dcc3552C1b25da232Be525AF75BaF' && (
                  `/${formattedDecimals(
                    allocationDetails.allocation,
                    pool.buyToken.decimals,
                    pool.buyToken.symbol,
                  )}`
                  )}
                </span>
              </div>
            ) : null}
            {(currentDate > startDate && currentDate < endDate && !allAllocationBought) ? (
              <div className={classes.buttonContainer}>
                <Button className={classes.defaultButton} onClick={handleOpenBuy}>
                  {t('common_buy_button')}
                </Button>
              </div>
            ) : null}
            {currentDate >= endDate ? (
              <div className={classes.buttonContainer}>
                <Button className={classes.defaultButton} onClick={handleOpenWithdraw}>
                  {t('pool_card_withdraw_button')}
                </Button>
              </div>
            ) : null}
          </div>
        )
    }

    if (!isUser && currentDate < startDate) {
      return (
        <div className={classes.rowContainer}>
          <div className={classes.columnAmountContainer}>
            <span className={classes.textInfo}>{t('pool_edit_your_satus')}</span>
            <span className={classes.smallBlackTitle}>{t('pool_edit_not_in_whitelist')}</span>
          </div>
          <div className={classes.columnAmountContainer}>
            {pool.URLs.applicationForm ? (
              <Link className={classes.link} target="_blank" href={pool.URLs.applicationForm}>
                <Button className={classes.defaultButton}>{t('pool_card_apply_to_pool')}</Button>
              </Link>
            ) : null}
          </div>
        </div>
      )
    }
  }

  const keyMetrics = [
    { title: dateTitle, value: date },
    { title: t('pool_card_token_symbol'), value: pool.buyToken.symbol },
    { title: t('pool_card_blockchain_network'), value: provider.currentNetwork },
    { title: t('pool_card_whitelisting_option'), value: pool.contract.options.address  === '0x8aBd6F5fFe3Dcc3552C1b25da232Be525AF75BaF' ? WhitelistState.Lottery : WhitelistState.Whitelist },
  ]

  if (pool.contract.options.address  !== '0x8aBd6F5fFe3Dcc3552C1b25da232Be525AF75BaF') {
    keyMetrics.push(
      { title: t('summary_pool_type'), value: pool.type },
    )
    keyMetrics.push(
      {
        title: t('pool_card_distributed'),
        value: `${formattedDecimals(pool.distributedBuyAmount, pool.buyToken.decimals, pool.buyToken.symbol)}`,
      }
    )
  }

  return (
    <div className={classes.rootContainer}>
      <BackButton path={POOLS} title={t('common_back_button')} />
      <div className={classes.rowContainer}>
        <div className={classes.columnContainer}>
          <div className={classes.contentContainer}>
            <div className={classes.rowContainer} style={{ justifyContent: 'flex-start' }}>
              <div className={classes.blackBgContainer}>
                <img onError={handleImageError} src={poolImage} alt="pool card" />
              </div>
              <div className={classes.columnContainer}>
                <div className={classes.rowContainer}>
                  <Tooltip title={pool.title}>
                    <span className={classes.largeBlackTitle}>{pool.title}</span>
                  </Tooltip>
                </div>
                <div className={classes.rowContainer}>
                  {socialInfo.map(({ path, img, alt }, index) => (
                    <span key={index}>
                      <Link href={path} target="_blank" rel="noopener noreferrer">
                        <img style={{ height: '30px' }} src={img} alt={alt} />
                      </Link>
                    </span>
                  ))}
                </div>
                {pool.URLs.website && (
                  <div className={classes.rowContainer}>
                    <Link href={pool.URLs.website} target="_blank" rel="noopener noreferrer" className={classes.link}>
                      {t('provide_pools_website_url')}
                    </Link>
                  </div>
                )}
              </div>
            </div>
            <div className={classes.rowContainer}>
              {pool.contract.options.address === '0x2007298370B5e6284463333843db9B5895A9Be36' && (
                <Link href='https://explorer.mainnet.aurora.dev/address/0x4148d2Ce7816F0AE378d98b40eB3A7211E1fcF0D' target="_blank" rel="noopener noreferrer" className={classes.link} style={{ textDecoration: 'underline' }}>
                  BlueBit token
                </Link>
              ) }

              {pool.contract.options.address === '0xf605CdAf60D4a252f4056421EEde9489DEA830c0' && (
                <Link href='https://explorer.mainnet.aurora.dev/address/0x6454e4a4891C6B78a5A85304d34558DDa5F3d6D8' target="_blank" rel="noopener noreferrer" className={classes.link} style={{ textDecoration: 'underline' }}>
                  JUMBO token link
                </Link>
              ) }

              {pool.contract.options.address === '0xe2eE9c4D4604465bEce33eAc4e2f3e9767D84435' && (
                <Link href='https://explorer.mainnet.aurora.dev/address/0xc413b6e14bEb32E03E4F6C0f485D2dd8121877f5' target="_blank" rel="noopener noreferrer" className={classes.link} style={{ textDecoration: 'underline' }}>
                  ODO token link
                </Link>
              ) }

              {pool.contract.options.address === '0x75eB7c42CB3159Be6c48941412211E954Bd28350' && (
                <Link href='https://explorer.mainnet.aurora.dev/address/0xd5c997724e4b5756d08e6464c01afbc5f6397236' target="_blank" rel="noopener noreferrer" className={classes.link} style={{ textDecoration: 'underline' }}>
                  Farm Me token link
                </Link>
              ) }

              {pool.contract.options.address === '0xe4993471074671507fFb8D5C825e985a11d50e98' && (
                <Link href='https://explorer.mainnet.aurora.dev/address/0x30975acac70b5d774d6f756acd03a9b90cd4d4f5' target="_blank" rel="noopener noreferrer" className={classes.link} style={{ textDecoration: 'underline' }}>
                  Mutant Swap token
                </Link>
              ) }

              {pool.contract.options.address === '0xf5548dfed147E0A7F0476547e4FE8892407f6E06' && (
                <Link href='https://explorer.mainnet.aurora.dev/address/0xFC56694aB428E7B4A03b8299B502D4598D945217' target="_blank" rel="noopener noreferrer" className={classes.link} style={{ textDecoration: 'underline' }}>
                  Scorefam token
                </Link>
              ) }

              {pool.contract.options.address === '0xE4044A41C46d61A1C9400a2db0556Ad25B5b8872' && (
                <Link href='https://aurorascan.dev/token/0x7538162f05bec5084a92a5f47c2a094fcf73e372' target="_blank" rel="noopener noreferrer" className={classes.link} style={{ textDecoration: 'underline' }}>
                  Fusotao token
                </Link>
              ) }
            </div>

            <div className={classes.description}>
              {description !== undefined && description !== null ? (
                <span className={classes.textInfo}>{description}</span>
              ) : description === undefined ? (
                <>
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </>
              ) : null}
            </div>
          </div>

          <div className={classes.columnSecondContainer}>
            <span className={classes.textInfo}>{t('pool_card_total_raise')}</span>
            <div className={classes.rowSecondContainer}>
              <span className={classes.largeBlackTitle}>
                {formattedDecimals(pool.totalSellAmount, pool.sellToken.decimals)}
              </span>
              <span className={classes.smallBlackTitle}>{pool.sellToken.symbol}</span>
            </div>

            {pool.contract.options.address  !== '0x8aBd6F5fFe3Dcc3552C1b25da232Be525AF75BaF' && (
              <ProgressBar currentValue={pool.totalBuyAmount} maxValue={pool.distributedBuyAmount} />
            )}

          </div>
          {showButton(startDate, endDate, isDeleted)}
        </div>

        <div className={classes.columnContainer}>
          <div className={classes.borderContainer}>
            <span className={classes.mediumBlackTitle}>{t('pool_card_key_metrics')}</span>
            {keyMetrics.map((el, index) => (
              <span key={index}>
                {index !== 0 && <div className={classes.separator} />}
                <div className={classes.rowContainer}>
                  <span className={classes.textInfo}>{el.title}</span>
                  <span className={classes.smallBlackTitle}>{el.value}</span>
                </div>
              </span>
            ))}
          </div>
        </div>
      </div>
      {whitelistResult.success && allocationDetails ? (
        <BuyModal
          handleClose={handleClose}
          open={openBuyModal}
          pool={pool}
          account={account}
          provider={provider}
          whitelistSuccess={whitelistResult.success}
          buyAllocation={allocationDetails.allocation}
        />
      ) : null}
      <WithdrawModal handleClose={handleClose} open={openWithdrawModal} pool={pool} />
      <WarningModal
        handleClose={onTransactionAlertClosed}
        onClick={onTransactionAlertClosed}
        open={txModalOpen}
        transaction={txResult?.hash}
        titleText={t('pool_card_success_dialog_title')}
        infoText={t('pool_card_success_dialog_description')}
        buttonStyle={classes.defaultButton}
        buttonText={t('summary_ok')}
      />
    </div>
  )
}
