import moment from 'moment'
import { TFunction } from 'react-i18next'
const MILLISECONDS_IN_SECONDS = 1000
export const MILLISECONDS_IN_MINUTE = 1000 * 60
export const MILLISECONDS_IN_DAY = MILLISECONDS_IN_MINUTE * 60 * 24

export function getFormattedDate(date: Date | number): string {
  return moment(date).format('DD.MM.YYYY')
}

export function millisecondsToSeconds(date: number) {
  return Math.floor(date / 1000)
}
export function secondsToMilliseconds(date: number | string) {
  return Number(date) * MILLISECONDS_IN_SECONDS
}

export function getDateDiff(date: number, t: TFunction<'translation'>): string | undefined {
  const now = moment(new Date())
  const poolDate = moment(date)

  const timeDifferenceDays = poolDate.diff(now, 'days')
  const timeDifferenceHours = poolDate.diff(now, 'hours')
  const timeDifferenceMinutes = poolDate.diff(now, 'minutes')
  const timeDifferenceSeconds = poolDate.diff(now, 'second')

  if (timeDifferenceDays > 0) {
    return timeDifferenceDays === 1
      ? `${timeDifferenceDays} ${t('common_day')}`
      : `${timeDifferenceDays} ${t('common_days')}`
  }
  if (timeDifferenceHours > 0) {
    if (timeDifferenceMinutes % 60 > 0) {
      return timeDifferenceHours === 1
        ? `${(timeDifferenceMinutes - timeDifferenceMinutes % 60) / 60} ${t('common_hour')} ${timeDifferenceMinutes % 60} ${t('common_minutes')}`
        : `${(timeDifferenceMinutes - timeDifferenceMinutes % 60) / 60} ${t('common_hours')} ${timeDifferenceMinutes % 60} ${t('common_minutes')}`
    }

    return timeDifferenceHours === 1
      ? `${timeDifferenceHours} ${t('common_hour')}`
      : `${timeDifferenceHours} ${t('common_hours')}`
  }

  if (timeDifferenceMinutes > 0) {
    return timeDifferenceMinutes === 1
      ? `${timeDifferenceMinutes} ${t('common_minute')}`
      : `${timeDifferenceMinutes} ${t('common_minutes')}`
  }
  if (timeDifferenceSeconds > 0) {
    return timeDifferenceSeconds === 1
      ? `${timeDifferenceSeconds} ${t('common_second')}`
      : `${timeDifferenceSeconds} ${t('common_seconds')}`
  }
}
