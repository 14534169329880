import { useCallback, useEffect, useMemo, useState } from 'react'
import { NETWORK_TYPE, Provider } from 'services/Provider'
import { AbiFixedPool } from 'contracts/web3/typings/abi_fixed_pool'
import { AbiLinearPool } from 'contracts/web3/typings/abi_linear_pool'
import { abiTreeWhitelist, abiWhitelistNoLimit } from 'contracts/web3'
import { AbiTreeWhitelist } from 'contracts/web3/typings/abi_tree_whitelist'
import { AbiWhitelistNoLimit } from 'contracts/web3/typings/abi_whitelist_no_limit'
import { BytesLike } from 'ethers'
import { validURLOrNull } from 'utils/URLUtils'
import { ERC20, useERC20 } from './useERC20'
import { computeWhitelist, requestWhitelist, WhitelistResult } from 'utils/treeUtils'
import { createTransaction, Transaction } from 'utils/store'
import { BNish } from 'utils/tsUtils'
import { transpileModule } from 'typescript'
import { MerkleTree } from 'utils/merkelTree'

export interface Whitelist {
  contract: AbiTreeWhitelist
  address: string
  root: BytesLike
  whitelistURL: string | null
}

type WhitelistContract = { contract: AbiTreeWhitelist; address: string }

function useWhitelistContract(
  provider: Provider,
  poolContract: AbiFixedPool | AbiLinearPool | null,
): WhitelistContract | null {
  const [whitelistContract, setWhitelistContract] = useState<WhitelistContract | null>(null)

  useEffect(() => {
    async function requestWhitelist(poolContract: AbiFixedPool | AbiLinearPool) {
      const whitelistAddress = await poolContract.methods.whitelist().call()

      let whitelist:any;

      if (provider.currentNetwork === NETWORK_TYPE.Aurora) {
        if (poolContract.options.address === '0x8aBd6F5fFe3Dcc3552C1b25da232Be525AF75BaF') { //NOTE: Aurora token IDO
          whitelist = new provider.eth.Contract(abiWhitelistNoLimit, whitelistAddress) as unknown as AbiWhitelistNoLimit

        } else {
          whitelist = new provider.eth.Contract(abiTreeWhitelist, whitelistAddress) as unknown as AbiTreeWhitelist
        }

      } else {
        whitelist = new provider.eth.Contract(abiTreeWhitelist, whitelistAddress) as unknown as AbiTreeWhitelist
      }

      setWhitelistContract({ contract: whitelist, address: whitelistAddress })
    }
    if (poolContract) {
      requestWhitelist(poolContract)
    }
  }, [poolContract])

  return whitelistContract
}

export function useWhitelist(provider: Provider, poolContract: AbiFixedPool | AbiLinearPool | null): Whitelist | null {
  const [whitelist, setWhitelist] = useState<Whitelist | null>(null)
  const whitelistContract = useWhitelistContract(provider, poolContract)

  useEffect(() => {
    async function requestWhitelistDetails(whitelistContract: WhitelistContract) {
      const whitelist: Whitelist = {
        contract: whitelistContract.contract,
        address: whitelistContract.address,
        root: '',
        whitelistURL: ''
      }

      if (poolContract?.options.address !== '0x8aBd6F5fFe3Dcc3552C1b25da232Be525AF75BaF') {
        whitelist.root = await whitelistContract.contract.methods.root().call()
        whitelist.whitelistURL = await whitelistContract.contract.methods.linkToCSV().call()
      }

      setWhitelist(whitelist)
    }
    if (whitelistContract) {
      requestWhitelistDetails(whitelistContract)
    }
  }, [whitelistContract])

  return whitelist
}

export function useWhitelistResult(
  provider: Provider,
  buyTokenAddress: string | null,
  whitelist: Whitelist | null
): WhitelistResult {
  type Result = { result: string | WhitelistResult; decimals: number }

  const [whitelistRequestResult, setWhitelistRequestResult] = useState<Result>({
    result: {
      success: null,
      error: null,
    },
    decimals: 0,
  })


  //eslint-disable
  const ERC20 = useERC20(provider, buyTokenAddress)
  useEffect(() => {
    async function requestWhitelistRecords(URL: string | null, decimals: number) {
      const result = await requestWhitelist(URL)
      setWhitelistRequestResult({ result, decimals })
    }
    if (whitelist && ERC20) {
      requestWhitelistRecords(whitelist?.whitelistURL || null, ERC20?.decimals || 0)
    }
  }, [whitelist, ERC20])

  return useMemo(
    () => computeWhitelist(whitelistRequestResult.result, whitelistRequestResult.decimals),
    [whitelistRequestResult],
  )
}

export function useWhitelistAllocation(
  whitelistResult: WhitelistResult,
  account: string,
  isAurora: boolean
): { allocation: string } | null {
  return useMemo(() => {
    if (isAurora) {
      return {
        allocation: '10000'
      }
    }
    if (!whitelistResult.success) {
      return null
    }
    const allocationIndex: number | null = whitelistResult.success.participants.findIndex(
      (p) => p.address.toLowerCase() === account.toLowerCase(),
    )
    if (allocationIndex !== -1) {
      return {
        allocation: whitelistResult.success.participants[allocationIndex].maxAllocation.toString(),
      }
    }
    return null
  }, [whitelistResult, account, isAurora])
}

type BuyWithProof = (
  proof: string[],
  account: string,
  maxAllocation: BNish,
  sellAmount: BNish,
  chainId: number,
  updateTransactions: (transaction: Transaction) => void,
) => Promise<Transaction>

export function useBuyWithProof(provider: Provider, sellToken: ERC20, whitelist: Whitelist): BuyWithProof {
  return useCallback<BuyWithProof>(
    async (proof, account, maxAllocation, sellAmount, chainId, updateTransactions) => {
      return new Promise((resolve) => {
        if (provider.contractSet.WETH === sellToken.address) {
          whitelist.contract.methods
            .exchangeValue([], maxAllocation.toString())
            .send({ from: account, value: sellAmount.toString() })
            .on('transactionHash', function (hash: string) {
              console.log('transactionHash', hash)
              const transaction: Transaction = createTransaction(hash, 'eth', 'Buy', chainId)
              updateTransactions(transaction)
              resolve(transaction)
            })
        } else {
          whitelist.contract.methods
            .exchange(proof, maxAllocation.toString(), sellAmount.toString())
            .send({ from: account })
            .on('transactionHash', function (hash: string) {
              console.log('transactionHash', hash)
              const transaction: Transaction = createTransaction(hash, 'eth', 'Buy', chainId)
              updateTransactions(transaction)
              resolve(transaction)
            })
        }
      })
    },
    [sellToken],
  )
}
