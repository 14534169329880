import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  rootContainer: {
    padding: '10px 50px',
    display: 'flex',
    flexDirection: 'column',
  },
  contentContainer: {
    padding: '72px 12% 0',
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
  },
  infoText: {
    color: colors.defaultWhite,
    fontSize: '1.13rem',
    lineHeight: '22px',
  },
  titleWhiteText: {
    color: colors.defaultGrayText,
    fontSize: '3rem',
    lineHeight: '58px',
    fontWeight: 500,
  },
  infoWarningText: {
    color: colors.defaultWhite,
    fontSize: '1rem',
    marginLeft: '20px',
  },
  infoWhiteText: {
    color: colors.defaultGrayText,
    fontSize: '1.13rem',
    lineHeight: '22px',
    padding: '4px 0 20px',
  },
  goldTitle: {
    color: colors.primaryYellowSecond,
    fontSize: '1.125rem',
    lineHeight: '29px',
    fontWeight: 500,
  },
  card: {
    maxWidth: '1022px',
    background: `linear-gradient(180deg, ${colors.primaryYellowFirst} 22.4%, ${colors.primaryYellowSecond} 66.15%)`,
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'column',
    padding: '61px 67px 42px 67px',
    boxSizing: 'border-box',
  },
  blackInput: {
    backgroundColor: 'transparent',
    color: colors.defaultWhite,
    outline: 'none',
    margin: '2px',
    height: '90px',
    border: `2px solid ${colors.defaultWhite}`,
    borderRadius: '15px',
    fontSize: '2rem',
    lineHeight: '29px',
    fontWeight: 700,
    textAlign: 'center',
    '&::placeholder': {
      color: colors.placeholder,
      textAlign: 'center',
      fontSize: '2rem',
    },
  },
  inputsContainer: {
    border: colors.blackBorder,
    padding: '25px 40px',
    borderRadius: '20px',
    marginTop: '40px',
  },
  socialInputsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 20,
    '&>div': {
      marginTop: '10px',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  socialTitle: {
    fontFamily: 'Formular',
    fontSize: '3rem',
    fontWeight: 400,
    '&>span': {
      fontSize: '1.2rem',
      fontWeight: 300,
      marginLeft: 10,
    },
  },
  segmentBox: {
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '20px',
  },
  segmentContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: '20px',
  },
  segmentBlackContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    background: colors.defaultBlack,
    borderRadius: '15px',
    width: '330px',
    padding: '30px 30px 50px 45px',
  },
  segmentSliders: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  defaultButton: {
    width: '306px',
    height: '100px',
    marginTop: '20px',
    background: colors.yellowLinearGradient,
    borderRadius: '10px',
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '1.5rem',
    cursor: 'pointer',
    color: colors.defaultWhite,
    boxShadow: `0px 4px 4px ${colors.blackShadow_25}`,
    '&:hover': {
      background: colors.yellowLinearGradient,
      opacity: 0.9,
    },
    '&:active': {
      background: colors.yellowLinearGradient,
      opacity: 0.8,
    },
  },
  flex_column: { display: 'flex', flexDirection: 'column' },
})
