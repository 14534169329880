import { ThemeOptions } from '@material-ui/core'
import { MuiPickersOverrides } from '@material-ui/pickers/typings/overrides'
import { AutocompleteClassKey } from '@material-ui/lab/Autocomplete'

type overridesNameToClassKey = {
  [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P]
}
declare module '@material-ui/core/styles/overrides' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface ComponentNameToClassKey extends overridesNameToClassKey {}
}
declare module '@material-ui/core/styles/overrides' {
  interface ComponentNameToClassKey {
    MuiAutocomplete: AutocompleteClassKey
  }
}

export const colors: { [key: string]: string } = {
  primaryYellowFirst: '#33B415',
  primaryYellowSecond: '#237A0E',
  globalBlack: '#000000',
  defaultYellow: '#41B129',
  defaultWhite: '#FFF',
  textYellow: '#F7D46D',
  defaultBlack: '#000',
  placeholderPrimaryYellowSecond: 'rgba(245, 208, 97, 0.5)',
  placeholder: 'rgba(19, 22, 27, 0.5)',
  defaultGray: '#3B3B3B',
  white: '#FFF',
  cardAddressGrey: '#A3A3A5',
  defaultGrey: '#CCCCCC',
  secondGrey: '#7C7C7C',
  cardGreen: '#41B129',
  cardYellow: '#E8B83E',
  cardBlackBg: '#1F2024',
  defaultGrayText: '#C4C4C4',
  redError: '#FF0202',
  violet: '#6F4BFF',
  redButton: '#FB6E6E',
  disabledButton: '#7C7C7C',

  blackShadow_15: 'rgba(0, 0, 0, 0.15)',
  blackShadow_20: 'rgba(0, 0, 0, 0.2)',
  blackShadow_25: 'rgba(0, 0, 0, 0.25)',
  blackShadow_30: 'rgba(0, 0, 0, 0.3)',
  blackShadow_50: 'rgba(0, 0, 0, 0.5)',
  blackShadow_0: 'rgba(0,0,0,0.00)',
  lightShadow_80: 'rgba(255, 255, 255, 0.8)',
  yellowLinearGradient: 'linear-gradient(180deg, #33B415 0%, #237A0E 100%)',
  blackBorder: '2px solid #13161B',
  greyBorder: '2px solid #CCCCCC',
}
export const color = (c: string): string => colors[c]
export const buildVariant = (fontWeight: number, fontSize: number, color: string): unknown => ({
  fontWeight,
  fontSize,
  color: colors[color],
})

export const themeConfig: ThemeOptions | undefined = {
  typography: {
    h2: {
      fontSize: 14,
      lineHeight: '17px',
      color: colors.titleColorH2,
    },
    h4: {
      color: `${colors.defaultBlack} !important`,
    },
    h5: {
      color: `${colors.primaryYellowSecond} !important`,
    },
  },

  overrides: {
    MuiAutocomplete: {
      clearIndicatorDirty: {
        color: colors.defaultBlack,
      },
      popupIndicator: {
        color: colors.defaultBlack,
      },
      input: {
        '&:first-child': {
          margin: '-21px',
          height: 66,
          paddingLeft: `${'30px'} !important`,
        },
      },
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: colors.primaryYellowSecond,
      },
    },
    MuiInputLabel: {
      outlined: {
        color: colors.primaryYellowSecond,
        fontWeight: 500,
        transform: 'translate(14px, 20px) scale(1.4)',
      },
      animated: {
        color: `${colors.defaultBlack} !important`,
        fontWeight: 500,
        padding: '0 5px',
        '&:hover': {
          color: colors.primaryYellowSecond,
          fontWeight: 500,
          background: colors.defaultBlack,
          padding: '0 5px',
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '1rem',
      },
    },
    MuiPickersDay: {
      day: {
        color: colors.defaultBlack,
      },
      daySelected: {
        backgroundColor: colors.primaryYellowSecond,
        color: colors.defaultBlack,
        '&:hover': { backgroundColor: colors.primaryYellowFirst },
      },
      current: {
        color: colors.primaryYellowSecond,
      },
    },
    MuiTabs: {
      root: {
        backgroundColor: `${colors.primaryYellowSecond} !important`,
      },
      indicator: {
        backgroundColor: colors.defaultBlack,
      },
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: colors.primaryYellowSecond,
      },
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: colors.primaryYellowSecond,
      },
      thumb: {
        backgroundColor: colors.primaryYellowSecond,
        border: `14px solid ${colors.primaryYellowSecond} !important`,
      },
      noPoint: {
        backgroundColor: colors.primaryYellowSecond,
      },
    },
    MuiTypography: {
      subtitle1: {
        color: `${colors.defaultBlack} !important`,
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '10px',
      },
      input: {
        padding: '8px 12px',
        color: '#fff',
        fontSize: 24,
        lineHeight: '28px',
      },
      notchedOutline: {
        border: 'none',
      },
      adornedEnd: {
        textAlign: 'center',
      },
      inputAdornedEnd: {
        color: colors.defaultBlack,
      },
    },
    MuiInput: {
      input: {
        border: 'none',
        fontSize: '1.125rem',
      },
      underline: {
        '&:before, &:after': {
          border: 'none',
          transform: 'none',
          outline: 'none',
          display: 'none',
        },
        '&:focus': {
          outline: 'none',
        },
      },
    },
    MuiTableRow: {
      root: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '0 0 10px 0',
      },
    },
    MuiToolbar: {
      root: {
        background: colors.yellowLinearGradient,
      },
    },
    MuiButton: {
      textPrimary: {
        color: colors.defaultBlack,
        background: colors.textYellow,
        '&:hover': {
          backgroundColor: colors.textYellow,
          opacity: '0.6',
        },
      },
    },
  },
}
