import { colors } from 'utils/mui'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  rootContainer: {
    display: 'flex',
    padding: '10px 50px',
    flexDirection: 'column',
  },
  contentContainer: {
    padding: '0 12%',
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    width: '-webkit-fill-available',
    paddingTop: '90px',
  },
  blackInput: {
    backgroundColor: 'transparent',
    color: colors.defaultBlack,
    outline: 'none',
    margin: '2px',
    height: '90px',
    border: `2px solid ${colors.defaultBlack}`,
    borderRadius: '15px',
    fontSize: '1.5rem',
    lineHeight: '29px',
    fontWeight: 700,
    textAlign: 'center',
    width: '-webkit-fill-available',
    '&::placeholder': {
      color: colors.defaultBlack,
      textAlign: 'center',
      fontSize: '2rem',
    },
  },
  defaultButton: {
    width: '306px',
    height: '100px',
    marginTop: '20px',
    background: colors.yellowLinearGradient,
    borderRadius: '10px',
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '1.5rem',
    cursor: 'pointer',
    color: colors.defaultBlack,
    boxShadow: `0px 4px 4px ${colors.blackShadow_25}`,
    '&:hover': {
      background: colors.yellowLinearGradient,
      opacity: 0.9,
    },
    '&:active': {
      background: colors.yellowLinearGradient,
      opacity: 0.8,
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'baseline',
    marginBottom: '20px',
  },
  infoText: {
    color: colors.defaultBlack,
    fontSize: '1.13rem',
    lineHeight: '22px',
    fontWeight: 700,
  },
})
