import { InjectedConnector } from '@web3-react/injected-connector'
import { SetInterface, web3Sets } from 'contracts/sets/sets'
import Web3 from 'web3'
import { provider } from 'web3-core'

export enum NETWORK_TYPE {
  Mainnet = 'Mainnet',
  Rinkeby = 'Rinkeby',
  AuroraTestnet = 'AuroraTestnet',
  Aurora = 'Aurora',
  PolygonTestnet = 'PolygonTestnet',
  Polygon = 'Polygon',
}

export const chainIdMap = {
  [NETWORK_TYPE.Mainnet]: 1,
  [NETWORK_TYPE.Rinkeby]: 4,
  [NETWORK_TYPE.Aurora]: 1313161554,
  [NETWORK_TYPE.AuroraTestnet]: 1313161555,
  [NETWORK_TYPE.PolygonTestnet]: 80001,
  [NETWORK_TYPE.Polygon]: 137,
}

export const subgraphLink = {
  [NETWORK_TYPE.Mainnet]: web3Sets[NETWORK_TYPE.Mainnet].subgraph,
  [NETWORK_TYPE.Rinkeby]: web3Sets[NETWORK_TYPE.Rinkeby].subgraph,
  [NETWORK_TYPE.Aurora]: web3Sets[NETWORK_TYPE.Aurora].subgraph,
  [NETWORK_TYPE.AuroraTestnet]: web3Sets[NETWORK_TYPE.AuroraTestnet].subgraph,
  [NETWORK_TYPE.PolygonTestnet]: web3Sets[NETWORK_TYPE.PolygonTestnet].subgraph,
  [NETWORK_TYPE.Polygon]: web3Sets[NETWORK_TYPE.Polygon].subgraph,
}

export const sellTokens = {
  [NETWORK_TYPE.Mainnet]: [
    { name: 'WETH', address: web3Sets[NETWORK_TYPE.Mainnet].WETH },
    { name: 'USDC', address: web3Sets[NETWORK_TYPE.Mainnet].USDC },
    { name: 'USDT', address: web3Sets[NETWORK_TYPE.Mainnet].USDT },
  ],
  [NETWORK_TYPE.Rinkeby]: [
    { name: 'WETH', address: web3Sets[NETWORK_TYPE.Rinkeby].WETH },
    { name: 'USDC', address: web3Sets[NETWORK_TYPE.Rinkeby].USDC },
    { name: 'USDT', address: web3Sets[NETWORK_TYPE.Rinkeby].USDT },
  ],
  [NETWORK_TYPE.Aurora]: [
    { name: 'WETH', address: web3Sets[NETWORK_TYPE.Aurora].WETH },
    { name: 'USDC', address: web3Sets[NETWORK_TYPE.Aurora].USDC },
    { name: 'USDT', address: web3Sets[NETWORK_TYPE.Aurora].USDT },
  ],
  [NETWORK_TYPE.AuroraTestnet]: [
    { name: 'WETH', address: web3Sets[NETWORK_TYPE.AuroraTestnet].WETH },
    { name: 'USDC', address: web3Sets[NETWORK_TYPE.AuroraTestnet].USDC },
    { name: 'USDT', address: web3Sets[NETWORK_TYPE.AuroraTestnet].USDT },
  ],
  [NETWORK_TYPE.PolygonTestnet]: [
    { name: 'WETH', address: web3Sets[NETWORK_TYPE.PolygonTestnet].WETH },
    { name: 'USDC', address: web3Sets[NETWORK_TYPE.PolygonTestnet].USDC },
    { name: 'USDT', address: web3Sets[NETWORK_TYPE.PolygonTestnet].USDT },
  ],
  [NETWORK_TYPE.Polygon]: [
    { name: 'WETH', address: web3Sets[NETWORK_TYPE.Polygon].WETH },
    { name: 'USDC', address: web3Sets[NETWORK_TYPE.Polygon].USDC },
    { name: 'USDT', address: web3Sets[NETWORK_TYPE.Polygon].USDT },
  ],
}

export const valueMap = {
  [NETWORK_TYPE.Mainnet]: 'ETH',
  [NETWORK_TYPE.Rinkeby]: 'ETH',
  [NETWORK_TYPE.Aurora]: 'ETH',
  [NETWORK_TYPE.AuroraTestnet]: 'ETH',
  [NETWORK_TYPE.PolygonTestnet]: 'ETH',
  [NETWORK_TYPE.Polygon]: 'ETH',
}

export const networks = [
  chainIdMap[NETWORK_TYPE.Mainnet],
  chainIdMap[NETWORK_TYPE.Rinkeby],
  chainIdMap[NETWORK_TYPE.Aurora],
  chainIdMap[NETWORK_TYPE.AuroraTestnet],
  chainIdMap[NETWORK_TYPE.PolygonTestnet],
  chainIdMap[NETWORK_TYPE.Polygon]
]

export const injectedConnector = new InjectedConnector({
  supportedChainIds: networks,
})

export function networkType(chainId: number | undefined): NETWORK_TYPE | null {
  if (chainId === chainIdMap[NETWORK_TYPE.Mainnet]) {
    return NETWORK_TYPE.Mainnet
  } else if (chainId === chainIdMap[NETWORK_TYPE.Rinkeby]) {
    return NETWORK_TYPE.Rinkeby
  } else if (chainId === chainIdMap[NETWORK_TYPE.Aurora]) {
    return NETWORK_TYPE.Aurora
  } else if (chainId === chainIdMap[NETWORK_TYPE.AuroraTestnet]) {
    return NETWORK_TYPE.AuroraTestnet
  } else if (chainId === chainIdMap[NETWORK_TYPE.PolygonTestnet]) {
    return NETWORK_TYPE.PolygonTestnet
  } else if (chainId === chainIdMap[NETWORK_TYPE.Polygon]) {
    return NETWORK_TYPE.Polygon
  } else {
    return null
  }
}

export class Provider extends Web3 {
  constructor(connector: provider, chainId: number | undefined) {
    super(connector)
    this.currentNetwork = networkType(chainId) ?? NETWORK_TYPE.Mainnet
  }

  currentNetwork: NETWORK_TYPE

  set setCurrentNetworkType(networkType: NETWORK_TYPE) {
    this.currentNetwork = networkType
  }

  get contractSet(): SetInterface {
    return web3Sets[this.currentNetwork]
  }
}
