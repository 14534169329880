import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  root: {
    padding: 0,
  },
  paperWidthSm: {
    background: `linear-gradient(180deg, ${colors.primaryYellowFirst} 22.4%, ${colors.primaryYellowSecond} 66.15%)`,
    boxShadow: `0px 0px 25px ${colors.blackShadow_50}`,
    borderRadius: '10px',
    padding: '58px 35px',
    minWidth: '425px',
  },
  title: {
    fontSize: '3rem',
    lineHeight: '59px',
    fontWeight: 600,
    color: colors.defaultWhite,
    textAlign: 'center',
  },
  subtitle: {
    fontSize: '1.5rem',
    lineHeight: '30px',
    color: colors.defaultWhite,
    margin: '20px 80px 0',
    textAlign: 'center',
  },
  dialogContent: {
    padding: 0,
    overflowY: 'initial',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    alignItems: 'center',
  },
  redButton: {
    width: '280px',
    height: '76px',
    background: colors.redButton,
    borderRadius: '15px',
    textTransform: 'none',
    cursor: 'pointer',
    color: colors.white,
    fontSize: '1.5rem',
    lineHeight: '30px',
    boxShadow: `0px 4px 4px ${colors.blackShadow_25}`,
    '&:hover': {
      background: colors.redButton,
      opacity: 0.9,
    },
    '&:active': {
      background: colors.redButton,
      opacity: 0.8,
    },
  },
})
