import Big from 'big.js'
import React from 'react'
import { useStyles } from './style'

interface ProgressProps {
  currentValue: string
  maxValue: string
}

const ProgressBar = ({ currentValue, maxValue }: ProgressProps): JSX.Element => {
  const { progress, progressDone, progressValue } = useStyles()
  const percent = maxValue !== '0' ? new Big(currentValue).mul(100).div(new Big(maxValue)) : new Big('0')
  const currentPercent: number = percent.gt(100) ? 100 : percent.toNumber()
  const formattedPercent = currentPercent.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 0 });
  return (
    <div>
      <div className={progress}>
        <div
          //INFO: it is default width for progress-bar
          style={{ width: `${formattedPercent}%` }}
          className={progressDone}
          data-done="100"
        >
          <span
            className={progressValue}
            style={{
              display: `${currentPercent < 44 ? 'none' : 'show'}`,
            }}
          >
            {formattedPercent} %
          </span>
        </div>

        <span
          className={progressValue}
          style={{
            display: `${currentPercent >= 44 ? 'none' : 'show'}`,
          }}
        >
          {formattedPercent} %
        </span>
      </div>
    </div>
  )
}

export default ProgressBar
