import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  root: {
    padding: 0,
  },
  paperWidthSm: {
    background: `linear-gradient(180deg, ${colors.primaryYellowFirst} 22.4%, ${colors.primaryYellowSecond} 66.15%)`,
    boxShadow: `0px 0px 25px ${colors.blackShadow_50}`,
    borderRadius: '20px',
    padding: '2px',
    minWidth: '460px',
  },
  blackBg: {
    background: colors.defaultBlack,
    borderRadius: '20px',
    padding: '50px 35px',
  },
  title: {
    fontSize: '1.125rem',
    lineHeight: '22px',
    fontWeight: 600,
    color: colors.primaryYellowSecond,
    textAlign: 'center',
  },
  subTitle: {
    fontSize: '2.25rem',
    lineHeight: '44px',
    color: colors.primaryYellowSecond,
    margin: '20px 0',
    textAlign: 'center',
  },
  buttonContainer: {
    minWidth: '198px',
    height: '80px',
    padding: '2px',
    borderRadius: '10px',
    background: `linear-gradient(180deg, ${colors.primaryYellowFirst} 22.4%, ${colors.primaryYellowSecond} 66.15%)`,
  },
  defaultButton: {
    width: '100%',
    height: '100%',
    background: `linear-gradient(180deg, ${colors.primaryYellowFirst} 22.4%, ${colors.primaryYellowSecond} 66.15%)`,
    borderRadius: '10px',
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '1.5rem',
    cursor: 'pointer',
    color: colors.defaultWhite,
    '&:hover': {
      background: colors.primaryYellowSecond,
      opacity: 0.9,
    },
    '&:active': {
      background: colors.primaryYellowSecond,
      opacity: 0.8,
    },
    '&:disabled': {
      background: colors.defaultBlack,
      color: colors.primaryYellowSecond,
    },
  },
  dialogContent: {
    padding: 0,
    overflowY: 'initial',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    alignItems: 'center',
  },
  amountContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
  },
})
