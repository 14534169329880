export const HOME = '/'
export const PRESALE = '/presale'
export const PRESALE_WITH_PROMO = '/presale/'
export const LANDING = '/landing'
export const ACCOUNT = '/account'
export const POOLS = '/pools'
export const CLAIM = '/claim'
export const STAKE = '/stake'
export const STATISTICS = '/statistics'
export const POOL_TYPES = '/pools/create/type'
export const WHITELISTING_OPTIONS = '/pools/create/whitelisting-options'
export const PROVIDE_POOLS_INFO = '/pools/create/provide-info'
export const SUMMARY = '/pools/create/summary'
export const WHITELIST = '/pools/create/whitelist'
export const TOKEN_INFO = '/pools/create/token-info'
export const POOL_EDIT_TEMPLATE = (address: string) => `/poolEdit/${address}`
export const POOL_EDIT = POOL_EDIT_TEMPLATE(':address')
export const POOL_DESCRIPTION_TEMPLATE = (address: string) => `/pools/${address}`
export const POOL_DESCRIPTION = POOL_DESCRIPTION_TEMPLATE(':address')
