import { Button } from '@material-ui/core'
import { useWeb3React } from '@web3-react/core'
import { urlInputs } from 'assets/constants'
import EthIcon from 'assets/icons/EthIcon.svg'
import FixedPrice from 'assets/icons/FixedPrice.svg'
import LinearPrice from 'assets/icons/LinearPrice.svg'
import Lottery from 'assets/icons/Lottery.svg'
import UsdcIcon from 'assets/icons/UsdcIcon.svg'
import UsdtIcon from 'assets/icons/UsdtIcon.svg'
import WhiteList from 'assets/icons/WhiteList.svg'
import Big from 'big.js'
import { WarningModal } from 'components/modals/modal_warning'
import TitleHeader from 'components/title_header'
import { approveToken, getAllowance, getFee, MAX_INT_VALUE, useCreatePool } from 'hooks'
import { useERC20 } from 'hooks/useERC20'
import { setTransaction } from 'hooks/useTransactions'
import { Card } from 'pages/pools/card'
import { PoolTypeState } from 'pages/pool_types'
import CSVTable from 'pages/whitelist/CSVTable'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { POOLS, POOL_TYPES, TOKEN_INFO } from 'routes'
import { Provider } from 'services/Provider'
import { millisecondsToSeconds } from 'utils/dateFormatter'
import {
  calculateCliffEnd,
  calculateVestDuration,
  convertDescriptionData,
  convertFixedPoolDetails,
  convertLinearPoolDetails,
  convertVestingData,
  convertWhiteListArgs,
  formatPercentage,
  toUQ112,
} from 'utils/NumberFormatter'
import { StoreContext, Transaction } from 'utils/store'
import {
  CREATOR_CLIFF_PERCENTAGE,
  CREATOR_VESTING_DURATION,
  CREATOR_VESTING_INTERVAL,
  whitelistCheckError,
} from 'utils/treeUtils'
import { convertSellTokenToBuyToken, formattedDecimals, fromDecimals, Nullable } from 'utils/tsUtils'
import { useStyles } from './styles'

export interface PriceTokenInfo {
  title: string
  amountBuyToken: string
  amountSellToken: string
}

const Summary = (): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const store = useContext(StoreContext)
  const { account, library, chainId } = useWeb3React()
  const provider: Provider = new Provider(library.provider, chainId)

  const [padAllowance, setPadAllowance] = useState<string>('')
  const [tokenAllowance, setTokenAllowance] = useState<string>('')
  const [creationFee, setCreationFee] = useState<null | string>(null)
  const [sellTokenImage, setSellTokenImage] = useState<string>('')
  const [errorText, setErrorText] = useState<string>('')
  const [root, setRoot] = useState<string>('')

  const [txResult, setTxResult] = useState<Nullable<Transaction>>(null)
  const [txModalOpen, setTxModalOpen] = useState<boolean>(false)
  const createPool = useCreatePool(provider)
  const handleCloseSuccess = (tx: Nullable<Transaction>) => {
    if (tx) {
      setTxResult(tx)
      setTxModalOpen(true)
    }
  }
  const onTransactionAlertClosed = () => {
    history.replace(POOLS)
    setTxModalOpen(false)
  }

  const fixedRedirect = !store.fixedBuyPrice || !store.fixedSellPrice
  const linearRedirect = !store.sellStartPrice || !store.buyStartPrice || !store.sellEndPrice || !store.buyEndPrice

  const redirect =
    !store.poolType ||
    !store.listOption ||
    !store.poolTitle ||
    !store.urls[0] ||
    !store.urls[1] ||
    !store.urls[2] ||
    !store.urls[3] ||
    !store.startDate ||
    !store.endDate ||
    !store.whiteListUrl ||
    !store.cliffWeekDuration ||
    !store.cliffDayDuration ||
    !store.vestWeekInterval ||
    !store.vestPercent ||
    !store.cliffPercent ||
    !store.raiseAmount ||
    !store.sellTokenSymbol ||
    !store.buyTokenSymbol ||
    !store.buyTokenAddress ||
    !store.sellAcceptedToken ||
    !store.vestDayInterval

  const isApproved =
    store.raiseAmount &&
    creationFee &&
    new Big(tokenAllowance !== '' ? tokenAllowance : '0').gte(new Big(store.raiseAmount)) &&
    new Big(padAllowance !== '' ? padAllowance : '0').gte(new Big(creationFee))
  const cardImage = store.getCardImageUrl()

  const sellTokenIcon = () => {
    switch (store.sellAcceptedToken) {
      case provider.contractSet.USDT: {
        setSellTokenImage(UsdtIcon)
        break
      }
      case provider.contractSet.USDC: {
        setSellTokenImage(UsdcIcon)
        break
      }
      default:
        setSellTokenImage(EthIcon)
    }
  }

  const priceBuyInSellUQ = (buyPrice: string, sellPrice: string) => {
    if (buyPrice == '' || sellPrice == '' || buyToken == null || sellToken == null) return '0'

    return toUQ112(fromDecimals(buyPrice, buyToken.decimals).toString())
      .div(fromDecimals(sellPrice, sellToken.decimals))
      .toString()
  }

  useEffect(() => {
    sellTokenIcon()
  })

  const urlInfo = urlInputs(t)
    .filter((el) => store.getUrl(el.id).link !== '')
    .map((el) => {
      return {
        titleName: el.name,
        pathValue: store.getUrl(el.id).link,
      }
    })

  const LinearPriceInfo = {
    img: LinearPrice,
    title: t('pool_types_linear_price'),
    info: t('pool_types_linear_price_info'),
  }
  const FixedPriceInfo = { img: FixedPrice, title: t('pool_types_fixed_price'), info: t('pool_types_fix_price_info') }
  const WhitelistInfo = {
    img: WhiteList,
    title: t('pool_whitelist_whitelist'),
    info: t('pool_whitelist_whitelist_info'),
  }
  const LotteryInfo = { img: Lottery, title: t('pool_whitelist_lottery'), info: t('pool_whitelist_lottery_info') }
  const priceTokenInfoLinear = [
    {
      title: t('token_info_start_price'),
      amountBuyToken: store.buyStartPrice,
      amountSellToken: store.sellStartPrice,
    },
    {
      title: t('token_info_end_prise'),
      amountBuyToken: store.buyEndPrice,
      amountSellToken: store.sellEndPrice,
    },
  ]

  const priceTokenInfoFixed = [
    {
      title: t('token_info_fixed_prise'),
      amountBuyToken: store.fixedBuyPrice,
      amountSellToken: store.fixedSellPrice,
    },
  ]

  const cliffDurationInfo = [
    { title: t('common_days'), value: store.cliffDayDuration },
    { title: t('common_weeks'), value: store.cliffWeekDuration },
  ]
  const vestIntervalInfo = [
    { title: t('common_days'), value: store.vestDayInterval },
    { title: t('common_weeks'), value: store.vestWeekInterval },
  ]

  const padToken = useERC20(provider, provider.contractSet.pad)
  const buyToken = useERC20(provider, store.buyTokenAddress)
  const sellToken = useERC20(provider, store.sellAcceptedToken)

  useEffect(() => {
    const allowance = async () => {
      if (!account || !chainId || !provider || !provider.contractSet || buyToken == null || padToken == null) return
      const padAllowance = await getAllowance(
        provider,
        account,
        provider.contractSet.poolFactory,
        provider.contractSet.pad,
      )
      const tokenAllowance = await getAllowance(provider, account, provider.contractSet.poolFactory, buyToken.address)
      setPadAllowance(padAllowance ? fromDecimals(padAllowance, padToken.decimals).toString() : '0')
      setTokenAllowance(tokenAllowance ? fromDecimals(tokenAllowance, buyToken.decimals).toString() : '0')
    }
    const getCreationFee = async () => {
      if (!account || !chainId || !provider || !provider.contractSet || buyToken == null || padToken == null) return
      const createFee = async (buyPrice: string, sellPrice: string) => {
        const buyInSellUQ = priceBuyInSellUQ(buyPrice, sellPrice)
        const creationFee = await getFee(
          provider,
          fromDecimals(store.raiseAmount, buyToken.decimals).toString(),
          buyInSellUQ,
          store.sellAcceptedToken,
          buyToken.address,
        )
        if (creationFee) {
          setCreationFee(formattedDecimals(creationFee, padToken.decimals).toString())
        }
      }

      if (store.poolType == PoolTypeState.Fixed) {
        createFee(store.fixedBuyPrice, store.fixedSellPrice)
      } else {
        createFee(store.buyStartPrice, store.sellStartPrice)
      }
    }
    getCreationFee()
    allowance()
  }, [buyToken, padToken])

  const approve = async () => {
    if (
      !account ||
      !chainId ||
      !provider ||
      !provider.contractSet ||
      !creationFee ||
      buyToken == null ||
      padToken == null
    )
      return

    await approveToken(
      provider.contractSet.pad,
      provider,
      fromDecimals(MAX_INT_VALUE, padToken.decimals).toString(),
      provider.contractSet.poolFactory,
      account,
      chainId,
      setTransaction,
    )

    await approveToken(
      store.buyTokenAddress,
      provider,
      fromDecimals(MAX_INT_VALUE, buyToken.decimals).toString(),
      provider.contractSet.poolFactory,
      account,
      chainId,
      setTransaction,
    )

    const padAllowance = await getAllowance(
      provider,
      account,
      provider.contractSet.poolFactory,
      provider.contractSet.pad,
    )
    const tokenAllowance = await getAllowance(
      provider,
      account,
      provider.contractSet.poolFactory,
      store.buyTokenAddress,
    )
    setPadAllowance(padAllowance ? fromDecimals(padAllowance, padToken.decimals).toString() : '0')
    setTokenAllowance(tokenAllowance ? fromDecimals(tokenAllowance, buyToken.decimals).toString() : '0')
  }

  const handleClose = () => {
    setErrorText('')
  }

  const checkWhiteList = async () => {
    buyToken && (await whitelistCheckError(store.whiteListUrl, buyToken.decimals, setErrorText, setRoot, t))
  }

  const createNewPool = async () => {
    const {
      urls,
      endDate,
      startDate,
      cliffPercent,
      vestPercent,
      poolTitle,
      cliffDayDuration,
      cliffWeekDuration,
      vestWeekInterval,
      vestDayInterval,
      poolType,
    } = store
    if (!account || !chainId || !provider || !provider.contractSet || buyToken == null || sellToken == null) return
    const url = store.whiteListUrl
    checkWhiteList()
    if (!root) return
    const descriptionData = convertDescriptionData(account, poolTitle, urls)
    const cliffEnd = calculateCliffEnd(cliffDayDuration, cliffWeekDuration, endDate.getTime())
    const vestDuration = calculateVestDuration(vestDayInterval, vestWeekInterval)

    const vestingData = convertVestingData(
      millisecondsToSeconds(startDate.getTime()),
      millisecondsToSeconds(endDate.getTime()),
      formatPercentage(cliffPercent),
      millisecondsToSeconds(endDate.getTime()),
      formatPercentage(vestPercent),
      vestDuration,
      CREATOR_CLIFF_PERCENTAGE,
      millisecondsToSeconds(cliffEnd),
      CREATOR_VESTING_DURATION,
      CREATOR_VESTING_INTERVAL,
    )

    console.log('vest percent', formatPercentage(vestPercent))
    console.log('vestDuration', vestDuration)
    console.log('cliffPercent', formatPercentage(cliffPercent))
    const whitelistArgs = convertWhiteListArgs(account, store.sellAcceptedToken, root, url)

    console.log('root', root)
    let poolDetails

    if (store.poolType === PoolTypeState.Linear) {
      poolDetails = convertLinearPoolDetails(
        priceBuyInSellUQ(store.buyStartPrice, store.sellStartPrice),
        priceBuyInSellUQ(store.buyEndPrice, store.sellEndPrice),
      )
    } else {
      poolDetails = convertFixedPoolDetails(priceBuyInSellUQ(store.fixedBuyPrice, store.fixedSellPrice))
    }

    const distributionAmount = fromDecimals(store.raiseAmount, buyToken.decimals)
    console.log(distributionAmount.toString());
    console.log('price', priceBuyInSellUQ(store.fixedBuyPrice, store.fixedSellPrice))
    // const tx = await createPool(
    //   account,
    //   poolType === PoolTypeState.Linear,
    //   store.buyTokenAddress,
    //   store.sellAcceptedToken,
    //   distributionAmount.toString(),
    //   descriptionData,
    //   vestingData,
    //   poolDetails,
    //   whitelistArgs,
    //   chainId,
    //   setTransaction,
    // )
    // handleCloseSuccess(tx)
  }

  const priceInfoBlock = (priceType: PriceTokenInfo[]) => {
    return priceType.map((el, index) => (
      <span key={index}>
        <div className={classes.paddingBlock}>
          <div className={classes.rowContainer}>
            <span className={classes.blackTitle}>{el.title}</span>
          </div>
        </div>

        <div className={classes.rowContainer}>
          <div className={classes.blackSecondBgContainer}>
            <div className={classes.inputContainer}>
              <span className={classes.whiteSecondTitle}>{el.amountSellToken}</span>
              <span className={classes.whiteSecondTitle}> {store.sellTokenSymbol}</span>
            </div>
          </div>
          <div className={classes.rowContainer}>
            <span className={classes.blackTitle} style={{ margin: 0 }}>
              =
            </span>
          </div>
          <div className={classes.rowContainer}>
            <div className={classes.blackSecondBgContainer}>
              <div className={classes.inputContainer}>
                <span className={classes.whiteSecondTitle}>{el.amountBuyToken}</span>
                <span className={classes.whiteSecondTitle}>{store.buyTokenSymbol ?? ''}</span>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.paddingBlock}>
          <div className={classes.rowContainer}>
            <span className={classes.grayTitle}>
              {t('common_price_eth_and_tkn', {
                amountBuy: el.amountBuyToken,
                symbolBuy: store.buyTokenSymbol,
                amountSell: el.amountSellToken,
                symbolSell: store.sellTokenSymbol,
              })}
            </span>
          </div>
        </div>
      </span>
    ))
  }

  if (store.poolType == PoolTypeState.Linear) {
    redirect && linearRedirect && history.replace(POOL_TYPES)
  } else {
    redirect && fixedRedirect && history.replace(POOL_TYPES)
  }

  return (
    <div className={classes.rootContainer}>
      <TitleHeader
        title={t('summary_title')}
        info={t('summary_title_info')}
        path={TOKEN_INFO}
        currentStep={'6'}
        isFee={true}
        feeValue={creationFee}
        buttonTitle={t('summary_back_button')}
      />
      <div className={classes.contentContainer}>
        {/* INFO: pool type block */}
        <div className={classes.goldBgContainer}>
          <span className={classes.goldLabelContainer}>{t('summary_pool_type')}</span>
        </div>

        <div className={classes.segmentContentContainer}>
          <div className={classes.rowContainer}>
            <img
              src={store.poolType === 'Linear' ? LinearPriceInfo.img : FixedPriceInfo.img}
              alt={store.poolType === 'Linear' ? LinearPriceInfo.title : FixedPriceInfo.title}
            />
            <span className={classes.blackPrimaryTitle}>
              {store.poolType === 'Linear' ? LinearPriceInfo.title : FixedPriceInfo.title}
            </span>
            <span className={classes.blackInfo}>
              {store.poolType === 'Linear' ? LinearPriceInfo.info : FixedPriceInfo.info}
            </span>
          </div>
        </div>

        {/* INFO: pool whitelist block */}
        <div className={classes.goldBgContainer}>
          <span className={classes.goldLabelContainer}>{t('pool_whitelist_title')}</span>
        </div>
        <div className={classes.segmentContentContainer}>
          <div className={classes.rowContainer}>
            <img
              src={store.listOption === 'Lottery' ? LotteryInfo.img : WhitelistInfo.img}
              alt={store.listOption === 'Lottery' ? LotteryInfo.title : WhitelistInfo.title}
            />
            <span className={classes.blackPrimaryTitle}>
              {store.listOption === 'Lottery' ? LotteryInfo.title : WhitelistInfo.title}
            </span>
            <span className={classes.blackInfo}>
              {store.listOption === 'Lottery' ? LotteryInfo.info : WhitelistInfo.info}
            </span>
          </div>
        </div>
        <div style={{ marginTop: '50px' }} />
        <div className={classes.segmentContentContainer}>
          <span className={classes.blackTitle}>{t('whitelist_placeholder_whitelist')} </span>
          <div className={classes.blackBgContainer} style={{ margin: '20px 0' }}>
            <span className={classes.whiteTitle} style={{ fontSize: '0.875rem' }}>
              {store.whiteListUrl}
            </span>
          </div>
          <CSVTable list={store.whiteListUrl} />
        </div>

        {/* INFO: pools info block */}
        <div className={classes.goldBgContainer}>
          <span className={classes.goldLabelContainer}>{t('summary_pool’s_info')}</span>
        </div>
        <div className={classes.segmentContentContainer}>
          <div className={classes.blackBgContainer}>
            <span className={classes.whiteTitle}>{store.poolTitle}</span>
          </div>
          <div className={classes.linksContainer}>
            {urlInfo.map((el, index) => {
              return (
                <div key={index} className={classes.linkContainer}>
                  <span className={classes.blackTitle}>{el.titleName}</span>
                  <div className={classes.urlInputContainer}>
                    <span className={classes.whiteInfo}>{el.pathValue}</span>
                  </div>
                </div>
              )
            })}
          </div>
        </div>

        {/* INFO: token info block */}
        <div className={classes.goldBgContainer}>
          <span className={classes.goldLabelContainer}>{t('summary_token_info')}</span>
        </div>
        <div className={classes.segmentContentContainer}>
          <div className={classes.rowContainer}>
            <span className={classes.blackTitle}>{t('pool_creation_accepted_token')}</span>
            <span className={classes.blackTitle}>{t('pool_creation_output_token')}</span>
            <span className={classes.blackInfo}>{t('token_info_token_info')} </span>
          </div>

          <div className={classes.rowContainer}>
            <div className={classes.blackBGSmallContainer}>
              <img src={sellTokenImage} alt="Sell token image" />
              <span className={classes.whiteSecondTitle}>{store.sellTokenSymbol}</span>
            </div>

            <div className={classes.blackBgContainer} style={{ height: '50px', margin: '18px 0 40px' }}>
              <span className={classes.whiteSecondTitle}>{store.buyTokenAddress}</span>
            </div>
          </div>

          {store.poolType == PoolTypeState.Linear
            ? priceInfoBlock(priceTokenInfoLinear)
            : priceInfoBlock(priceTokenInfoFixed)}

          <div className={classes.paddingBlock} />
          <div className={classes.paddingBlock}>
            <span className={classes.blackPrimaryTitle}>{t('token_info_raise_amount_')} </span>
          </div>
          <div className={classes.blackBgContainer} style={{ margin: '15px 0 48px' }}>
            <div className={classes.rowContainer} style={{ margin: '0 30px' }}>
              <span className={classes.whiteTitle}>{store.raiseAmount}</span>
              <span className={classes.whiteTitle}>{store.buyTokenSymbol ?? ''}</span>
            </div>
          </div>

          <div className={classes.paddingBlock}>
            <span className={classes.blackPrimaryTitle}>{t('token_info_vesting')}</span>
          </div>

          <div className={classes.rowContainer} style={{ gap: '20px' }}>
            <div className={classes.columnContainer}>
              <span className={classes.blackTitle}>{t('token_info_cliff_duration')}</span>

              <div className={classes.rowContainer}>
                {cliffDurationInfo.map((el, index) => (
                  <div className={classes.columnContainer} style={{ gap: '6px' }} key={index}>
                    <span className={classes.grayInfo}>{el.title}</span>
                    <div className={classes.blackBg}>
                      <span className={classes.whiteLabelInfo}>{el.value}</span>
                    </div>
                  </div>
                ))}
              </div>

              <span className={classes.blackTitle}>{t('token_info_cliff_unlock')}</span>
              <div className={classes.blackBg}>
                <span className={classes.whiteLabelInfo}>{store.cliffPercent}</span>
              </div>
            </div>

            <div className={classes.columnContainer}>
              <span className={classes.blackTitle}>{t('token_info_vesting_interval')}</span>
              <div className={classes.rowContainer}>
                {vestIntervalInfo.map((el, index) => (
                  <div className={classes.columnContainer} style={{ gap: '6px' }} key={index}>
                    <span className={classes.grayInfo}>{el.title}</span>
                    <div className={classes.blackBg}>
                      <span className={classes.whiteLabelInfo}>{el.value}</span>
                    </div>
                  </div>
                ))}
              </div>
              <span className={classes.blackTitle}>{t('token_info_vesting_unlock')}</span>
              <div className={classes.blackBg}>
                <span className={classes.whiteLabelInfo}>{store.vestPercent}</span>
              </div>
            </div>
          </div>
        </div>

        {/* INFO: preview block */}
        <div className={classes.goldBgContainer}>
          <span className={classes.goldLabelContainer}>{t('summary_preview')}</span>
        </div>
        <div className={classes.rowSecondContainer}>
          <Card
            title={store.poolTitle}
            image={cardImage}
            startDate={store.startDate}
            endDate={store.endDate}
            pooType={store.poolType}
            poolOption={store.listOption}
            maxValueInBuyToken={store.raiseAmount}
            firstToken={store.sellTokenSymbol}
            sellAcceptedToken={store.sellAcceptedToken}
            currentValueInSellToken={'0'}
            currentValueInBuyToken={'0'}
            secondAmount={
              sellToken != null && buyToken != null
                ? formattedDecimals(
                    convertSellTokenToBuyToken(
                      fromDecimals('1', sellToken.decimals),
                      store.poolType == PoolTypeState.Linear
                        ? priceBuyInSellUQ(store.buyStartPrice, store.sellStartPrice)
                        : priceBuyInSellUQ(store.fixedBuyPrice, store.fixedSellPrice),
                    ).toString(),
                    buyToken.decimals,
                  )
                : '0'
            }
            secondToken={store.buyTokenSymbol}
            totalRaise={store.raiseAmount}
            isDeleted={false}
            address={''}
          />
          <div className={classes.columnContainer}>
            <Button className={classes.defaultButton} onClick={approve} disabled={isApproved}>
              {t('stake_approve')}
            </Button>
            <Button className={classes.defaultButton} onClick={createNewPool}>
              {t('pool_create_pool_button')}
            </Button>
          </div>
        </div>
      </div>
      <WarningModal
        handleClose={handleClose}
        onClick={handleClose}
        open={errorText !== ''}
        titleText={t('summary_error_title')}
        infoText={errorText}
        buttonStyle={classes.errorButton}
        buttonText={t('summary_ok')}
      />
      <WarningModal
        handleClose={onTransactionAlertClosed}
        onClick={onTransactionAlertClosed}
        open={txModalOpen}
        transaction={txResult?.hash}
        titleText={t('common_pool_created')}
        infoText={t('pool_card_success_dialog_description')}
        buttonStyle={classes.defaultButton}
        buttonText={t('summary_ok')}
      />
    </div>
  )
}

export default Summary
