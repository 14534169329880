import { notifyInvalidPromo } from 'App'
import Big from 'big.js'
import {
  abiERC20,
  abiFixedPool,
  abiLinearPool,
  abiPoolFactory,
  abiPresale,
  abiTreeWhitelist,
  abiVesting,
  aPAD,
} from 'contracts/web3'
import { AbiErc20 } from 'contracts/web3/typings/abi_erc20'
import { AbiFixedPool } from 'contracts/web3/typings/abi_fixed_pool'
import { AbiLinearPool } from 'contracts/web3/typings/abi_linear_pool'
import { AbiPoolFactory } from 'contracts/web3/typings/abi_pool_factory'
import { AbiPresale } from 'contracts/web3/typings/abi_presale'
import { AbiTreeWhitelist } from 'contracts/web3/typings/abi_tree_whitelist'
import { AbiVesting } from 'contracts/web3/typings/abi_vesting'
import { APAD } from 'contracts/web3/typings/aPAD'
import { BytesLike } from 'ethers/lib/utils'
import { PoolInterface } from 'pages/pools'
import { PoolTypeState } from 'pages/pool_types'
import { WhitelistState } from 'pages/whitelistingOptions'
import { Dispatch, SetStateAction, useCallback } from 'react'
import { NETWORK_TYPE, Provider } from 'services/Provider'
import { toMilliseconds } from 'utils/NumberFormatter'
import { createTransaction, Transaction, UrlObject } from 'utils/store'
import Web3 from 'web3'

export const EMPTY_ADDRESS = '0x0000000000000000000000000000000000000000'
export const Q112 = new Big(2).pow(112)
export const MAX_INT_VALUE = Number.MAX_SAFE_INTEGER.toFixed()

const usePresaleContract = (provider: Provider) => {
  if (provider.contractSet) {
    return new provider.eth.Contract(abiPresale, provider.contractSet.presale) as any as AbiPresale
  }
  return
}

const useVestingContract = (provider: Provider) => {
  if (provider.contractSet) {
    return new provider.eth.Contract(abiVesting, provider.contractSet.vesting) as any as AbiVesting
  }
  return
}

const useStakeContract = (provider: Provider) => {
  if (provider.contractSet) {
    return new provider.eth.Contract(aPAD, provider.contractSet.staking) as any as APAD
  }
  return
}

const useERC20Contract = (provider: Provider, contractAddress: string) => {
  try {
    return new provider.eth.Contract(abiERC20, contractAddress) as any as AbiErc20
  } catch(err) {
    console.log('Invalid token address!', err)
  }
}

const usePoolFactoryContract = (provider: Provider) => {
  if (provider.contractSet) {
    return new provider.eth.Contract(abiPoolFactory, provider.contractSet.poolFactory) as any as AbiPoolFactory
  }
  return
}

// const useWhitelistTreeContract = (provider: Provider) => {
//   if (provider.contractSet) {
//     return new provider.eth.Contract(abiTreeWhitelist, provider.contractSet.whitelist) as any as AbiTreeWhitelist
//   }
//   return
// }

export const usePoolContract = (provider: Provider, addressPool: string, type: PoolTypeState) => {
  if (type === PoolTypeState.Fixed) {
    return new provider.eth.Contract(abiFixedPool, addressPool) as any as AbiFixedPool
  } else {
    return new provider.eth.Contract(abiLinearPool, addressPool) as any as AbiLinearPool
  }
}

export function convertSellTokenToBuyToken(amount: Big, currentPrice: string) {
  return amount.mul(currentPrice).div(Q112)
}

export async function currentPrice(provider: Provider, addressPool: string, type: PoolTypeState) {
  //eslint-disable-next-line
  const contract = usePoolContract(provider, addressPool, type)
  if (!contract) return
  return await contract.methods.currentPriceBuyInSellUQ().call()
}

export async function register(
  provider: Provider,
  account: string,
  value: string,
  promo: string,
  chainId: number,
  updateTransactions: (transaction: Transaction) => void,
): Promise<void> {
  //eslint-disable-next-line
  const contract = usePresaleContract(provider)
  const big = new Big(value).mul(1e18).toFixedSpecial(0)
  if (contract) {
    const newPromoCode = generatePromo()
    const refererAddress = await contract.methods.addressOfName('0x' + promo).call()

    await contract.methods
      .register(refererAddress, '0x' + newPromoCode)
      .send({ from: account, value: big })
      .on('transactionHash', function (hash) {
        const transaction: Transaction = createTransaction(hash, 'eth', 'Register function', chainId)
        updateTransactions(transaction)
      })
  }
}
function dec2hex(dec: number): string {
  return dec.toString(16).padStart(2, '0')
}

export function generatePromo(): string {
  const arr = new Uint8Array(3)
  window.crypto.getRandomValues(arr)
  return Array.from(arr, dec2hex).join('')
}

export async function buy(
  provider: Provider,
  account: string,
  value: string,
  web3Account: string,
  chainId: number,
  updateTransactions: (transaction: Transaction) => void,
): Promise<void> {
  const big = new Big(value).mul(1e18).toFixedSpecial(0)
  //eslint-disable-next-line
  const contract = usePresaleContract(provider)

  if (contract) {
    const participantResponse = await contract.methods.participant(web3Account).call()
    const promoCode: string | null = participantResponse._name
    await contract.methods
      .buy(promoCode ?? `0x${generatePromo()}`)
      .send({ from: account, value: big })
      .on('transactionHash', function (hash) {
        const transaction: Transaction = createTransaction(hash, 'eth', 'Buy function', chainId)
        updateTransactions(transaction)
      })
  }
}

export async function participant(
  provider: Provider,
  account: string,
  address: string,
  setPromo: Dispatch<SetStateAction<string>>,
  setIsExists?: Dispatch<SetStateAction<boolean>>,
): Promise<void> {
  //eslint-disable-next-line
  const contract = usePresaleContract(provider)
  if (contract) {
    const participantResponse = await contract.methods.participant(address).call()
    participantResponse._name !== null && setPromo(participantResponse._name.toString().substring(2))
    setIsExists && setIsExists(participantResponse._name !== null)
  }
}

export async function lifetime(
  provider: Provider,
  setStartDate: Dispatch<SetStateAction<number>>,
  setEndDate: Dispatch<SetStateAction<number>>,
): Promise<void> {
  //eslint-disable-next-line
  const contract = usePresaleContract(provider)
  if (contract) {
    const data = await contract.methods.lifetime().call()
    setStartDate(toMilliseconds(data._startTime))
    setEndDate(new Big(toMilliseconds(data._startTime)).plus(toMilliseconds(data._duration)).toNumber())
  }
}
export const customStringToHex = (value: string): string => Web3.utils.stringToHex(value).substring(2)

export async function getUsersCount(provider: Provider, address: string): Promise<string | undefined> {
  //eslint-disable-next-line
  const contract = usePresaleContract(provider)
  if (contract) {
    const participantResponse = await contract.methods.participant(address).call()
    return participantResponse._name.substring(2)
  }
}

export async function promoValidation(
  provider: Provider,
  promo: string,
  address: string,
  setIsExists: Dispatch<SetStateAction<boolean>>,
): Promise<void> {
  //eslint-disable-next-line
  const contract = usePresaleContract(provider)
  if (contract) {
    const promoAddress = await contract.methods.addressOfName('0x' + promo).call()
    promoAddress === EMPTY_ADDRESS && notifyInvalidPromo()
    const participantResponse = await contract.methods.participant(address).call()
    setIsExists(participantResponse._name !== null)
  }
}

export async function isValidProof(
  provider: Provider,
  proof: BytesLike[],
  account: string,
  amount: string,
  cliffAmount: string,
  interval: string,
): Promise<boolean> {
  //eslint-disable-next-line
  const contract = useVestingContract(provider)
  if (contract) {
    return await contract.methods
      .isMerkleProofValid(
        proof.map((el) => el.toString()),
        account,
        amount,
        cliffAmount,
        interval,
      )
      .call()
  }
  return false
}

export async function withdrawWithProof(
  provider: Provider,
  proof: BytesLike[],
  account: string,
  amount: string,
  cliffAmount: string,
  interval: string,
  chainId: number,
  updateTransactions: (transaction: Transaction) => void,
): Promise<void> {
  //eslint-disable-next-line
  const contract = useVestingContract(provider)
  if (contract) {
    await contract.methods
      .withdrawWithProof(
        proof.map((el) => el.toString()),
        amount,
        cliffAmount,
        interval,
      )
      .send({ from: account })
      .on('transactionHash', function (hash: string) {
        const transaction: Transaction = createTransaction(hash, 'eth', 'Withdraw', chainId)
        updateTransactions(transaction)
      })
  }
}

export async function getUnlockedAmount(
  provider: Provider,
  account: string,
  amount: string,
  cliffAmount: string,
  interval: string,
): Promise<string | undefined> {
  //eslint-disable-next-line
  const contract = useVestingContract(provider)
  if (contract) {
    return await contract.methods.unlockedAmountOf(account, amount, cliffAmount, interval).call()
  }
  return
}

export async function getDecimals(tokenAddress: string, provider: Provider) {
  //eslint-disable-next-line
  const contract = useERC20Contract(provider, tokenAddress)
  if (!contract) return
  return contract.methods.decimals().call()
}

export async function approveToken(
  tokenAddress: string,
  provider: Provider,
  amount: string,
  spender: string,
  userAccount: string,
  chainId: number,
  updateTransactions: (transaction: Transaction) => void,
): Promise<void> {
  //eslint-disable-next-line
  const contract = useERC20Contract(provider, tokenAddress)
  if (!contract) return

  await contract.methods
    .approve(spender, amount)
    .send({ from: userAccount })
    .on('transactionHash', function (hash: string) {
      const transaction: Transaction = createTransaction(hash, 'eth', 'Approve token', chainId)
      updateTransactions(transaction)
    })
}

export async function getBalance(
  provider: Provider,
  account: string,
  tokenAddress: string,
): Promise<string | undefined> {
  //eslint-disable-next-line
  const contract = useERC20Contract(provider, tokenAddress)
  if (!contract) return
  return await contract.methods.balanceOf(account).call()
}

export async function getAssetBalanceOf(provider: Provider, account: string): Promise<string | undefined> {
  //eslint-disable-next-line
  const contract = useStakeContract(provider)
  if (!contract) return
  return await contract.methods.assetBalanceOf(account).call()
}

export async function stakePAD(
  provider: Provider,
  amount: string,
  web3Account: string,
  chainId: number,
  updateTransactions: (transaction: Transaction) => void,
): Promise<void> {
  //eslint-disable-next-line
  const contract = useStakeContract(provider)
  if (!contract) return

  await contract.methods
    .stake(web3Account, amount)
    .send({ from: web3Account })
    .on('transactionHash', function (hash: string) {
      const transaction: Transaction = createTransaction(hash, 'eth', 'stake function', chainId)
      updateTransactions(transaction)
    })
}

export async function getAllowance(
  provider: Provider,
  owner: string,
  spender: string,
  tokenAddress: string,
): Promise<string | undefined> {
  //eslint-disable-next-line
  const contract = useERC20Contract(provider, tokenAddress)
  if (!contract) return
  return await contract.methods.allowance(owner, spender).call()
}

export async function getPoolsInfo(
  provider: Provider,
): Promise<PoolInterface[] | undefined> {
  //eslint-disable-next-line
  const contract = usePoolFactoryContract(provider)
  if (!contract) return

  let pools: Array<PoolInterface> = [];

  if (provider.currentNetwork === NETWORK_TYPE.Aurora) {
    let poolObject: PoolInterface = {
      pool: '0xE4044A41C46d61A1C9400a2db0556Ad25B5b8872',
      poolPrototype: '0xeCdf3767C24ce7165E0321c27Be4f2840C78f017'
    };

    pools.push(poolObject)

    poolObject = {
      pool: '0xe4993471074671507fFb8D5C825e985a11d50e98',
      poolPrototype: '0xeCdf3767C24ce7165E0321c27Be4f2840C78f017'
    };

    pools.push(poolObject)

    poolObject = {
      pool: '0xf5548dfed147E0A7F0476547e4FE8892407f6E06',
      poolPrototype: '0xeCdf3767C24ce7165E0321c27Be4f2840C78f017'
    };

    pools.push(poolObject)

    poolObject = {
      pool: '0x75eB7c42CB3159Be6c48941412211E954Bd28350',
      poolPrototype: '0xeCdf3767C24ce7165E0321c27Be4f2840C78f017'
    };

    pools.push(poolObject)

    poolObject = {
      pool: '0x2007298370B5e6284463333843db9B5895A9Be36',
      poolPrototype: '0xeCdf3767C24ce7165E0321c27Be4f2840C78f017'
    };

    pools.push(poolObject)

    poolObject = {
      pool: '0xe2eE9c4D4604465bEce33eAc4e2f3e9767D84435',
      poolPrototype: '0xeCdf3767C24ce7165E0321c27Be4f2840C78f017'
    };

    pools.push(poolObject)

    poolObject = {
      pool: '0xf605CdAf60D4a252f4056421EEde9489DEA830c0',
      poolPrototype: '0xeCdf3767C24ce7165E0321c27Be4f2840C78f017'
    };

    pools.push(poolObject)

    poolObject = {
      pool: '0xa4F9F697894b1690566B5564a73dc872644b139B',
      poolPrototype: '0x1595aBaa52F87Fe0C50bceC0b5B0355D8A51055f'
    };

    pools.push(poolObject)

    poolObject = {
      pool: '0xB16E3F899aaFe2B54625307C01Dc1E2F5a4a6Eac',
      poolPrototype: '0x1595aBaa52F87Fe0C50bceC0b5B0355D8A51055f'
    };

    pools.push(poolObject)

    poolObject = {
      pool: '0x8aBd6F5fFe3Dcc3552C1b25da232Be525AF75BaF',
      poolPrototype: '0x1595aBaa52F87Fe0C50bceC0b5B0355D8A51055f'
    };

    pools.push(poolObject)

  } else if (provider.currentNetwork === NETWORK_TYPE.Rinkeby) {
    let poolObject: PoolInterface = {
      pool: '0x5E09eDa85175E69B24a10F28fFd4E2Fbe7A4Ae61',
      poolPrototype: '0xe770A9098bC4C07DB02f0c09957652a2AdC47183'
    };

    pools.push(poolObject)

  }
  // else {
  //   const poolsLength: string = await contract.methods.poolCount().call();

  //   for (var _i = 0; _i < Number(poolsLength); _i++) {
  //     const poolAddress: string = await contract.methods.poolAtIndex(_i).call();

  //     const poolInfo = await contract.methods.poolInfoByPoolAddress(poolAddress).call();

  //     let poolObject: PoolInterface = {
  //       pool: poolAddress,
  //       poolPrototype: poolInfo.poolPrototypeAddress
  //     };

  //     pools.push(poolObject);
  //   }
  // }

  return pools;
}

export async function getFee(
  provider: Provider,
  raiseInBuyToken: string,
  priceBuyInSellUQ: string,
  sellToken: string,
  buyToken: string,
): Promise<string | undefined> {
  //eslint-disable-next-line
  const contract = usePoolFactoryContract(provider)
  if (!contract) return
  return await contract.methods.feeForDistribution(raiseInBuyToken, priceBuyInSellUQ, sellToken, buyToken).call()
}

export async function unstakePAD(
  provider: Provider,
  amount: string,
  account: string,
  chainId: number,
  updateTransactions: (transaction: Transaction) => void,
): Promise<void> {
  //eslint-disable-next-line
  const contract = useStakeContract(provider)
  if (!contract) return

  await contract.methods
    .unstake(account, amount)
    .send({ from: account })
    .on('transactionHash', function (hash: string) {
      const transaction: Transaction = createTransaction(hash, 'eth', 'unstake function', chainId)
      updateTransactions(transaction)
    })
}

type CreatePool = (
  account: string,
  isLinear: boolean,
  buyToken: string,
  sellToken: string,
  distribution: string,
  descriptionData: string,
  vestingData: string,
  poolDetails: string,
  whitelistArgs: string,
  chainId: number,
  updateTransactions: (transaction: Transaction) => void,
) => Promise<Transaction>

export function useCreatePool(provider: Provider): CreatePool {
  return useCallback<CreatePool>(
    async (
      account,
      isLinear,
      buyToken,
      sellToken,
      distribution,
      descriptionData,
      vestingData,
      poolDetails,
      whitelistArgs,
      chainId,
      updateTransactions,
    ) => {
      return new Promise((resolve) => {
        //eslint-disable-next-line
        const contract = usePoolFactoryContract(provider)
        if (!contract || !provider || !provider.contractSet) return

        const poolTypeAddress = isLinear ? provider.contractSet.linearPool : provider.contractSet.fixedPool
        contract.methods
          .createPool(
            poolTypeAddress,
            provider.contractSet.whitelist,
            [buyToken, sellToken],
            distribution,
            descriptionData,
            vestingData,
            poolDetails,
            whitelistArgs,
          )
          .send({ from: account })
          .on('transactionHash', function (hash: string) {
            console.log('transactionHash', hash)
            const transaction: Transaction = createTransaction(hash, 'eth', 'Buy', chainId)
            updateTransactions(transaction)
            resolve(transaction)
          })
      })
    },
    [],
  )
}

export interface PoolInfoInterface {
  _creator: string
  _sellToken: string
  _buyToken: string
  _distributedBuyAmount: string
  _startTimestamp: string
  _endTimestamp: string
  _totalBuyAmount: string
  _totalSellAmount: string
  _types: string[]
  _values: string[]
  urls?: UrlObject[]
  type?: PoolTypeState
  option?: WhitelistState
  currentAmount?: string
  tokenPrice?: string
}

export async function getPoolInfo(
  provider: Provider,
  addressPool: string,
  type: PoolTypeState,
): Promise<PoolInfoInterface | undefined> {
  //eslint-disable-next-line
  const contract = usePoolContract(provider, addressPool, type)
  if (!contract) return
  return await contract.methods.poolInfo().call()
}

export async function balanceOf(
  tokenAddress: string,
  provider: Provider,
  userAccount: string,
): Promise<string | undefined> {
  //eslint-disable-next-line
  const contract = useERC20Contract(provider, tokenAddress)
  if (!contract) return
  return contract.methods.balanceOf(userAccount).call()
}

export async function getTokenSymbol(tokenAddress: string, provider: Provider): Promise<string | undefined> {
  //eslint-disable-next-line
  const contract = useERC20Contract(provider, tokenAddress)
  if (!contract) return
  return contract.methods.symbol().call()
}

export async function poolInfo(provider: Provider, addressPool: string, type: PoolTypeState) {
  //eslint-disable-next-line
  const contract = usePoolContract(provider, addressPool, type)
  if (!contract) return
  const value = await contract.methods.poolInfo().call()
  return value
}

// export async function isValidProofForBuy(
//   provider: Provider,
//   proof: BytesLike[],
//   account: string,
//   maxAllocation: string,
// ): Promise<boolean> {
//   //eslint-disable-next-line
//   const contract = useWhitelistTreeContract(provider)
//   if (contract) {
//     return await contract.methods
//       .isMerkleProofValid(
//         proof.map((el) => el.toString()),
//         account,
//         maxAllocation,
//       )
//       .call()
//   }
//   return false
// }

// export async function buyWithProof(
//   provider: Provider,
//   proof: BytesLike[],
//   userAccount: string,
//   sellTokenAddress: string,
//   maxAllocation: string,
//   inputAmount: string,
//   chainId: number,
//   updateTransactions: (transaction: Transaction) => void,
// ): Promise<void> {
//   //eslint-disable-next-line
//   const contract = useWhitelistTreeContract(provider)
//   if (!contract) return
//   if (sellTokenAddress == provider.contractSet.WETH) {
//     await contract.methods
//       .exchangeValue(
//         proof.map((el) => el.toString()),
//         maxAllocation,
//       )
//       .send({ from: userAccount })
//       .on('transactionHash', function (hash: string) {
//         const transaction: Transaction = createTransaction(hash, 'eth', 'Buy', chainId)
//         updateTransactions(transaction)
//       })
//   } else {
//     await contract.methods
//       .exchange(
//         proof.map((el) => el.toString()),
//         maxAllocation,
//         inputAmount,
//       )
//       .send({ from: userAccount })
//       .on('transactionHash', function (hash: string) {
//         const transaction: Transaction = createTransaction(hash, 'eth', 'Buy', chainId)
//         updateTransactions(transaction)
//       })
//   }
// }
