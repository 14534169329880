import React, { useState } from 'react'
import { useStyles } from './styles'
import validator from 'validator'

export const URLInput = React.memo(function InnerInput({
  placeholder,
  value,
  setValue,
  propertyWidth,
  setIsError,
  csvCheck = false,
}: {
  placeholder?: string
  value: string
  setValue: (el: string) => void
  propertyWidth?: boolean
  setIsError?: React.Dispatch<React.SetStateAction<boolean>>
  csvCheck?: boolean
}) {
  const { input, errorInput } = useStyles()
  const [isValid, setIsValid] = useState(true)
  const validate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
    if (validator.isURL(e.target.value) || e.target.value.length == 0) {
      setIsValid(true)
      setIsError && setIsError(false)
    } else {
      setIsValid(false)
      setIsError && setIsError(true)
    }
  }

  return (
    <input
      value={value}
      onChange={validate}
      placeholder={placeholder}
      className={isValid ? input : errorInput}
      style={{ boxSizing: 'border-box', width: propertyWidth ? '100%' : '390px' }}
    />
  )
})
