import { TFunction } from 'react-i18next'
import { Provider, valueMap } from 'services/Provider'

// TODO: Extend to support for BNB
export function tokenToDisplay(token: string, t: TFunction<'translation'>): string {
    return token == 'WETH' ? t('common_eth_token') : token
}

export function formatTokenSymbol(provider: Provider, tokenAddress: string, symbol: string): string {
  return tokenAddress === provider.contractSet.WETH ? valueMap[provider.currentNetwork] : symbol
}