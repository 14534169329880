import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  content: {
    backgroundColor: '#000',//colors.white,
    // height: 'calc(100vh - 187px)',
    //INFO: don't need scrollable header for landing
    width: '100%',
    color: colors.globalBlack,
    flexGrow: 1,
  },
  contentWrap: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '100vh',
    backgroundColor: '#000',
    color: '#fff'
  },
  defaultButton: {
    width: '203px',
    height: '46px',
    marginTop: '40px',
    background: colors.yellowLinearGradient,
    borderRadius: '66px',
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '1rem',
    cursor: 'pointer',
    '&:hover': {
      background: colors.yellowLinearGradient,
      opacity: 0.6,
    },
    '&:active': {
      background: colors.yellowLinearGradient,
      opacity: 0.3,
    },
  },
  defaultTitleBox: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '104px',
  },
  defaultTitle: {
    fontSize: '3rem',
    maxWidth: '700px',
    textAlign: 'center',
  },
  switchNetworkBox: {
    height: 'calc(100vh - 360px)',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
})
