import { makeStyles } from '@material-ui/core'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  input: {
    color: colors.defaultWhite,
    width: '100%',
    position: 'relative',
    fontWeight: 500,
    outline: 'none',
    border: 'none',
    flex: '1 1 auto',
    backgroundColor: 'transparent',
    fontSize: '1.5rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '0',
    paddingLeft: '15px',
    '&::placeholder': {
      color: colors.defaultWhite,
    },
  },
})
