import { Tooltip } from '@material-ui/core'
import { useWeb3React } from '@web3-react/core'
import { PoolFields } from 'assets/constants'
import EthIcon from 'assets/icons/EthIcon.svg'
import FixedPriceGold from 'assets/icons/FixedPriceGold.svg'
import LinearPriceGold from 'assets/icons/LinearPriceGold.svg'
import LotteryGold from 'assets/icons/LotteryGold.svg'
import SmallLogo from 'assets/icons/SmallLogo.svg'
import UsdcIcon from 'assets/icons/UsdcIcon.svg'
import UsdtIcon from 'assets/icons/UsdtIcon.svg'
import WhiteListGold from 'assets/icons/WhiteListGold.svg'
import Loader from 'components/loader'
import ProgressBar from 'components/progress_bar'
import { currentPrice, getBalance, getPoolInfo, poolInfo, PoolInfoInterface } from 'hooks'
import { useDeactivatedPool } from 'hooks/useEditPool'
import { useERC20 } from 'hooks/useERC20'
import { usePoolContract } from 'hooks/usePoolDetails'
import { PoolTypeState } from 'pages/pool_types'
import { WhitelistState } from 'pages/whitelistingOptions'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { POOL_DESCRIPTION_TEMPLATE } from 'routes'
import { NETWORK_TYPE, Provider } from 'services/Provider'
import { getDateDiff, getFormattedDate, secondsToMilliseconds } from 'utils/dateFormatter'
import { getUrlElement, UrlObject } from 'utils/store'
import { tokenToDisplay } from 'utils/tokenFormatter'
import { convertSellTokenToBuyToken, formattedDecimals, fromDecimals } from 'utils/tsUtils'
import { useStyles } from './styles'

export interface CardProps {
  title: string
  image: string
  firstToken: string
  secondAmount: string
  secondToken: string
  totalRaise: string
  currentValueInSellToken: string
  currentValueInBuyToken: string
  maxValueInBuyToken: string
  startDate: number
  endDate: number
  pooType: PoolTypeState
  poolOption: WhitelistState
  sellAcceptedToken: string
  isDeleted: boolean,
  address: string
}
export interface CardInfoProps {
  progressTitle: string
  progressTitleColor: string
  progressColor: string
  durationTitle: string
  dateColor: string
}

const convertInfoValues = (types: string[], values: string[]): UrlObject[] => {
  const resultArray = []
  for (const el in types) {
    resultArray.push({ id: Number(types[el]), link: values[el] })
  }
  return resultArray
}

export interface CardComponentProps {
  address: string
  poolPrototype: string
}

export const CardComponent = ({ address, poolPrototype }: CardComponentProps): JSX.Element => {
  const { library, chainId } = useWeb3React()

  const provider: Provider = new Provider(library.provider, chainId)

  const [poolData, setPoolData] = useState<PoolInfoInterface | null>(null)
  const [tokenSymbols, setTokenSymbols] = useState<string[] | null>()
  const [sellTokenAddress, setSellTokenAddress] = useState<string>('')
  const [buyTokenAddress, setBuyTokenAddress] = useState<string>('')
  const [isDeleted, setIsDeleted] = useState<boolean>(false)

  const sellToken = useERC20(provider, sellTokenAddress)
  const buyToken = useERC20(provider, buyTokenAddress)
  const type = provider.contractSet.linearPool == poolPrototype ? PoolTypeState.Linear : PoolTypeState.Fixed
  const pool = usePoolContract(provider, address, type)

  useEffect(() => {
    const setTokens = async () => {
      if (poolData) return
      if (!provider || !provider.contractSet) return
      const type =
        poolPrototype.toLowerCase() === provider.contractSet.linearPool.toLowerCase()
          ? PoolTypeState.Linear
          : PoolTypeState.Fixed

      const info = await getPoolInfo(provider, address, type)

      if (info) {
        setBuyTokenAddress(info._buyToken)
        setSellTokenAddress(info._sellToken)
      }
    }
    setTokens()
  }, [])

  useEffect(() => {
    const getPoolData = async () => {
      //INFO: WITHOUT NEXT CHECK WILL BE INFINITE LOOP

      if (poolData) return
      if (!provider || !provider.contractSet) return
      const type =
        poolPrototype.toLowerCase() === provider.contractSet.linearPool.toLowerCase()
          ? PoolTypeState.Linear
          : PoolTypeState.Fixed

      const info = await getPoolInfo(provider, address, type)

      if (info) {
        if (buyToken == null || sellToken == null) return
        //INFO: CHECK HOW PROGRESS BAR IN CARD CALCULATED
        info.urls = convertInfoValues(info._types, info._values)
        info.type = type
        info.option = WhitelistState.Whitelist
        const price = await currentPrice(provider, address, type)
        if (!price) return

        info.tokenPrice = formattedDecimals(
          convertSellTokenToBuyToken(fromDecimals('1', sellToken.decimals), price).toString(),
          buyToken.decimals,
        )

        buyToken && sellToken && setTokenSymbols([sellToken.symbol, buyToken.symbol])
        const currentAmount = await getBalance(provider, address, info._buyToken)
        info.currentAmount = fromDecimals(currentAmount ?? '0', buyToken.decimals).toString()
        setPoolData(info)
      }
    }

    getPoolData()
  }, [sellToken, buyToken])

  useEffect(() => {
    const deletePool = async () => {
      if (!pool) return
      //eslint-disable-next-line
      const info = await useDeactivatedPool(pool)
      setIsDeleted(info)
    }
    deletePool()
  })

  if (
    !poolData ||
    !poolData.urls ||
    !tokenSymbols ||
    !poolData.type ||
    !poolData.option ||
    !poolData._distributedBuyAmount ||
    !poolData._totalSellAmount ||
    !poolData._totalBuyAmount ||
    !poolData.currentAmount ||
    !poolData.tokenPrice
  ) {
    return <Loader />
  }

  const poolImage = getUrlElement(poolData.urls, PoolFields.IMAGE_URL).link
  const poolTitle = getUrlElement(poolData.urls, PoolFields.TITLE).link
  const startDate = secondsToMilliseconds(poolData._startTimestamp) ?? Date.now()
  const endDate = secondsToMilliseconds(poolData._endTimestamp) ?? Date.now()

  return (
    <Link to={POOL_DESCRIPTION_TEMPLATE(address)} style={{ textDecoration: 'none' }}>
      <Card
        title={poolTitle}
        image={poolImage}
        startDate={startDate}
        endDate={endDate}
        pooType={poolData.type}
        poolOption={poolData.option}
        maxValueInBuyToken={poolData._distributedBuyAmount}
        currentValueInBuyToken={poolData._totalBuyAmount}
        currentValueInSellToken={
          sellToken != null ? Number(formattedDecimals(poolData._totalSellAmount, sellToken.decimals)).toFixed(3) : '0'
        }
        firstToken={tokenSymbols[0]}
        secondAmount={poolData.tokenPrice ?? '0'}
        secondToken={tokenSymbols[1]}
        totalRaise={poolData.currentAmount}
        sellAcceptedToken={sellTokenAddress}
        isDeleted={isDeleted}
        address={address}
      />
    </Link>
  )
}

export const Card = ({
  title,
  image,
  firstToken,
  secondAmount,
  secondToken,
  currentValueInSellToken,
  currentValueInBuyToken,
  maxValueInBuyToken,
  startDate,
  endDate,
  pooType,
  poolOption,
  sellAcceptedToken,
  isDeleted,
  address
}: CardProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [imageError, setImageError] = useState<boolean>(false)
  const [poolImage, setPoolImage] = useState<string>(image)
  const [sellTokenImage, setSellTokenImage] = useState<string>('')
  const { library, chainId } = useWeb3React()
  const provider: Provider = new Provider(library.provider, chainId)
  const handleImageError = () => {
    setImageError(true)
  }

  useEffect(() => {
    if (imageError) {
      setPoolImage(SmallLogo)
    }
    sellTokenIcon()
  }, [imageError])

  const sellTokenIcon = () => {
    switch (sellAcceptedToken) {
      case provider.contractSet.USDT: {
        setSellTokenImage(UsdtIcon)
        break
      }
      case provider.contractSet.USDC: {
        setSellTokenImage(UsdcIcon)
        break
      }
      default:
        setSellTokenImage(EthIcon)
    }
  }

  const currentDate = Number(new Date())
  const inProgress = {
    progressTitle: t('pool_card_progress_button'),
    progressTitleColor: classes.whiteText,
    progressColor: classes.progressVioletBlock,
    durationTitle: t('pool_card_ends'),
    dateColor: classes.endGoldDate,
  }
  const comingSoon = {
    progressTitle: t('pool_card_coming_soon'),
    progressTitleColor: classes.blackText,
    progressColor: classes.progressYellowBlock,
    durationTitle: t('pool_card_starts_in'),
    dateColor: classes.endGoldDate,
  }
  const completed = {
    progressTitle: t('pool_card_completed'),
    progressTitleColor: classes.blackText,
    progressColor: classes.progressGreenBlock,
    durationTitle: t('pool_card_ended'),
    dateColor: classes.endDate,
  }
  const deleted = {
    progressTitle: t('pool_edit_deleted'),
    progressTitleColor: classes.blackText,
    progressColor: classes.progressRedBlock,
    durationTitle: t('pool_edit_status'),
    dateColor: classes.endDate,
  }

  const [cardInfo, setCardInfo] = useState<CardInfoProps>(inProgress)
  const [dateFormat, setDateFormat] = useState<string | undefined>('')

  const getCardInfo = (startDate: number, endDate: number, isDeleted: boolean) => {
    if (currentDate < startDate) {
      setCardInfo(comingSoon)
      setDateFormat(getDateDiff(startDate, t))
    } else if (currentDate >= endDate) {
      setCardInfo(completed)
      setDateFormat(getFormattedDate(endDate))
    } else {
      setCardInfo(inProgress)
      setDateFormat(getDateDiff(endDate, t))
    }
    if (isDeleted) {
      setCardInfo(deleted)
      setDateFormat(t('pool_edit_deleted'))
    }
  }

  useEffect(() => {
    getCardInfo(startDate, endDate, isDeleted)
  }, [startDate, endDate, isDeleted])

  return (
    <div className={classes.cardContainer}>
      <div className={classes.columnBlock}>
        <div className={classes.rowBlock}>
          <img onError={handleImageError} src={poolImage} alt="card icon" />
        </div>
        <div className={classes.headerRowContainer}>
          <div className={classes.rowBlock}>
            <img src={sellTokenImage} alt="sell icon" />
          </div>
          <div className={cardInfo.progressColor}>
            <span className={cardInfo.progressTitleColor}>{cardInfo.progressTitle}</span>
          </div>
        </div>
      </div>

      <div className={classes.columnBlock}>
        <div className={classes.rowBlock}>
          <Tooltip title={title}>
            <span className={classes.titleText}>{title}</span>
          </Tooltip>
          {address  !== '0x8aBd6F5fFe3Dcc3552C1b25da232Be525AF75BaF' && secondAmount && (
            <span className={classes.greyText}>
              {t('pool_card_price', {
                amountBuy: 1,
                buyToken: firstToken,
                amountSell: parseFloat(secondAmount).toFixed(2),
                sellToken: tokenToDisplay(secondToken, t),
              })}
            </span>
          )}
        </div>
        {/* <div className={classes.rowBlock}>
          <span className={classes.greyText}>{cardInfo.durationTitle}</span>
          <span className={cardInfo.dateColor}>{dateFormat}</span>
        </div> */}
      </div>

      <div className={classes.columnBlock}>
        <div className={classes.rowBlock}>
          <span className={classes.greyText}>{cardInfo.durationTitle}</span>
          <span className={cardInfo.dateColor}>{dateFormat}</span>
        </div>
      </div>

      <div className={classes.columnBlock} style={{ margin: 0 }}>
        <div className={classes.rowBlock}>
          <span className={classes.whiteText}>{t('pool_card_total_raise')}</span>
          <span className={classes.largeGoldText}>
            {currentValueInSellToken}
            <span className={classes.mediumGoldText}> {tokenToDisplay(firstToken, t)}</span>
          </span>
        </div>
      </div>

      {address  !== '0x8aBd6F5fFe3Dcc3552C1b25da232Be525AF75BaF' && (
        <ProgressBar currentValue={currentValueInBuyToken} maxValue={maxValueInBuyToken} />
      )}

      {address  !== '0x8aBd6F5fFe3Dcc3552C1b25da232Be525AF75BaF' && (
        <div className={classes.footerContainer}>
          <div className={classes.rowBlock}>
            <img src={pooType === PoolTypeState.Fixed ? FixedPriceGold : LinearPriceGold} alt="pool_type" />
            <span className={classes.goldInfo}>
              {pooType === PoolTypeState.Fixed ? t('pool_card_fixed') : t('pool_card_linear')}
            </span>
          </div>
          <div className={classes.rowBlock}>
            <img src={poolOption === WhitelistState.Whitelist ? WhiteListGold : LotteryGold} alt="pool_options" />
            <span className={classes.goldInfo}>
              {poolOption === WhitelistState.Whitelist ? t('pool_whitelist_whitelist') : t('pool_whitelist_lottery')}
            </span>
          </div>
        </div>
      )}

      {address  === '0x8aBd6F5fFe3Dcc3552C1b25da232Be525AF75BaF' && (
        <div className={classes.footerContainer} style={{ marginTop: 10 }}>
          <div className={classes.rowBlock}>
            <img src={LotteryGold} alt="pool_options" style={{ marginBottom: 10 }}/>
            <span className={classes.goldInfo}>
              {t('pool_whitelist_lottery')}
            </span>
          </div>
        </div>
      )}
    </div>
  )
}
