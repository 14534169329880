import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  buttonStyles: {
    width: '203px',
    height: '46px',
    background: colors.yellowLinearGradient,
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '1rem',
    cursor: 'pointer',
    '&:hover': {
      background: colors.yellowLinearGradient,
      opacity: 0.6,
    },
    '&:active': {
      background: colors.yellowLinearGradient,
      opacity: 0.3,
    },
    color: colors.defaultWhite,
  },
  linkText: {
    color: colors.globalBlack,
    textDecoration: 'none',
    textTransform: 'none',
    cursor: 'pointer',
    height: '100%',
    width: '100%',
  },
})
