import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  wrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '187px',
    padding: '0 50px',
    backgroundColor: colors.defaultBlack,
    userSelect: 'none',
    position: 'relative',
    zIndex: 10,
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'all 0.4s',
    textDecoration: 'none',
    '&:hover': {
      opacity: 0.8,
    },
    '@media (max-width: 750px)': {
      display: 'none',
    },
  },
  logoContainerMobile: {
    display: 'none',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'all 0.4s',
    textDecoration: 'none',
    '@media (max-width: 750px)': {
      display: 'block',
      '&:hover': {
        opacity: 0.8,
      },
    },
  },
  navBarContainer: {
    width: '35vw',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  ulList: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
  },
  liItem: { marginRight: '30px' },
  navLinkContainer: {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    transition: 'all 0.4s',
    textDecoration: 'none',
    '&>span': {
      color: '#FFF',
      opacity: 1,
      marginRight: '6px',
      fontSize: '1.13rem',
      lineHeight: '22px',
    },
    '&:hover': {
      opacity: 0.6,
    },
  },
  navLinkActiveContainer: {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    transition: 'all 0.4s',
    textDecoration: 'none',
    '&>span': {
      color: '#41B129',
      opacity: 0.5,
      marginRight: '6px',
      fontSize: '1.13rem',
      lineHeight: '22px',
      transition: 'all 0.4s',
    },
    '&:hover': {
      opacity: 0.8,
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
})
