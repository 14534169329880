import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  mainContainer: {
    width: '270px',
    height: '80px',
    background: `linear-gradient(180deg, ${colors.primaryYellowFirst} 22.4%, ${colors.primaryYellowSecond} 66.15%)`,
    boxShadow: `0px 4px 5px ${colors.blackShadow_25}`,
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'row',
    padding: '2px 10px',
    boxSizing: 'border-box',
    margin: '14px 0 23px 0',
    alignItems: 'center',
  },
})
