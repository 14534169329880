import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  root: {
    padding: 0,
  },
  paperWidthSm: {
    background: `linear-gradient(180deg, ${colors.primaryYellowFirst} 22.4%, ${colors.primaryYellowSecond} 66.15%)`,
    boxShadow: `0px 0px 25px ${colors.blackShadow_50}`,
    borderRadius: '10px',
    padding: '58px 35px',
    minWidth: '425px',
  },
  title: {
    fontSize: '3rem',
    lineHeight: '59px',
    fontWeight: 600,
    color: colors.defaultWhite,
    textAlign: 'center',
  },
  whiteText: {
    fontSize: '1.5rem',
    lineHeight: '30px',
    color: colors.white,
  },
  subtitle: {
    fontSize: '1.5rem',
    lineHeight: '30px',
    color: colors.defaultWhite,
    margin: '20px 80px 0',
    textAlign: 'center',
  },
  dialogContent: {
    padding: 0,
    overflowY: 'initial',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    alignItems: 'center',
  },
  addressBox: {
    borderRadius: '20px',
    width: '-webkit-fill-available',
    margin: '4px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  addressText: {
    textTransform: 'uppercase',
    background: colors.yellowLinearGradient,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: '1.5rem',
    lineHeight: '44px',
    fontWeight: 700,
    marginRight: '16px'
  },
  blackContainer: {
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: colors.defaultBlack,
    overflowY: 'auto',
    maxHeight: '200px',
    paddingRight: 15,
    paddingLeft: 15,
    borderRadius: 15,
    marginTop: 15,
    marginBottom: 15,
  }
})
