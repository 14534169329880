import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  rootContainer: {
    maxWidth: '370px',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    background: colors.white,
    borderRadius: '15px',
    alignItems: 'center',
    '&> span:last-child > div:nth-child(2)': {
      display: 'none',
    },
    '&> span': {
      width: '80%',
    },
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '15px',
  },
  rightTopContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    marginBottom: '-15px',
    zIndex: 1,
  },
  goldRightBg: {
    minWidth: '233px',
    minHeight: '51px',
    background: colors.yellowLinearGradient,
    borderRadius: '10px',
  },
  goldBorder: {
    background: colors.yellowLinearGradient,
    borderRadius: '15px',
    padding: 2,
    width: '230px',
    marginLeft: '7px',
    marginTop: '-20px',
  },
  goldBg: {
    height: '100%',
    background: colors.yellowLinearGradient,
    borderRadius: '10px',
    margin: '0 5px 11px 5px',
    display: 'flex',
    alignItems: 'center',
  },
  titleText: {
    fontSize: '0.9rem',
    color: colors.defaultBlack,
    lineHeight: '18px',
    margin: '17px 0',
    textAlign: 'center',
  },
  totalAmountText: {
    fontSize: '2.25rem',
    color: colors.defaultBlack,
    lineHeight: '44px',
    margin: '0 13px 0 23px',
  },
  titleTransformText: {
    fontSize: '0.9rem',
    color: colors.defaultBlack,
    writingMode: 'vertical-lr',
    transform: 'rotate(180deg)',
    lineHeight: '18px',
    margin: '0 9px',
  },
  amountText: {
    fontSize: '1.5rem',
    color: colors.defaultBlack,
    lineHeight: '29px',
    margin: '40px 0',
    textAlign: 'center',
  },
  daysText: {
    fontSize: '3rem',
    color: colors.defaultBlack,
    margin: '13px 18px',
  },
  goldSeparator: {
    borderTop: `2px solid ${colors.primaryYellowFirst}`,
    minWidth: '190px',
  },
})
