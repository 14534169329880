import { Button } from '@material-ui/core'
import { useWeb3React } from '@web3-react/core'
import { PoolFields, urlInputs } from 'assets/constants'
import { BackButton } from 'components/buttons'
import { URLInput } from 'components/inputs/link_inputs'
import Loader from 'components/loader'
import { DeleteModal } from 'components/modals/modal_delete'
import { WarningModal } from 'components/modals/modal_warning'
import { useEditPool } from 'hooks/useEditPool'
import { Pool, usePool } from 'hooks/usePoolDetails'
import { setTransaction } from 'hooks/useTransactions'
import CSVTable from 'pages/whitelist/CSVTable'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Redirect, useRouteMatch } from 'react-router-dom'
import { POOLS } from 'routes'
import { Provider } from 'services/Provider'
import { useRefresh } from 'services/RefreshContext'
import { convertWhiteListArgsEditPool } from 'utils/NumberFormatter'
import { StoreContext, Transaction } from 'utils/store'
import { whitelistCheckError } from 'utils/treeUtils'
import { Nullable } from 'utils/tsUtils'
import validator from 'validator'
import { useStyles } from './styles'

interface UrlObject {
  id: PoolFields
  link: string | null
}
interface PoolEditProps {
  account: string
  pool: Pool
  chainId: number
  whitelistUrl: string
}

export const PoolEditSwitch = (): JSX.Element => {
  const match = useRouteMatch<{ address: string }>()
  const { account, library, chainId } = useWeb3React()

  if (!account || !library || !chainId || !library.provider) {
    return <Redirect to="/" />
  }
  const provider = new Provider(library.provider, chainId)
  if (!provider.utils.isAddress(match.params.address)) {
    return <Redirect to="/" />
  }
  //eslint-disable-next-line
  const [pool] = usePool(provider, match.params.address)
  if (!pool || !pool.whitelist.whitelistURL) {
    return <Loader />
  }
  return <PoolEdit account={account} pool={pool} chainId={chainId} whitelistUrl={pool.whitelist.whitelistURL} />
}

const PoolEdit = ({ account, pool, chainId, whitelistUrl }: PoolEditProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const { slowRefresh } = useRefresh()
  const store = useContext(StoreContext)
  const editPool = useEditPool(pool.contract)

  const [whitelist, setWhitelist] = useState<string>(whitelistUrl)
  const [isError, setIsError] = useState<boolean>(false)
  const [poolError, setPoolError] = useState<boolean>(false)
  const [openDelete, setOpenDelete] = useState<boolean>(false)
  const [txResult, setTxResult] = useState<Nullable<Transaction>>(null)
  const [txModalOpen, setTxModalOpen] = useState<boolean>(false)
  const [errorText, setErrorText] = useState<string>('')
  const [root, setRoot] = useState<string>('')
  const [poolTitle, setPoolTitle] = useState<string>(pool.title)

  const getUrlCallback = (id: number) => (value: string) => store.setUrl(id, value)

  const isInputsEmpty = () => {
    return urlInputs(t).every((el) =>
      el.isRequired ? store.getUrl(el.id).link !== '' && validator.isURL(store.getUrl(el.id).link) : true,
    )
  }
  const isDisabled = !(isInputsEmpty() && !isError && !poolError && !(whitelist.length == 0))

  const oldValue: UrlObject[] = [
    { link: pool.URLs.description, id: PoolFields.DESCRIPTION_URL },
    { link: pool.URLs.website, id: PoolFields.WEBSITE_URL },
    { link: pool.URLs.applicationForm, id: PoolFields.APPLICATION_FORM_URL },
    { link: pool.URLs.image, id: PoolFields.IMAGE_URL },
    { link: pool.URLs.discord, id: PoolFields.DISCORD_URL },
    { link: pool.URLs.twitter, id: PoolFields.TWITTER_URL },
    { link: pool.URLs.whitepaper, id: PoolFields.WHITEPAPER_URL },
    { link: pool.URLs.medium, id: PoolFields.MEDIUM_URL },
    { link: pool.URLs.telegram, id: PoolFields.TELEGRAM_URL },
    { link: pool.URLs.github, id: PoolFields.GITHUB_URL },
  ]
  const newValue = store.urls

  const handleOpen = () => {
    setOpenDelete(true)
  }

  const handleClose = () => {
    setErrorText('')
  }
  const handleDeleteClose = (tx: Nullable<Transaction>) => {
    setOpenDelete(false)

    if (tx) {
      setTxResult(tx)
      setTxModalOpen(true)
    }
  }

  const handleCloseSuccess = (tx: Nullable<Transaction>) => {
    if (tx) {
      setTxResult(tx)
      setTxModalOpen(true)
    }
  }
  const onTransactionAlertClosed = () => {
    history.replace(POOLS)
    setTxModalOpen(false)
  }

  const urlInfo = urlInputs(t).map((el) => {
    return {
      titleName: el.name,
      pathValue: store.getUrl(el.id).link,
      pathSetValue: getUrlCallback(el.id),
      error: setPoolError,
      isRequired: el.isRequired,
    }
  })

  const requiredInputs = urlInfo.filter((el) => el.isRequired)
  const socialsInputs = urlInfo.filter((el) => !el.isRequired)

  const checkWhiteList = async () => {
    await whitelistCheckError(whitelist, pool.buyToken.decimals, setErrorText, setRoot, t)
  }

  const changeInfo = async () => {
    let whitelistArgs
    if (whitelistUrl !== whitelist) {
      checkWhiteList()
      if (!root) return
      whitelistArgs = convertWhiteListArgsEditPool(root, whitelist)
    } else {
      whitelistArgs = []
    }

    const result = newValue.filter((x: UrlObject) => oldValue.some((y: UrlObject) => x.link !== y.link && x.id == y.id))
    const types = result.map((el: UrlObject) => el.id)
    const values = result.map((el: UrlObject) => el.link)

    if (poolTitle && poolTitle !== pool.title) {
      types.push(0)
      values.push(poolTitle)
    }
    const tx = await editPool(account, types, values, whitelistArgs, chainId, setTransaction)
    handleCloseSuccess(tx)
  }

  useEffect(() => {
    isInputsEmpty()
  }, [slowRefresh])

  useEffect(() => {
    store.setUrls(oldValue)
  }, [pool])

  return (
    <div className={classes.rootContainer}>
      <div className={classes.headerContainer}>
        <div className={classes.rowContainer}>
          <div className={classes.titleContainer}>
            <span className={classes.titleText}>{t('pool_edit_title')} </span>
            <span className={classes.infoText}>{t('pool_edit_title_info')} </span>
          </div>
          <Button className={classes.redButton} onClick={handleOpen}>
            <span>{t('pool_edit_delete_pool_button')}</span>
          </Button>
        </div>
        <div className={classes.backButtonStepContainer}>
          <BackButton path={POOLS} title={t('common_back_button')} />
        </div>
      </div>

      <div className={classes.contentContainer}>
        <div className={classes.card}>
          <input
            value={poolTitle}
            className={classes.blackInput}
            placeholder={t('provide_pools_pool_title_placeholder')}
            onChange={(e) => setPoolTitle(e.target.value)}
          />
          <span className={classes.infoWarningText}>{t('common_max_pool_name')}</span>
          <div className={classes.segmentBox}>
            <div className={classes.segmentContent}>
              {requiredInputs.map((el, index) => (
                <span key={index} className={classes.columnContainer}>
                  <span className={classes.infoText}>{el.titleName} </span>
                  <URLInput
                    value={el.pathValue}
                    setValue={el.pathSetValue}
                    setIsError={el.error}
                    propertyWidth={true}
                  />
                </span>
              ))}
              <div>
                <div>
                  <h1 className={classes.socialTitle}>
                    {t('pool_creation_socials')}
                    <span>{t('pool_creation_optional')}</span>
                  </h1>
                </div>
                <div className={classes.socialInputsContainer}>
                  {socialsInputs.map((el, index) => (
                    <div key={index} className={classes.flex_column}>
                      <span className={classes.infoText}>{el.titleName} </span>
                      <URLInput
                        value={el.pathValue}
                        setValue={el.pathSetValue}
                        setIsError={el.error}
                        propertyWidth={true}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.maxWidthContainer}>
          <URLInput
            value={whitelist}
            setValue={setWhitelist}
            placeholder={t('whitelist_placeholder_whitelist')}
            propertyWidth={true}
            setIsError={setIsError}
            csvCheck={true}
          />
        </div>
        <div className={classes.maxWidthContainer}>
          {whitelist && !isError && <CSVTable list={whitelist} setIsError={setIsError} />}
        </div>
        <Button className={classes.defaultButton} onClick={changeInfo} disabled={isDisabled}>
          <span>{t('pool_edit_save_all_changes_button')}</span>
        </Button>
      </div>
      <DeleteModal handleClose={handleDeleteClose} open={openDelete} poolContract={pool.contract} />
      <WarningModal
        handleClose={onTransactionAlertClosed}
        onClick={onTransactionAlertClosed}
        open={txModalOpen}
        transaction={txResult?.hash}
        titleText={t('common_pool_change')}
        infoText={t('pool_card_success_dialog_description')}
        buttonStyle={classes.defaultButton}
        buttonText={t('summary_ok')}
      />
      <WarningModal
        handleClose={handleClose}
        onClick={handleClose}
        open={errorText !== ''}
        titleText={t('summary_error_title')}
        infoText={errorText}
        buttonStyle={classes.errorButton}
        buttonText={t('summary_ok')}
      />
    </div>
  )
}
