import gql from 'graphql-tag'

export const GET_PROPOSALS = gql`
  query Proposals {
    proposalAmounts {
      id
      amount
    }
    createdProposals {
      id
      title
      deadlineBlock
    }
  }
`

export const GET_TOP_USERS = gql`
  query Users {
    users(orderBy: bonus, orderDirection: desc, first: 11) {
      id
      referrer
      bonus
      value
      nodesCount
      promocode
    }
  }
`

export const GET_ALL_USERS = gql`
  query Users {
    transactions {
      id
      value
    }
  }
`

export const GET_MY_BONUS = (id: string) => {
  return gql`
  query Users {
    users( where:{ id:"${id.toLowerCase()}" }) {
      id
      referrer
      bonus
      value
      promocode
    }
  }
`
}

export const GET_MY_PROMOTED_USERS = (id: string) => {
  return gql`
  query Users {
    users( where:{ referrer:"${id.toLowerCase()}" }) {
      id
      referrer
      bonus
      value
    }
  }
`
}

export const GET_TOKENS_SOLD = gql`
  query Factories {
    factories {
      id
      tokenSold
    }
  }
`

export const GET_ALL_POOL_ADDRESS = gql`
  query Pools {
    poolEvents {
      id
      pool
      creator
      whitelist
      isPremium
      poolPrototype
    }
  }
`

export const GET_POOL_TYPE = (pool: string) => {
  return gql`
  query PoolType {
    poolEvents( where:{ pool:"${pool}" }) {
      poolPrototype
      whitelist
      creator
    }
  }
`
}

export const GET_CREATOR_POOLS = (creator: string) => {
  return gql`
  query PoolType {
    poolEvents( where:{ creator:"${creator}" }) {
      id
      pool
      creator
      whitelist
      isPremium
      poolPrototype
    }
  }
`
}
