import React from 'react'
import { useStyles } from './styles'
import { useHistory } from 'react-router'
import { PoolTypeState } from 'pages/pool_types'
import { WhitelistState } from 'pages/whitelistingOptions'

interface CardProps {
  img: string
  title: string
  info: string
  path: string
  isDark?: boolean
  setPoolType?: React.Dispatch<React.SetStateAction<PoolTypeState>>
  poolType?: PoolTypeState
  listingOption?: WhitelistState
  setListingOption?: React.Dispatch<React.SetStateAction<WhitelistState>>
  isDisabled?: boolean
}

const ChoosesPoolCard = ({
  img,
  title,
  info,
  path,
  isDark,
  poolType,
  setPoolType,
  listingOption,
  setListingOption,
  isDisabled,
}: CardProps): JSX.Element => {
  const { rootBlackContainer, rootYellowContainer, titleBlackText, infoBlackText, titleWhiteText, infoWhiteText } =
    useStyles()
  const history = useHistory()

  const handlePush = () => {
    if (setListingOption && listingOption) {
      setListingOption(listingOption)
    }

    if (setPoolType && poolType) {
      setPoolType(poolType)
    }
    history.push(path)
  }

  return (
    <div
      className={isDark ? rootBlackContainer : rootYellowContainer}
      style={{ pointerEvents: isDisabled ? 'none' : 'auto' }}
      onClick={handlePush}
    >
      <div>
        <div>
          <img src={img} alt={title} />
        </div>
        <div className={isDark ? titleWhiteText : titleBlackText}>{title}</div>
        <div className={isDark ? infoWhiteText : infoBlackText}>{info}</div>
      </div>
    </div>
  )
}

export default ChoosesPoolCard
