import React, { FC } from 'react'
import Button, { ButtonProps as MuiButtonProps } from '@material-ui/core/Button'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'
import { transformAddress } from 'utils/NumberFormatter'

interface ButtonProps extends MuiButtonProps {
  activeWeb3Account: string
  activateWeb3Account: () => void
}

const WalletButton: FC<ButtonProps> = ({ activeWeb3Account, activateWeb3Account }: ButtonProps) => {
  const { t } = useTranslation()
  const { buttonStyles } = useStyles()

  return (
    <Button classes={{ root: buttonStyles }} onClick={activateWeb3Account}>
      <span>{activeWeb3Account ? transformAddress(activeWeb3Account) : t('common_connect_wallet')}</span>
    </Button>
  )
}

export default WalletButton
