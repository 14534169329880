import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  rootContainer: {
    display: 'flex',
    padding: '10px 50px',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '100px',
    justifyContent: 'center',
    padding: '120px 0 0',
    '@media (max-width: 1024px)': {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
})
