import { PoolFields } from 'assets/constants'
import React, { createContext, useCallback, useState } from 'react'

export const StoreContext = createContext<any>(null)

export type TransactionStatus = 'success' | 'canceled' | 'pending' | 'failed'
export type TransactionChain = 'eth'
export interface UrlObject {
  id: number
  link: string
}
export interface SerializableTransactionReceipt {
  to: string
  from: string
  contractAddress: string
  transactionIndex: number
  blockHash: string
  transactionHash: string
  blockNumber: number
  status?: number
}

export interface Transaction {
  status: TransactionStatus
  hash: string
  summary: string
  chainId: number
  chain: TransactionChain
  addedTime: number
  lastCheckedBlockNumber?: number
  receipt?: SerializableTransactionReceipt
}
export function createTransaction(hash: string, chain: TransactionChain, summary: string, chainId = 1): Transaction {
  return {
    hash: hash,
    status: 'pending',
    chainId: chainId,
    chain: chain,
    addedTime: new Date().getTime(),
    summary: summary,
  }
}
export interface BlockInfo {
  data: { [key in TransactionChain]: { chainId: number; blockNumber: number }[] }
}

export const getUrlElement = (arr: UrlObject[], id: number) => {
  const index = arr.findIndex((element) => element.id === id)
  if (index < 0) return { id: id, link: '' }
  else return arr[index]
}

export const StoreProvider = ({ children }: { children: JSX.Element }) => {
  const [poolType, setPoolType] = useState<string>('')
  const [listOption, setListOption] = useState<string>('')

  const [urls, setUrls] = useState<UrlObject[]>([])

  const [poolTitle, setPoolTitle] = useState<string>('')
  const [buyTokenAddress, setBuyTokenAddress] = useState<string>('')
  const [sellAcceptedToken, setSellAcceptedToken] = useState<string>('')
  const [whiteListUrl, setWhiteListUrl] = useState<string>('')
  const [sellStartPrice, setSellStartPrice] = useState<string>('')
  const [sellEndPrice, setSellEndPrice] = useState<string>('')
  const [buyStartPrice, setBuyStartPrice] = useState<string>('')
  const [buyEndPrice, setBuyEndPrice] = useState<string>('')
  const [fixedBuyPrice, setFixedBuyPrice] = useState<string>('')
  const [fixedSellPrice, setFixedSellPrice] = useState<string>('')
  const [cliffDayDuration, setCliffDayDuration] = useState<string>('')
  const [cliffWeekDuration, setCliffWeekDuration] = useState<string>('')
  const [vestDayInterval, setVestDayInterval] = useState<string>('')
  const [vestWeekInterval, setVestWeekInterval] = useState<string>('')
  const [cliffPercent, setCliffPercent] = useState<string>('')
  const [vestPercent, setVestPercent] = useState<string>('')
  const [raiseAmount, setRaiseAmount] = useState<string>('')
  const [startDate, setStartDate] = useState<Date>(new Date())
  const _endDate = new Date()
  _endDate.setDate(_endDate.getDate() + 1) // add day
  const [endDate, setEndDate] = useState<Date>(_endDate)
  const [sellTokenSymbol, setSellTokenSymbol] = useState<null | string>(null)
  const [buyTokenSymbol, setBuyTokenSymbol] = useState<null | string>(null)

  const [web3Account, setWeb3Account] = useState<string>('')
  const [isExists, setIsExists] = useState<boolean>(true)

  const [activeProposalPreview, setActiveProposalPreview] = useState<
    { id: string; title: string; deadlineBlock: string; amount: string }[]
  >()
  const [inactiveProposalPreview, setInactiveProposalPreview] = useState<
    { id: string; title: string; deadlineBlock: string; amount: string }[]
  >()
  const [blockNumberInfo, setBlockNumberInfo] = useState<BlockInfo>({ data: { eth: [] } })
  const [transactionModalOpen, setTransactionModalOpen] = useState<boolean>(false)

  const getUrl = (id: number) => {
    return getUrlElement(urls, id)
  }

  const setUrl = (id: number, value: string) => {
    const newUrls = [...urls]
    const index = newUrls.findIndex((el) => el.id === id)

    if (index < 0) newUrls.push({ id: id, link: value })
    else newUrls[index].link = value

    setUrls(newUrls)
  }

  const getCardImageUrl = () => {
    return getUrl(PoolFields.IMAGE_URL).link
  }

  const store = {
    sellTokenSymbol,
    setSellTokenSymbol,
    getCardImageUrl,
    web3Account,
    setWeb3Account,
    isExists,
    setIsExists,
    activeProposalPreview,
    setActiveProposalPreview,
    inactiveProposalPreview,
    setInactiveProposalPreview,
    blockNumberInfo,
    setBlockNumberInfo,
    transactionModalOpen,
    setTransactionModalOpen,
    poolType,
    setPoolType,
    listOption,
    setListOption,
    poolTitle,
    setPoolTitle,
    whiteListUrl,
    setWhiteListUrl,
    sellStartPrice,
    setSellStartPrice,
    sellEndPrice,
    setSellEndPrice,
    buyStartPrice,
    setBuyStartPrice,
    buyEndPrice,
    setBuyEndPrice,
    fixedBuyPrice,
    setFixedBuyPrice,
    fixedSellPrice,
    setFixedSellPrice,
    cliffDayDuration,
    setCliffDayDuration,
    cliffWeekDuration,
    setCliffWeekDuration,
    vestDayInterval,
    setVestDayInterval,
    vestWeekInterval,
    setVestWeekInterval,
    cliffPercent,
    setCliffPercent,
    vestPercent,
    setVestPercent,
    raiseAmount,
    setRaiseAmount,
    buyTokenAddress,
    setBuyTokenAddress,
    sellAcceptedToken,
    setSellAcceptedToken,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    getUrl,
    setUrl,
    setUrls,
    urls,
    buyTokenSymbol,
    setBuyTokenSymbol,
  }

  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
}
