import React from 'react'
import { useStyles } from './styles'
import Big from 'big.js'

const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`) // match escaped "." characters via in a non-capturing group
//TODO: delete balance value, add MAX value (compare with Big?)

export const Input = React.memo(function InnerInput({
  value,
  onUserInput,
  placeholder,
  balance,
  isDisabled,
  ...rest
}: {
  value: string
  onUserInput: (value: string) => void
  balance?: string
  error?: boolean
  fontSize?: string
  isDisabled?: boolean
  align?: 'right' | 'left'
} & Omit<React.HTMLProps<HTMLInputElement>, 'ref' | 'onChange' | 'as'>) {
  const { input } = useStyles()
  const escapeRegExp = (string: string): string => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
  }
  const enforcer = (nextUserInput: string) => {
    if (nextUserInput === '.') {
      onUserInput('0.')
    } else if (nextUserInput === '' || inputRegex.test(escapeRegExp(nextUserInput))) {
      onUserInput(nextUserInput)
    }
    if (nextUserInput.length == 0) {
      return
    } else if (
      inputRegex.test(escapeRegExp(nextUserInput)) &&
      balance &&
      new Big(nextUserInput).gte(new Big(balance))
    ) {
      onUserInput(balance)
    }
  }

  return (
    <input
      className={input}
      value={value}
      onChange={(event) => {
        // replace commas with periods, because uniswap exclusively uses period as the decimal separator
        enforcer(event.target.value.replace(/,/g, '.'))
      }}
      disabled={isDisabled}
      // universal input options
      inputMode="decimal"
      title="Token Amount"
      autoComplete="off"
      autoCorrect="off"
      max={balance}
      // text-specific options
      type="text"
      pattern="^[0-9]*[.,]?[0-9]*$"
      placeholder={placeholder || '0.0'}
      minLength={1}
      maxLength={79}
      spellCheck="false"
      {...rest}
    />
  )
})
