import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  rootContainer: {
    display: 'flex',
    padding: '10px 50px',
    flexDirection: 'column',
  },
  contentContainer: {
    padding: '0 12%',
    marginTop: '40px',
  },
  segmentContentContainer: {
    padding: '0 12%',
  },
  goldBgContainer: {
    width: '100%',
    height: '100px',
    background: colors.yellowLinearGradient,
    borderRadius: '15px',
    fontWeight: 500,
    boxShadow: `0px 4px 4px ${colors.blackShadow_25}`,
    margin: '48px 0',
  },
  goldLabelContainer: {
    height: '100%',
    color: colors.defaultWhite,
    fontWeight: 500,
    fontSize: '2.25rem',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '90px',
  },
  blackBgContainer: {
    width: '100%',
    height: '100px',
    background: colors.defaultBlack,
    borderRadius: '15px',
    fontWeight: 500,
    boxShadow: `0px 4px 4px ${colors.blackShadow_25}`,
    margin: '48px 0',
  },
  blackBg: {
    width: '70px',
    height: '44px',
    margin: '0 11px 11px 0',
    background: colors.defaultBlack,
    borderRadius: '10px',
    fontWeight: 500,
    boxShadow: `0px 4px 4px ${colors.blackShadow_25}`,
  },
  blackBGSmallContainer: {
    background: colors.defaultBlack,
    borderRadius: '15px',
    fontWeight: 500,
    boxShadow: `0px 4px 4px ${colors.blackShadow_25}`,
    height: '50px',
    width: '393px',
    margin: '18px 0px 40px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: '0 10px',
    '&> img': {
      margin: '5px',
    },
  },
  blackSecondBgContainer: {
    width: '320px',
    height: '50px',
    background: colors.defaultBlack,
    borderRadius: '15px',
    fontWeight: 500,
    boxShadow: `0px 4px 4px ${colors.blackShadow_25}`,
  },
  whiteTitle: {
    height: '100%',
    color: colors.white,
    fontWeight: 500,
    fontSize: '2.25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 10px',
  },
  whiteSecondTitle: {
    height: '100%',
    color: colors.white,
    fontWeight: 500,
    fontSize: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'max-content',
    padding: '0 10px',
  },
  whiteInfo: {
    height: '100%',
    color: colors.white,
    fontWeight: 500,
    fontSize: '0.75rem',
    display: 'flex',
    alignItems: 'center',
    padding: '0 10px',
  },
  whiteLabelInfo: {
    height: '100%',
    color: colors.white,
    fontWeight: 500,
    fontSize: '1.125rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  grayTitle: {
    lineHeight: '22px',
    color: colors.secondGrey,
    fontWeight: 500,
    fontSize: '1.125rem',
  },
  grayInfo: {
    lineHeight: '22px',
    color: colors.secondGrey,
    fontWeight: 500,
    fontSize: '1rem',
  },
  blackTitle: {
    lineHeight: '22px',
    color: colors.defaultWhite,
    fontWeight: 500,
    fontSize: '1.125rem',
    width: '-webkit-fill-available',
  },
  blackPrimaryTitle: {
    color: colors.defaultWhite,
    fontWeight: 500,
    fontSize: '2.25rem',
    display: 'flex',
    alignItems: 'center',
    width: '-webkit-fill-available',
  },
  blackInfo: {
    lineHeight: '17px',
    color: colors.defaultWhite,
    fontWeight: 500,
    fontSize: '0.875rem',
    display: 'flex',
    alignItems: 'center',
  },
  //TODO: gridTemplateColumns -> 1fr 1fr
  linksContainer: {
    display: 'grid',
    gridTemplateColumns: 'calc(50% - 5px) calc(50% - 5px)',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 'inherit',
    alignItems: 'center',
    gap: '10px',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: 'inherit',
    alignItems: 'center',
    gap: '30px',
  },
  urlInputContainer: {
    width: '100%',
    height: '50px',
    background: colors.defaultBlack,
    borderRadius: '15px',
    fontWeight: 500,
    boxShadow: `0px 4px 4px ${colors.blackShadow_25}`,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  linkContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '15px',
  },
  rowSecondContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 'inherit',
    gap: '10px',
    alignItems: 'flex-end',
    margin: '0 100px',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 'inherit',
    alignItems: 'center',
    margin: '0 30px',
  },
  paddingBlock: {
    padding: '16px 0 ',
  },
  defaultButton: {
    width: '320px',
    height: '77px',
    marginBottom: '20px',
    background: colors.yellowLinearGradient,
    borderRadius: '15px',
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '1.5rem',
    cursor: 'pointer',
    color: colors.defaultWhite,
    boxShadow: `0px 4px 4px ${colors.blackShadow_25}`,
    '&:hover': {
      background: colors.yellowLinearGradient,
      opacity: 0.9,
    },
    '&:active': {
      background: colors.yellowLinearGradient,
      opacity: 0.8,
    },
    '&:disabled': {
      background: `${colors.disabledButton} !important`,
      color: colors.white,
    },
  },
  errorButton: {
    width: '280px',
    height: '76px',
    background: colors.redButton,
    borderRadius: '15px',
    textTransform: 'none',
    cursor: 'pointer',
    color: colors.white,
    fontSize: '1.5rem',
    lineHeight: '30px',
    boxShadow: `0px 4px 4px ${colors.blackShadow_25}`,
    '&:hover': {
      background: colors.redButton,
      opacity: 0.9,
    },
    '&:active': {
      background: colors.redButton,
      opacity: 0.8,
    },
  },
})
