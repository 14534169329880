import React, { useContext, useState } from 'react'
import { Button } from '@material-ui/core'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { WHITELISTING_OPTIONS, WHITELIST } from 'routes'
import CustomDatePicker from '../../components/date_picker'
import TitleHeader from 'components/title_header'
import { URLInput } from 'components/inputs/link_inputs'
import { StoreContext } from 'utils/store'
import validator from 'validator'
import { urlInputs } from 'assets/constants'

const PoolInfo = (): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const store = useContext(StoreContext)
  const [dateError, setDateError] = useState<boolean>(false)
  const [poolError, setPoolError] = useState<boolean>(false)

  const getCb = (id: number) => (value: string) => store.setUrl(id, value)

  const isInputsEmpty = () => {
    return urlInputs(t).every((el) =>
      el.isRequired ? store.getUrl(el.id).link !== '' && validator.isURL(store.getUrl(el.id).link) : true,
    )
  }

  const isDisabled = !(isInputsEmpty() && store.poolTitle && !poolError && !dateError)

  const urlInfo = urlInputs(t).map((el) => {
    return {
      titleName: el.name,
      pathValue: store.getUrl(el.id).link,
      pathSetValue: getCb(el.id),
      error: setPoolError,
      isRequired: el.isRequired,
    }
  })
  const requiredInputs = urlInfo.filter((el) => el.isRequired)
  const socialsInputs = urlInfo.filter((el) => !el.isRequired)

  if (!store.poolType || !store.listOption) history.push(WHITELISTING_OPTIONS)

  return (
    <div className={classes.rootContainer}>
      <TitleHeader
        title={t('provide_pools_title')}
        info={t('provide_pools_title_info')}
        path={WHITELISTING_OPTIONS}
        currentStep={'3'}
        buttonTitle={t('provide_pools_back_button')}
      />

      <div className={classes.contentContainer}>
        <div className={classes.card}>
          <input
            className={classes.blackInput}
            value={store.poolTitle}
            maxLength={30}
            placeholder={t('provide_pools_pool_title_placeholder')}
            onChange={(e) => store.setPoolTitle(e.target.value)}
          />
          <span className={classes.infoWarningText}>{t('common_max_pool_name')}</span>
          <div className={classes.inputsContainer}>
            <div className={classes.segmentBox}>
              <div className={classes.segmentContent}>
                {requiredInputs.map((el, index) => (
                  <div key={index} className={classes.flex_column}>
                    <span className={classes.infoText}>{el.titleName}</span>
                    <URLInput value={el.pathValue} setValue={el.pathSetValue} setIsError={el.error} />
                  </div>
                ))}
              </div>
              <div className={classes.segmentBlackContent}>
                <span className={classes.titleWhiteText}>{t('provide_pools_pool_duration')}</span>
                <span className={classes.infoWhiteText}>{t('provide_pools_pool_duration_info')}</span>
                <div className={classes.segmentSliders}>
                  <div className={classes.goldTitle}>{t('pool_creation_start_date')} </div>
                  <CustomDatePicker date={store.startDate} setDate={store.setStartDate} />
                  <div className={classes.goldTitle}>{t('pool_creation_end_date')} </div>
                  <CustomDatePicker
                    date={store.endDate}
                    setDate={store.setEndDate}
                    startDate={store.startDate}
                    setIsError={setDateError}
                  />
                </div>
              </div>
            </div>
            <div>
              <div>
                <h1 className={classes.socialTitle}>
                  {t('pool_creation_socials')}
                  <span>{t('pool_creation_optional')}</span>
                </h1>
              </div>
              <div className={classes.socialInputsContainer}>
                {socialsInputs.map((el, index) => (
                  <div key={index} className={classes.flex_column}>
                    <span className={classes.infoText}>{el.titleName} </span>
                    <URLInput
                      value={el.pathValue}
                      setValue={el.pathSetValue}
                      setIsError={el.error}
                      propertyWidth={true}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <Button onClick={() => history.push(WHITELIST)} className={classes.defaultButton} disabled={isDisabled}>
            {t('common_next_button')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default PoolInfo
