import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  loaderStyles: {
    width: '28px',
    height: '28px',
    WebkitAnimationName: 'spin',
    marginRight: '10px',
    WebkitAnimationDuration: '4000ms',
    WebkitAnimationIterationCount: 'infinite',
    WebkitAnimationTimingFunction: 'linear',
    MozAnimationName: 'spin',
    MozAnimationDuration: '4000ms',
    MozAnimationIterationCount: 'infinite',
    MozAnimationTimingFunction: 'linear',
    animationName: 'spin',
    animationDuration: '4000ms',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
  },
})
