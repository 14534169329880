import React, { useContext } from 'react'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'
import { POOLS, WHITELISTING_OPTIONS } from 'routes'
import { StoreContext } from 'utils/store'
import TitleHeader from 'components/title_header'
import ChoosesPoolCard from 'components/chooses_pool_card'
import FixedPrice from 'assets/icons/FixedPrice.svg'
import LinearPrice from 'assets/icons/LinearPrice.svg'

export enum PoolTypeState {
  Fixed = 'Fixed',
  Linear = 'Linear',
}

const PoolTypes = (): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { setPoolType } = useContext(StoreContext)

  const PoolCardInfo = [
    {
      img: FixedPrice,
      title: t('pool_types_fixed_price'),
      info: t('pool_types_fix_price_info'),
      path: WHITELISTING_OPTIONS,
      isDark: false,
      poolType: PoolTypeState.Fixed,
    },
    {
      img: LinearPrice,
      title: t('pool_types_linear_price'),
      info: t('pool_types_linear_price_info'),
      path: WHITELISTING_OPTIONS,
      isDark: false,
      poolType: PoolTypeState.Linear,
    },
  ]
  return (
    <>
      <div className={classes.rootContainer}>
        <TitleHeader
          title={t('pool_types_title')}
          info={t('pool_types_title_info_')}
          path={POOLS}
          currentStep={'1'}
          buttonTitle={t('pool_types_back_button')}
        />
      </div>
      <div className={classes.cardContainer}>
        {PoolCardInfo.map((el, index) => (
          <div key={index}>
            <ChoosesPoolCard {...el} setPoolType={setPoolType} />
          </div>
        ))}
      </div>
    </>
  )
}

export default PoolTypes
