import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  rootContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 50px',
    '@media (max-width: 700px)': {
      padding: '0',
    },
  },
  contentContainer: {
    padding: '0 5%',
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    width: '-webkit-fill-available',
    maxWidth: '1200px',
    '@media (max-width: 1100px)': {
      padding: '0 30px',
    },
  },
  claimContainerDesktop: {
    width: '100%',
    backgroundColor: colors.defaultBlack,
    borderRadius: '20px',
    marginTop: '37px',
    marginBottom: '60px',
    maxWidth: 1200,
    '@media (max-width: 700px)': {
      display: 'none',
    },
  },
  claimContainerMobile: {
    width: '100%',
    display: 'none',
    '@media (max-width: 700px)': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  table: {
    padding: '40px 58px 10px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    '@media (max-width: 1100px)': {
      padding: '40px 20px 10px',
    },
  },
  dayColumn: {
    marginRight: '15px',
    width: '-webkit-fill-available',
    maxWidth: '25%',
  },
  headerCell: {
    background: colors.yellowLinearGradient,
    borderRadius: '13px',
    fontFamily: 'Formular',
    fontStyle: 'normal',
    fontSize: '0.88rem',
    lineHeight: '17px',
    height: '51px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
    textAlign: 'center',
    '@media (max-width: 1100px)': {
      fontSize: '0.63rem',
    },
  },
  dayCell: {
    background: colors.yellowLinearGradient,
    borderRadius: '16px',
    fontFamily: 'Formular',
    fontStyle: 'normal',
    fontSize: '3rem',
    lineHeight: '59px',
    height: '83px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '11px',
    '&:last-child': {
      marginBottom: 0,
      borderBottom: 'none',
    },
    color: '#fff'
  },
  basicCell: {
    color: colors.primaryYellowFirst,
    fontFamily: 'Formular',
    fontStyle: 'normal',
    fontSize: '1.5rem',
    lineHeight: '59px',
    padding: '12px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '10px',
    '@media (max-width: 1100px)': {
      fontSize: '1.13rem',
    },
  },
  yellowLine: {
    height: '1px',
    background: colors.yellowLinearGradient,
    width: '85%',
    margin: '0 auto',
  },
  basicColumn: {
    border: `1px solid ${colors.primaryYellowFirst}`,
    borderRadius: '15px',
    width: '25%',
    height: 'max-content',
    margin: '0 10px',
    boxSizing: 'border-box',
  },
  totalBox: {
    padding: '10px 58px 40px 154px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    '@media (max-width: 1100px)': {
      padding: '10px 20px 40px 119px',
    },
  },
  totalTitle: {
    marginRight: '15px',
    background: '#fff',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '3.2rem',
  },
  totalValue: {
    color: colors.primaryYellowSecond,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '3.2rem',
    padding: '0 30px',
  },
  totalSumBox: {
    background: colors.yellowLinearGradient,
    borderRadius: '15px',
    width: '25%',
    height: '56px',
    fontSize: '1.5rem',
    color: colors.globalBlack,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 10px',
  },

  totalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    background: colors.yellowLinearGradient,
    borderRadius: '15px',
    marginRight: '10px',
    height: '105px',
    width: 'calc(75% + 40px)',
  },

  withdrawValueBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    background: colors.yellowLinearGradient,
    borderRadius: '15px',
    marginRight: '10px',
    height: '105px',
    width: '402px',
    '@media (max-width: 768px)': {
      width: '250px',
    },
  },
  withdrawCurrency: {
    fontSize: '1.5rem',
    alignSelf: 'flex-end',
    margin: '0 0 22px 5px',
  },
  withdrawValue: {
    fontSize: '2.5rem',
    '@media (max-width: 768px)': {
      fontSize: '1.5rem',
    },
  },
  withdrawButton: {
    width: '259px',
    height: '105px',
    background: colors.defaultBlack,
    borderRadius: '10px',
    textTransform: 'none',
    border: 'none',
    fontWeight: 500,
    fontSize: '3rem',
    cursor: 'pointer',
    color: colors.primaryYellowSecond,
    '&:hover': {
      background: colors.defaultBlack,
      opacity: 0.9,
    },
    '&:active': {
      background: colors.defaultBlack,
      opacity: 0.8,
    },
    '&:disabled': {
      background: `${colors.disabledButton} !important`,
      color: colors.white,
      cursor: 'auto',
      '&:hover': {
        background: colors.defaultBlack,
        opacity: 1,
      },
    },
  },
  withdrawMobileButton: {
    width: '259px',
    background: colors.defaultBlack,
    borderRadius: '10px',
    textTransform: 'none',
    border: 'none',
    fontWeight: 500,
    fontSize: '1.8rem',
    cursor: 'pointer',
    color: colors.primaryYellowSecond,
    '&:hover': {
      background: colors.defaultBlack,
      opacity: 0.9,
    },
    '&:active': {
      background: colors.defaultBlack,
      opacity: 0.8,
    },
  },

  headingTitle: {
    color: colors.defaultYellow,
    fontSize: '2.2rem',
    lineHeight: '58px',
    fontWeight: 500,
  },
  headingText: {
    color: colors.defaultBlack,
    fontSize: '1.13rem',
    lineHeight: '22px',
    maxWidth: '700px',
  },
  headingContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: '30px',
    alignSelf: 'flex-start',
  },
  totalHeadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: '30px',
    alignItems: 'center',
    '@media (max-width: 700px)': {
      display: 'none',
    },
  },
  totalMobile: {
    display: 'flex',
    width: '100%',
    marginTop: '20px',
  },
  totalMobileTitle: {
    width: '-webkit-fill-available',
    height: '56px',
    background: colors.defaultBlack,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    display: 'flex',
    fontSize: '3rem',
  },
  withdrawMobileBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: colors.yellowLinearGradient,
    borderRadius: '15px',
    height: '85px',
    width: '100%',
    margin: '20px 30px',
  },
})
