import { Link } from '@material-ui/core'
import MediumIcon from 'assets/icons/MediumIcon.svg'
import TelegramIcon from 'assets/icons/TelegramIcon.svg'
import TwitterIcon from 'assets/icons/TwitterIcon.svg'
import React from 'react'
import { useStyles } from './styles'

const Footer = (): JSX.Element => {
  const { footerContainer, footerLinks, link, socialContainer, iconContainer, footerRightsText } = useStyles()

  const currentYear = new Date().getFullYear()

  return (
    <footer className={footerContainer}>
      <div className={footerLinks}>
        <Link
          className={link}
          target="_blank"
          href="https://smartpadofficial.medium.com/an-introduction-to-smartpad-b5b85c89f213"
        >
          About
        </Link>
        <Link className={link} target="_blank" href="https://t.me/smartpad_group">
          Support
        </Link>
      </div>
      <div className={socialContainer}>
        <Link className={iconContainer} target="_blank" href="https://t.me/smartpad_group">
          <img src={TelegramIcon} alt="telegram-icon" />
        </Link>
        <Link className={iconContainer} target="_blank" href="https://smartpadofficial.medium.com/">
          <img src={MediumIcon} alt="medium-icon" />
        </Link>
        <Link className={iconContainer} target="_blank" href="https://twitter.com/SmartPad_launch">
          <img src={TwitterIcon} alt="twitter-icon" />
        </Link>
      </div>
      <span className={footerRightsText}>{`©${currentYear}. All rights reserved`}</span>
    </footer>
  )
}

export default Footer
