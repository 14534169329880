import BN from 'bn.js'
import Web3 from 'web3'
import Big from 'big.js'
import { BigNumber } from 'ethers'
import { UrlObject } from './store'
const SECONDS_IN_DAY = 24 * 60 * 60
const SECONDS_IN_WEEK = SECONDS_IN_DAY * 7
const MAX_PERCENT = 10000
const MIN_PERCENT = 0

const web3 = new Web3()

export const fromBNValue = (value: string): Big => {
  return new Big(value).div(new Big(BASE).pow(PRECISION))
}

export const PRECISION = 18
export const BASE = 10

export class NumberFormatter {
  static ranges = [
    { divider: 1e18, suffix: 'E' },
    { divider: 1e15, suffix: 'P' },
    { divider: 1e12, suffix: 'T' },
    { divider: 1e9, suffix: 'G' },
    { divider: 1e6, suffix: 'M' },
    { divider: 1e3, suffix: 'K' },
  ]
  static prefixFormat(number: number): string {
    for (let i = 0; i < NumberFormatter.ranges.length; i++) {
      if (Math.abs(number) >= NumberFormatter.ranges[i].divider) {
        return (number / NumberFormatter.ranges[i].divider).toFixed(1) + NumberFormatter.ranges[i].suffix
      }
    }
    return number.toString()
  }
  static formatNumber(number: number | string): number {
    return Number(number) / Math.pow(BASE, PRECISION)
  }

  static formatBN(number: BN): BN {
    return number.div(new BN(BASE).pow(new BN(PRECISION)))
  }
}

export const transformAddress = (address: string): string => {
  return `${address.substring(0, 6)}…${address.substring(address.length - 4)}`
}

declare module 'big.js' {
  interface Big {
    toFixedSpecial(n: number): string
    toFormat(n: Big, precision: number): string
  }
}

Big.prototype.toFixedSpecial = function (n: number) {
  let str = this.toFixed(n)
  if (str.indexOf('e+') === -1) return str

  // if number is in scientific notation, pick (b)ase and (p)ower
  str = str
    .replace('.', '')
    .split('e+')
    .reduce(function (p: string, b: number) {
      return p + Array(b - p.length + 2).join('0')
    })

  if (n > 0) str += '.' + Array(n + 1).join('0')

  return str
}

Big.prototype.toFormat = (n: Big, precision: number) => {
  const newNumber = new Big(n.toFixed(precision)).toFixed()
  if (newNumber == '0' && !n.eq(0)) {
    return '< 0.000001'
  }
  return newNumber
}

export const toMilliseconds = (value: string): number => {
  return new Big(value).times(1000).toNumber()
}

export const formateToEth = (value: string | number): string => Web3.utils.fromWei(new BN(value))

export const toTokenPrecision = (value: string | Big, precision: number): Big => {
  return new Big(value).mul(new Big(BASE).pow(precision))
}

export const toBNValue = (value: string | Big): Big => {
  return new Big(value).mul(new Big(BASE).pow(PRECISION))
}

export function expandTo18Decimals(n: number): BigNumber {
  return BigNumber.from(n).mul(BigNumber.from(BASE).pow(PRECISION))
}

export function convertDescriptionData(creatorAddress: string, name: string, url: UrlObject[]) {
  const numTypes = url.map((el) => el.id)
  const values = url.map((el) => el.link)
  numTypes.push(0)
  values.push(name)
  return web3.eth.abi.encodeParameters(['address', 'uint[]', 'string[]'], [creatorAddress, numTypes, values])
}

export function convertVestingData(
  start: number,
  end: number,
  cliffPercentage: number,
  cliffEnd: number,
  vestingPercentage: number,
  vestingInterval: number,
  creatorCliffPercentage: number,
  creatorCliffEnd: number,
  creatorVestingPercentage: number,
  creatorVestingInterval: number,
) {
  return web3.eth.abi.encodeParameters(
    ['uint256', 'uint256', 'uint16', 'uint32', 'uint16', 'uint32', 'uint16', 'uint32', 'uint16', 'uint32'],
    [
      start,
      end,
      cliffPercentage,
      cliffEnd,
      vestingPercentage,
      vestingInterval,
      creatorCliffPercentage,
      creatorCliffEnd,
      creatorVestingPercentage,
      creatorVestingInterval,
    ],
  )
}

export function convertWhiteListArgs(creatorAddress: string, sellToken: string, root: string, csvUrl: string) {
  return web3.eth.abi.encodeParameters(
    ['address', 'address', 'bytes32', 'string'],
    [creatorAddress, sellToken, root, csvUrl],
  )
}

export function convertWhiteListArgsEditPool(root: string, csvUrl: string) {
  return web3.eth.abi.encodeParameters(
    [ 'bytes32', 'string'],
    [root, csvUrl],
  )
}

export function convertFixedPoolDetails(startPriceBuyInSellUQ: string) {
  return web3.eth.abi.encodeParameters(['uint256'], [startPriceBuyInSellUQ])
}

export function convertLinearPoolDetails(startPriceBuyInSellUQ: string, endPriceBuyInSellUQ: string) {
  return web3.eth.abi.encodeParameters(['uint256', 'uint256'], [startPriceBuyInSellUQ, endPriceBuyInSellUQ])
}

export function toUQ112(value: string): BigNumber {
  return BigNumber.from(value).mul(BigNumber.from(2).pow(112))
}

export function calculateCliffEnd(days: number, weeks: number, endDate: number) {
  return endDate + weeks * SECONDS_IN_WEEK + days * SECONDS_IN_DAY
}

export function calculateVestDuration(days: number, weeks: number) {
  return weeks * SECONDS_IN_WEEK + days * SECONDS_IN_DAY
}

export function formatPercentage(percent: number): number {
  const result = percent * 100
  if (result > MAX_PERCENT) {
    return MAX_PERCENT
  } else if (result < MIN_PERCENT) {
    return 0
  } else return result
}
