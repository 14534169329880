import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  rootContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 50px',
  },
  contentContainer: {
    padding: '0 5%',
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
  },
  claimContainer: {
    minWidth: 535,
    backgroundColor: colors.defaultBlack,
    padding: '40px 58px 75px',
    borderRadius: '20px',
    marginTop: '37px',
    marginBottom: '60px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
  },
  defaultButton: {
    width: '100%',
    height: '74px',
    marginTop: '15px',
    marginBottom: '40px',
    background: colors.yellowLinearGradient,
    borderRadius: '10px',
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '1rem',
    cursor: 'pointer',
    '&:hover': {
      background: colors.yellowLinearGradient,
      opacity: 0.6,
    },
    '&:active': {
      background: colors.yellowLinearGradient,
      opacity: 0.3,
    },
  },
  buttonTextContainer: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonText: {
    fontSize: '1.5rem',
    fontWeight: 500,
    lineHeight: '29px',
    color: colors.defaultWhite,
  },
  titleBlock: {
    fontSize: '1.5rem',
    fontWeight: 400,
    lineHeight: '29px',
    textAlign: 'center',
    color: colors.primaryYellowFirst,
  },
  claimAmount: {
    fontSize: '4rem',
    fontWeight: 700,
    lineHeight: '78px',
    textAlign: 'center',
    color: colors.primaryYellowFirst,
  },
  bonusBg: {
    borderRadius: '16px',
    background: colors.yellowLinearGradient,
  },
  bonusContainer: {
    border: 'none',
    borderRadius: '16px',
    background: colors.defaultBlack,
    margin: 2,
    padding: 14,
    display: 'flex',
    justifyContent: 'space-between',
  },
  bonusDailyContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  bonusBlackText: {
    color: colors.defaultWhite,
    margin: 'inherit',
    fontWeight: 400,
    fontSize: '0.9rem',
    lineHeight: '17px',
  },
  bonusGoldText: {
    color: colors.primaryYellowFirst,
    margin: '5px',
    fontWeight: 400,
    fontSize: '1.1rem',
    lineHeight: '22px',
  },
  dailyBonusAmount: {
    borderRadius: '16px',
    background: colors.yellowLinearGradient,
    margin: '5px',
    width: '87px',
    textAlign: 'center',
  },
})
