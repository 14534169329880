import { Web3Provider } from '@ethersproject/providers'
import { useWeb3React } from '@web3-react/core'
import React, { useEffect, useState } from 'react'
import { injectedConnector } from 'services/Provider'

export default ({ children }: { children: JSX.Element }): JSX.Element => {
  const { connector } = useWeb3React<Web3Provider>()
  const triedEager: boolean = useEagerConnect()

  const [activatingConnector, setActivatingConnector] = React.useState<any>()
  React.useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined)
    }
  }, [activatingConnector, connector])

  useInactiveListener(!triedEager || !!activatingConnector)

  return children
}

export function useEagerConnect(): boolean {
  const { activate, active } = useWeb3React()

  const [tried, setTried] = useState(false)

  useEffect(() => {
    const { ethereum } = window as any

    injectedConnector.isAuthorized().then((isAuthorized: boolean) => {
      if (isAuthorized) {
        activate(injectedConnector, undefined, true).catch(() => {
          setTried(true)
        })
      } else if (ethereum) {
        activate(injectedConnector, undefined, true).catch(() => {
          setTried(true)
        })
      } else {
        setTried(true)
      }
    })
  }, [])

  useEffect(() => {
    if (!tried && active) {
      setTried(true)
    }
  }, [tried, active])

  return tried
}

export function useInactiveListener(suppress = false): void {
  const { active, error, activate, deactivate } = useWeb3React()

  useEffect((): any => {
    const { ethereum } = window as any

    if (!active && !error && !suppress) {
      const handleNetworkChanged = (networkId: string | number): void => {
        if (networkId == 3 || networkId == 1) {
          activate(injectedConnector)
        } else {
          deactivate()
        }
      }
      if (ethereum) {
        ethereum.on('networkChanged', handleNetworkChanged)
        return () => {
          if (ethereum.removeListener) {
            ethereum.removeListener('networkChanged', handleNetworkChanged)
          }
        }
      }
    }
  }, [active, error, suppress, activate, deactivate])
}
