import { AbiFixedPool } from 'contracts/web3/typings/abi_fixed_pool'
import { AbiLinearPool } from 'contracts/web3/typings/abi_linear_pool'
import { useCallback } from 'react'
import { createTransaction, Transaction } from 'utils/store'

type EditPool = (
  account: string,
  types: (number | string)[],
  values: string[],
  whitelistData: string | number[],
  chainId: number,
  updateTransactions: (transaction: Transaction) => void,
) => Promise<Transaction>

export function useEditPool(contract: AbiFixedPool | AbiLinearPool): EditPool {
  return useCallback<EditPool>(async (account, types, values, whitelistData, chainId, updateTransactions) => {
    return new Promise((resolve) => {
      contract.methods
        .changeInfo(types, values, whitelistData)
        .send({ from: account })
        .on('transactionHash', function (hash: string) {
          const transaction: Transaction = createTransaction(hash, 'eth', 'ChangeInfo', chainId)
          updateTransactions(transaction)
          resolve(transaction)
        })
    })
  }, [])
}

type DeletedPool = (
  account: string,
  chainId: number,
  updateTransactions: (transaction: Transaction) => void,
) => Promise<Transaction>

export function useDeletedPool(contract: AbiFixedPool | AbiLinearPool): DeletedPool {
  return useCallback<DeletedPool>(async (account, chainId, updateTransactions) => {
    return new Promise((resolve) => {
      contract.methods
        .destruct()
        .send({ from: account })
        .on('transactionHash', function (hash: string) {
          const transaction: Transaction = createTransaction(hash, 'eth', 'Destruct', chainId)
          updateTransactions(transaction)
          resolve(transaction)
        })
    })
  }, [])
}

export function useDeactivatedPool(contract: AbiFixedPool | AbiLinearPool): Promise<boolean> {
  return contract.methods.deactivated().call()
}
