import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  rootContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 50px',
  },
  headerContainer: {
    padding: '0 12%',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '85px',
  },
  contentContainer: {
    padding: '0 12%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: '30px',
  },
  redButton: {
    width: '280px',
    height: '76px',
    background: colors.redButton,
    borderRadius: '15px',
    textTransform: 'none',
    cursor: 'pointer',
    color: colors.white,
    fontSize: '1.5rem',
    lineHeight: '30px',
    boxShadow: `0px 4px 4px ${colors.blackShadow_25}`,
    '&:hover': {
      background: colors.redButton,
      opacity: 0.9,
    },
    '&:active': {
      background: colors.redButton,
      opacity: 0.8,
    },
  },
  infoWarningText: {
    color: colors.defaultBlack,
    fontSize: '1rem',
    marginLeft: '20px',
  },
  defaultButton: {
    width: '370px',
    height: '100px',
    marginBottom: '20px',
    background: colors.yellowLinearGradient,
    borderRadius: '15px',
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '1.5rem',
    cursor: 'pointer',
    color: colors.defaultBlack,
    boxShadow: `0px 4px 4px ${colors.blackShadow_25}`,
    '&:hover': {
      background: colors.yellowLinearGradient,
      opacity: 0.9,
    },
    '&:active': {
      background: colors.yellowLinearGradient,
      opacity: 0.8,
    },
    '&:disabled': {
      background: colors.disabledButton,
      color: colors.white,
    },
  },
  card: {
    width: '-webkit-fill-available',
    background: `linear-gradient(180deg, ${colors.primaryYellowFirst} 22.4%, ${colors.primaryYellowSecond} 66.15%)`,
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'column',
    padding: '61px 67px 70px 67px',
    boxSizing: 'border-box',
  },
  blackInput: {
    backgroundColor: 'transparent',
    color: colors.defaultBlack,
    outline: 'none',
    margin: '2px',
    height: '90px',
    border: `2px solid ${colors.defaultBlack}`,
    borderRadius: '15px',
    fontSize: '2rem',
    lineHeight: '29px',
    fontWeight: 700,
    textAlign: 'center',
    '&::placeholder': {
      color: colors.placeholder,
      textAlign: 'center',
      fontSize: '2rem',
    },
  },
  segmentBox: {
    border: colors.blackBorder,
    borderRadius: '20px',
    marginTop: '40px',
    padding: '25px 40px 60px',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    gap: '20px',
  },
  segmentContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  infoText: {
    color: colors.defaultBlack,
    fontSize: '1.13rem',
    lineHeight: '22px',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '30px',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleText: {
    color: colors.defaultYellow,
    fontSize: '3rem',
    lineHeight: '58px',
    fontWeight: 500,
  },
  backButtonStepContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '20px',
    marginTop: '25px',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '22px',
    width: 'fit-content',
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  maxWidthContainer: {
    width: '-webkit-fill-available',
    boxSizing: 'border-box',
  },
  errorButton: {
    width: '280px',
    height: '76px',
    background: colors.redButton,
    borderRadius: '15px',
    textTransform: 'none',
    cursor: 'pointer',
    color: colors.white,
    fontSize: '1.5rem',
    lineHeight: '30px',
    boxShadow: `0px 4px 4px ${colors.blackShadow_25}`,
    '&:hover': {
      background: colors.redButton,
      opacity: 0.9,
    },
    '&:active': {
      background: colors.redButton,
      opacity: 0.8,
    },
  },
  socialTitle: {
    fontSize: '3rem',
    fontWeight: 400,
    margin: '30px 0 5px',
    '&>span': {
      fontSize: '1.2rem',
      fontWeight: 300,
      marginLeft: 10,
    },
  },
  socialInputsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 20,
    '&>div': {
      marginTop: '10px',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  flex_column: {
    display: 'flex',
    flexDirection: 'column',
  },
})
