import SmallLogo from 'assets/icons/SmallLogo.png'
import SmartPadLogo from 'assets/icons/Logo.png'
import { PendingButton, WalletButton } from 'components/buttons'
import { useTransactions } from 'hooks/useTransactions'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useHistory } from 'react-router-dom'
import { POOLS, STAKE, STATISTICS } from 'routes'
import { Transaction } from 'utils/store'
import { useStyles } from './styles'
import { NETWORK_TYPE, Provider, networkType } from 'services/Provider'
import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'

interface HeaderProps {
  activeWeb3Account: string
  activateWeb3Account: () => void
  openModal: () => void
}

export const Header: FC<HeaderProps> = ({
  activeWeb3Account,
  activateWeb3Account,
  openModal
}: HeaderProps): JSX.Element => {
  const {
    wrap,
    logoContainer,
    buttonContainer,
    logoContainerMobile,
    navBarContainer,
    ulList,
    liItem,
    navLinkContainer,
    navLinkActiveContainer,
  } = useStyles()

  const { chainId } = useWeb3React<Web3Provider>()

  const transactions = useTransactions()
  const transactionCount = transactions.filter(tx => tx.status === 'pending').length
  const history = useHistory()
  const { t } = useTranslation()
  return (
    <header className={wrap}>
      <div onClick={() => history.push(POOLS)} className={logoContainer}>
        <img src={SmartPadLogo} />
      </div>
      <div onClick={() => history.push(POOLS)} className={logoContainerMobile}>
        <img src={SmallLogo} />
      </div>
      <nav className={navBarContainer}>
        <ul className={ulList}>
          <li className={liItem}>
            <NavLink to={POOLS} className={navLinkContainer} activeClassName={navLinkActiveContainer}>
              <span>{t('header_header_pools')}</span>
            </NavLink>
          </li>
          {(networkType(chainId) === NETWORK_TYPE.Mainnet || networkType(chainId) === NETWORK_TYPE.Polygon || networkType(chainId) === NETWORK_TYPE.Aurora) && (
          <li className={liItem}>
            {/* <NavLink to={STAKE} className={navLinkContainer} activeClassName={navLinkActiveContainer}>
              <span>{t('header_header_staking')}</span>
            </NavLink> */}
            <a rel="noopener noreferrer" className={navLinkContainer} href="https://v2.smartpad.network" target="_blank">
              <span>{t('header_header_staking')}</span>
            </a>
          </li>
          )}
          {networkType(chainId) === NETWORK_TYPE.Mainnet && (
          <li className={liItem}>
            <NavLink to={STATISTICS} className={navLinkContainer} activeClassName={navLinkActiveContainer}>
              <span>{t('header_header_claim')}</span>
            </NavLink>
          </li>
          )}
        </ul>
      </nav>

      <div className={buttonContainer}>
        {transactionCount !== 0 && (
          <PendingButton pendingCount={transactionCount} isActive={transactionCount !== 0} onClick={openModal} />
        )}
        <WalletButton activateWeb3Account={activateWeb3Account} activeWeb3Account={activeWeb3Account} />
      </div>
    </header>
  )
}
