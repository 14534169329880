import DateFnsUtils from '@date-io/date-fns'
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { MILLISECONDS_IN_DAY, MILLISECONDS_IN_MINUTE } from 'utils/dateFormatter'
import { useStyles } from './styles'
import { colors } from 'utils/mui'

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core'

export const customTheme = createMuiTheme({
  typography: {
    button: {
      fontSize: '1rem',
      fontWeight: 'bold'
    }
  },
  palette: {
    text: {
      primary: colors.defaultWhite,
    },
    primary: {
      main: colors.primaryYellowSecond,
    },
    secondary: {
      main: colors.defaultWhite,
    },
  },
})

const CustomDatePicker = ({
  date,
  setDate,
  startDate,
  setIsError,
}: {
  date: Date
  setDate: React.Dispatch<React.SetStateAction<Date>>
  startDate?: Date
  setIsError?: React.Dispatch<React.SetStateAction<boolean>>
}): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  const maxStartDate = Number(new Date()) + 60 * MILLISECONDS_IN_DAY
  const maxDate = startDate && Number(startDate) + 30 * MILLISECONDS_IN_DAY
  const minDate = startDate && Number(startDate) + 15 * MILLISECONDS_IN_MINUTE

  const handleError = () => {
    if (!setIsError || !startDate) return
    setIsError(startDate > date)
  }
  const isAMPM = !!new Intl.DateTimeFormat(undefined, { hour: 'numeric' }).format(0).match(/\s/)

  return (
    <div className={classes.mainContainer}>
      <MuiThemeProvider theme={customTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDateTimePicker
            InputProps={{ readOnly: true, disableUnderline: true }}
            ampm={isAMPM}
            value={date}
            onChange={(date) => setDate(date ?? new Date())}
            onError={handleError}
            minDate={startDate ? minDate : new Date()}
            maxDate={startDate ? maxDate : maxStartDate}
            minDateMessage={t('provide_pools_date_error')}
          />
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </div>
  )
}

export default CustomDatePicker
