import { Button } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import { useWeb3React } from '@web3-react/core'
import Loader from 'components/loader'
import { Pool } from 'hooks/usePoolDetails'
import { setTransaction, useTransactions } from 'hooks/useTransactions'
import { getWithdrawalAmountOf, useWithdraw } from 'hooks/useWithdraw'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Provider } from 'services/Provider'
import { Transaction } from 'utils/store'
import { formattedDecimals, Nullable } from 'utils/tsUtils'
import { useStyles } from './styles'

type ModalProps = {
  handleClose: (tx: Nullable<Transaction>) => void
  open: boolean
  pool: Pool
}

export const WithdrawModal = ({ handleClose, open, pool }: ModalProps): JSX.Element => {
  const {
    root,
    paperWidthSm,
    title,
    subTitle,
    dialogContent,
    contentContainer,
    blackBg,
    buttonContainer,
    defaultButton,
    amountContainer,
  } = useStyles()
  const { t } = useTranslation()
  const { account, library, chainId } = useWeb3React()
  const provider: Provider = new Provider(library.provider, chainId)
  const [isUser, setIsUser] = useState<boolean>(true)
  const [withdrawalSellAmount, setWithdrawalSellAmount] = useState<string>('')
  const [withdrawalBuyAmount, setWithdrawalBuyAmount] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const withdraw = useWithdraw(pool.contract)
  const transaction = useTransactions()
  const transactionCount = transaction.filter((tx) => tx.status === 'pending').length

  const buyToken = pool.buyToken
  const sellToken = pool.sellToken

  useEffect(() => {
    if (account == null) return
    setIsUser(pool.creator.toLowerCase() != account.toLowerCase())
    if (transactionCount) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [transaction])

  const withdrawToken = async () => {
    if (!account || !chainId || !provider || !provider.contractSet) return
    const tx = await withdraw(isUser, account, chainId, setTransaction)
    setIsLoading(true)
    handleClose(tx)
  }

  useEffect(() => {
    const allowance = async () => {
      if (!account) return
      const withdrawalResult = await getWithdrawalAmountOf(pool, isUser, account)

      if (!withdrawalResult) {
        return
      }
      setWithdrawalSellAmount(withdrawalResult.withdrawalSellAmount)
      setWithdrawalBuyAmount(withdrawalResult.withdrawalBuyAmount)
    }
    allowance()
  }, [transaction, isUser, buyToken, sellToken])

  const onClose = () => {
    handleClose(null)
  }

  return (
    <Dialog classes={{ paperWidthSm: paperWidthSm }} maxWidth="sm" onClose={onClose} open={open}>
      <div className={blackBg}>
        <MuiDialogTitle disableTypography className={root}>
          <Typography classes={{ root: title }}>{t('common_modal_title')}</Typography>
        </MuiDialogTitle>
        <MuiDialogContent classes={{ root: dialogContent }}>
          <div className={contentContainer}>
            <div className={amountContainer}>
              <span className={subTitle}>
                {formattedDecimals(withdrawalBuyAmount ?? '0', buyToken.decimals, buyToken.symbol).toString()}
              </span>
            </div>
            {!isUser ? (
              <div className={amountContainer}>
                <span className={subTitle}>
                  {formattedDecimals(withdrawalSellAmount ?? '0', sellToken.decimals, sellToken.symbol).toString()}
                </span>
              </div>
            ) : null}
            <div className={buttonContainer}>
              <Button
                className={defaultButton}
                onClick={withdrawToken}
                disabled={isLoading ? true : withdrawalSellAmount == '0' && withdrawalBuyAmount == '0'}
              >
                {isLoading ? <Loader /> : <span>{t('pool_card_withdraw_button')}</span>}
              </Button>
            </div>
          </div>
        </MuiDialogContent>
      </div>
    </Dialog>
  )
}
