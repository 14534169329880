import { abiERC20 } from 'contracts/web3'
import { AbiErc20 } from 'contracts/web3/typings/abi_erc20'
import { ethers } from 'ethers'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { chainIdMap, Provider, valueMap } from 'services/Provider'
import { createTransaction, Transaction } from 'utils/store'
import { BN, BNish, fromBNish, Nullable } from 'utils/tsUtils'
import { setTransaction } from './useTransactions'

export interface ERC20 {
  contract: AbiErc20
  address: string
  symbol: string
  name: string
  decimals: number
}

// [contractAddress][erc20]
const ERC20Cache: Record<string, Nullable<ERC20>> = {}

export function useERC20Contract(provider: Provider, contractAddress: Nullable<string>): Nullable<AbiErc20> {
  const [contract, setContract] = useState<AbiErc20 | null>(null)
  useEffect(() => {
    if (contractAddress) {
      const _contract = new provider.eth.Contract(abiERC20, contractAddress) as unknown as AbiErc20
      setContract(_contract)
    }
  }, [contractAddress])
  return contract
}

export function useERC20(provider: Provider, contractAddress: Nullable<string>): ERC20 | null {
  const contract = useERC20Contract(provider, contractAddress)
  const [ERC20, setERC20] = useState<ERC20 | null>(ERC20Cache[contractAddress ?? ''] ?? null)

  useEffect(() => {
    async function requestERC20Details(contract: AbiErc20, address: string) {
      let name = ''
      let symbol = ''
      let decimals = 18

      try {
        name = await contract.methods.name().call()
      } catch (e) {
        console.log('useERC20', e)
      }

      if (address == provider.contractSet.WETH) {
        symbol = valueMap[provider.currentNetwork]
      } else {
        try {
          symbol = await contract.methods.symbol().call()
        } catch (e) {
          console.log('useERC20', e)
        }
      }

      try {
        decimals = Number.parseInt(await contract.methods.decimals().call())
      } catch (e) {
        console.log('useERC20', e)
      }

      const erc20: ERC20 = {
        contract,
        address,
        symbol,
        decimals,
        name,
      }

      ERC20Cache[address] = erc20

      setERC20(erc20)
    }

    if (!ERC20 && contractAddress && contract) {
      requestERC20Details(contract, contractAddress)
    }
  }, [contractAddress, contract])

  return ERC20
}

// [erc20][owner][spender]
const allowanceCache: Record<string, Nullable<BN>> = {}
export type ApproveCallback = (spender: string, amount: BNish) => Promise<void>
export type AllowanceCallback = () => void

export function useAllowance(
  provider: Provider,
  ERC20: ERC20,
  owner: string,
  spender: string,
): [BNish | null, ApproveCallback, AllowanceCallback] {
  const cacheKey = `${ERC20.address}_${owner}_${spender}`
  const [allowance, setAllowance] = useState<BNish | null>(allowanceCache[cacheKey] ?? null)

  async function request() {
    try {
      const allowance = await ERC20.contract.methods.allowance(owner, spender).call()
      allowanceCache[cacheKey] = fromBNish(allowance)
      setAllowance(allowance)
    } catch (e) {
      console.log('useAllowance', e)
    }
  }

  const requestAllowance = useCallback<AllowanceCallback>(() => {
    request()
  }, [ERC20])

  const approve = useCallback<ApproveCallback>(
    async (spender, amount) => {
      await ERC20.contract.methods
        .approve(spender, amount.toString())
        .send({ from: owner })
        .on('transactionHash', function (hash: string) {
          const transaction: Transaction = createTransaction(
            hash,
            'eth',
            'Approve token',
            chainIdMap[provider.currentNetwork],
          )
          request()
          setTransaction(transaction)
        })
    },
    [ERC20],
  )

  useEffect(() => {
    request()
  }, [ERC20])

  return [allowance, approve, requestAllowance]
}

// [erc20][account]
const balanceCache: Record<string, Nullable<BNish>> = {}

export function useBalance(ERC20: ERC20, account: string): BNish | null {
  const cacheKey = `${ERC20.address}_${account}`
  const [balance, setBalance] = useState<BNish | null>(balanceCache[cacheKey] ?? null)
  useEffect(() => {
    async function request() {
      try {
        const balance = await ERC20.contract.methods.balanceOf(account).call()
        balanceCache[cacheKey] = balance
        setBalance(balance)

      } catch (e) {
        console.log('useBalance', e)
      }
    }
    request()
  }, [ERC20])

  return balance
}
