/* eslint-disable @typescript-eslint/no-var-requires */
export const abiPresale = require('./abi_presale.json')
export const abiVesting = require('./abi_vesting.json')
export const aPAD = require('./aPAD.json')
export const abiPoolFactory = require('./abi_pool_factory.json')
export const abiERC20 = require('./abi_erc20.json')
export const abiFixedPool = require('./abi_fixed_pool.json')
export const abiLinearPool = require('./abi_linear_pool.json')
export const abiTreeWhitelist = require('./abi_tree_whitelist.json')
export const abiWhitelistNoLimit = require('./abi_whitelist_no_limit.json')
