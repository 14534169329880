import { Button } from '@material-ui/core'
import { useWeb3React } from '@web3-react/core'
import { clientV2 } from 'apollo/client'
import { GET_ALL_POOL_ADDRESS, GET_CREATOR_POOLS } from 'apollo/queries'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { POOL_TYPES } from 'routes'
import { NETWORK_TYPE, Provider } from 'services/Provider'
import { CardComponent } from './card'
import { useStyles } from './styles'
import { getPoolsInfo } from 'hooks'

const CARDS_PER_ROW = 3

export interface PoolInterface {
  pool: string
  poolPrototype: string
}

const Pool = (): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [showUserPools, setShowUserPools] = useState(false)
  const history = useHistory()
  const [allPoolAddress, setAllPoolAddress] = useState<PoolInterface[]>([])
  const [userPoolAddress, setUserPoolAddress] = useState<PoolInterface[]>([])
  const [poolsToShow, setPoolsToShow] = useState<PoolInterface[]>([])

  const { account, library, chainId } = useWeb3React()
  const provider: Provider = new Provider(library.provider, chainId)

  const changeState = () => {
    setIsOpen(!isOpen)
  }
  const changeShowUserPoolsState = () => {
    return;
    // setShowUserPools(!showUserPools)
    // setPoolsToShow(!showUserPools ? userPoolAddress : allPoolAddress)
  }

  async function fetchPoolAddress() {
    const provider: Provider = new Provider(library.provider, chainId)

    try {
      let pools:any = [];

      if (provider.currentNetwork === NETWORK_TYPE.Mainnet) { //NOTE: use thegraph
        const referrer = await clientV2(provider).query({
          query: GET_ALL_POOL_ADDRESS,
        })
        pools = referrer.data.poolEvents.map((el: PoolInterface): PoolInterface => {
          return {
            pool: el.pool,
            poolPrototype: el.poolPrototype,
          }
        })

      } else { //NOTE: use PoolFactory pools info
        pools = await getPoolsInfo(provider)
      }

      setAllPoolAddress(pools)
      setPoolsToShow(pools)
    } catch (e) {
      console.log(e)
    }
  }

  // async function fetchUserPoolAddress() {
  //   try {
  //     if (!account) return
  //     const referrer = await clientV2(provider).query({
  //       query: GET_CREATOR_POOLS(account),
  //     })

  //     setUserPoolAddress(
  //       referrer.data.poolEvents.map((el: PoolInterface): PoolInterface => {
  //         return {
  //           pool: el.pool,
  //           poolPrototype: el.poolPrototype,
  //         }
  //       }),
  //     )
  //   } catch (e) {
  //     console.log(e)
  //   }
  // }

  useEffect(() => {
    fetchPoolAddress()
    // fetchUserPoolAddress()
  }, [chainId])

  return (
    <div className={classes.rootContainer}>
      <div className={classes.contentContainer}>
        <div className={classes.headingContainer}>
          <div className={classes.headingLeft}>
            <span className={classes.headingLeftTitle}>{t('pool_title')} </span>
            <span className={classes.headingLeftText}>{t('pool_page_info')}</span>
          </div>
          { false && (
          <div className={classes.headingRight}>
            <Button onClick={() => history.push(POOL_TYPES)} classes={{ root: classes.secondaryButton }}>
              {t('pool_create_pool_button')}
            </Button>
          </div>
          )}
        </div>

        { false &&
          <div className={classes.filterWrapper}>
            <span className={classes.filterText}>{t('pool_filter')}</span>
            <Button
              classes={{ root: showUserPools ? classes.filterButtonClicked : classes.filterButton }}
              onClick={changeShowUserPoolsState}
            >
              {t('pool_my_pools')}
            </Button>
          </div>
        }

        {poolsToShow && (
          <div className={classes.cardWrapper}>
            { isOpen
              ? poolsToShow.map((el) => (
                  <CardComponent key={el.pool} address={el.pool} poolPrototype={el.poolPrototype} />
                ))
              : poolsToShow
                  .slice(0, CARDS_PER_ROW)
                  .map((el) => <CardComponent key={el.pool} address={el.pool} poolPrototype={el.poolPrototype} />)}
          </div>
        )}

        {poolsToShow.length > CARDS_PER_ROW && (
          <Button classes={{ root: classes.primaryButton }} onClick={changeState}>
            {!isOpen ? t('pool_view_all_pools') : t('pool_hide_all_pools')}
          </Button>
        )}

        {!poolsToShow.length && (
          <div className={classes.defaultTitleBox}>
            <span className={classes.defaultTitle}>{`IDOs on ${provider.currentNetwork} will be available soon!`}</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default Pool
