import React, { useContext, useState } from 'react'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'
import { POOL_TYPES, PROVIDE_POOLS_INFO, TOKEN_INFO } from 'routes'
import TitleHeader from 'components/title_header'
import { StoreContext } from 'utils/store'
import { URLInput } from 'components/inputs/link_inputs'
import { useHistory } from 'react-router'
import { Button } from '@material-ui/core'
import CSVTable from 'pages/whitelist/CSVTable'

const Whitelist = (): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const store = useContext(StoreContext)
  const [isError, setIsError] = useState(false)

  if (
    !store.poolType ||
    !store.listOption ||
    !store.poolTitle ||
    !store.urls[0] ||
    !store.urls[1] ||
    !store.urls[2] ||
    !store.urls[3] ||
    !store.startDate ||
    !store.endDate
  )
    history.push(POOL_TYPES)

  return (
    <div className={classes.rootContainer}>
      <TitleHeader
        title={t('whitelist_title')}
        info={t('whitelist_title_info')}
        path={PROVIDE_POOLS_INFO}
        currentStep={'4'}
        buttonTitle={t('whitelist_back_button')}
      />
      <div className={classes.contentContainer}>
        <URLInput
          value={store.whiteListUrl}
          setValue={store.setWhiteListUrl}
          placeholder={t('whitelist_placeholder_whitelist')}
          propertyWidth={true}
          setIsError={setIsError}
          csvCheck={true}
        />

        <div className={classes.buttonContainer}>
          <Button
            onClick={() => history.push(TOKEN_INFO)}
            className={classes.defaultButton}
            disabled={store.whiteListUrl.length === 0 || isError}
          >
            {t('common_continue_button')}
          </Button>
        </div>
        {store.whiteListUrl && !isError && <CSVTable list={store.whiteListUrl} setIsError={setIsError} />}
      </div>
    </div>
  )
}

export default Whitelist
