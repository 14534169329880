import { AbiFixedPool } from 'contracts/web3/typings/abi_fixed_pool'
import { AbiLinearPool } from 'contracts/web3/typings/abi_linear_pool'
import { useCallback } from 'react'
import { createTransaction, Transaction } from 'utils/store'
import { fromBNish } from 'utils/tsUtils'
import { Pool } from './usePoolDetails'

type Withdraw = (
  isUser: boolean,
  account: string,
  chainId: number,
  updateTransactions: (transaction: Transaction) => void,
) => Promise<Transaction>

export function useWithdraw(contract: AbiFixedPool | AbiLinearPool): Withdraw {
  return useCallback<Withdraw>(async (isUser, account, chainId, updateTransactions) => {
    return new Promise((resolve) => {
      if (isUser) {
        contract.methods
          .withdraw()
          .send({ from: account })
          .on('transactionHash', function (hash: string) {
            console.log('transactionHash', hash)
            const transaction: Transaction = createTransaction(hash, 'eth', 'Withdraw', chainId)
            updateTransactions(transaction)
            resolve(transaction)
          })
      } else {
        contract.methods
          .creatorWithdraw()
          .send({ from: account })
          .on('transactionHash', function (hash: string) {
            console.log('transactionHash', hash)
            const transaction: Transaction = createTransaction(hash, 'eth', 'Withdraw', chainId)
            updateTransactions(transaction)
            resolve(transaction)
          })
      }
    })
  }, [])
}

type WithdrawalResult = { withdrawalSellAmount: string; withdrawalBuyAmount: string }

export async function getWithdrawalAmountOf(
  pool: Pool,
  isUser: boolean,
  account: string,
): Promise<WithdrawalResult | undefined> {
  if (isUser) {
    return {
      withdrawalSellAmount: '0',
      withdrawalBuyAmount: await pool.contract.methods.withdrawalAmountOf(account).call(),
    }
  } else {
    const creatorWithdrawnBuyAmount = await pool.contract.methods.creatorWithdrawnBuyAmount().call()
    let buyLeft
    const buyTokenBalance = pool.distributedBuyAmount
    if (creatorWithdrawnBuyAmount) {
      buyLeft = fromBNish('0')
    } else {
      buyLeft = fromBNish(buyTokenBalance ?? '0').sub(fromBNish(pool.totalBuyAmount))
    }

    return {
      withdrawalSellAmount: await pool.contract.methods.withdrawalAmountOfCreator().call(),
      withdrawalBuyAmount: buyLeft.toString(),
    }
  }
}
