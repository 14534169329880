import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient, DefaultOptions } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { Provider, subgraphLink } from 'services/Provider'

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

const FIRST_SUBGRAPH_URL = 'https://api.thegraph.com/subgraphs/name/meold/smartpad'

export const client = new ApolloClient({
  link: new HttpLink({
    uri: FIRST_SUBGRAPH_URL,
  }),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
})

export const clientV2 = (provider: Provider) =>
  new ApolloClient({
    link: new HttpLink({
      uri: subgraphLink[provider.currentNetwork],
    }),
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions,
  })
