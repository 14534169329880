import { ethers } from 'ethers'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Provider, valueMap } from 'services/Provider'
import { BNish, Nullable } from 'utils/tsUtils'
import { useBalance, ERC20, ApproveCallback, AllowanceCallback, useAllowance } from './useERC20'

const balanceCache: Record<string, Nullable<BNish>> = {}

export function useValueBalance(provider: Provider, account: string): BNish | null {
  const cacheKey = `${valueMap[provider.currentNetwork]}_${account}`
  const [valueBalance, setValueBalance] = useState<BNish | null>(balanceCache[cacheKey] ?? null)
  useEffect(() => {
    async function request() {
      try {
        const _balance = await provider.eth.getBalance(account)
        balanceCache[cacheKey] = _balance
        setValueBalance(_balance)
      } catch (e) {
        console.log('useValueBalance', e)
      }
    }
    request()
  }, [])

  return valueBalance
}

export function useAnyBalance(provider: Provider, ERC20: ERC20, account: string): BNish | null {
  const erc20Balance = useBalance(ERC20, account)
  const valueBalance = useValueBalance(provider, account)
  return useMemo(
    () => (ERC20.address == provider.contractSet.WETH ? valueBalance : erc20Balance),
    [ERC20, erc20Balance, valueBalance],
  )
}

export function useIsValue(provider: Provider, ERC20: ERC20): boolean {
  return useMemo(() => ERC20.address == provider.contractSet.WETH, [ERC20])
}

export function useSymbol(provider: Provider, ERC20: ERC20): string | null {
  return useMemo(() => (ERC20.address === provider.contractSet.WETH ? valueMap[provider.currentNetwork] : ERC20.symbol), [ERC20])
}
