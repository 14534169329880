import { Web3Provider } from '@ethersproject/providers'
import { useWeb3React as useWeb3ReactCore } from '@web3-react/core'
import { setTransaction, useTransactions } from 'hooks/useTransactions'
import { useContext, useEffect } from 'react'
import { BlockInfo, StoreContext, Transaction } from 'utils/store'
import { useRefresh } from './RefreshContext'

export function shouldCheck(lastBlockNumber: BlockInfo, tx: Transaction): boolean {
  if (!tx.lastCheckedBlockNumber || !lastBlockNumber.data[tx.chain]) return true
  const activeChainIndex = lastBlockNumber.data[tx.chain].findIndex(
    (el: { chainId: number; blockNumber: number }) => el.chainId === tx.chainId,
  )

  if (activeChainIndex < 0) return false

  const blocksSinceCheck = lastBlockNumber.data[tx.chain][activeChainIndex].blockNumber - tx.lastCheckedBlockNumber
  if (blocksSinceCheck < 1) return false
  const minutesPending = (new Date().getTime() - tx.addedTime) / 1000 / 60
  if (minutesPending > 60) {
    // every 10 blocks if pending for longer than an hour
    return blocksSinceCheck > 9
  } else if (minutesPending > 5) {
    // every 3 blocks if pending more than 5 minutes
    return blocksSinceCheck > 2
  } else {
    // otherwise every block
    return true
  }
}

export default function TransactionUpdater(): null {
  const { chainId, library } = useWeb3ReactCore<Web3Provider>()
  const { blockNumberInfo } = useContext(StoreContext)
  const transactions = useTransactions()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    async function updateTransactionsStatuses() {
      const activeTransactions: Transaction[] = transactions
        .filter((tx) => tx.status === 'pending')
        .filter((tx) => shouldCheck(blockNumberInfo, tx))

      if (activeTransactions.length) {
        activeTransactions
          .filter((tx) => tx.chain === 'eth')
          .map(async (tx: Transaction) => {
            const activeChainIndex = blockNumberInfo.data.eth.findIndex(
              (el: { chainId: number; blockNumber: number }) => el.chainId === tx.chainId,
            )
            
            const receipt = await library?.getTransactionReceipt(tx.hash)
           
            if (!chainId || !library || !blockNumberInfo.data.eth || activeChainIndex < 0) return

            tx.lastCheckedBlockNumber = blockNumberInfo.data.eth[activeChainIndex].blockNumber
      
            if (receipt) {
              tx.receipt = receipt
              tx.status = receipt.status ? 'success' : 'failed'
            }
            setTransaction(tx)
          })
      }
    }
    updateTransactionsStatuses()
  }, [fastRefresh, chainId, library])
  return null
}
