import { Button, IconButton } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { useStyles } from './styles'
import CopyIcon from 'assets/icons/CopyIcon.svg'
import { notify } from 'App'
import { useTranslation } from 'react-i18next'
import { transformAddress } from 'utils/NumberFormatter'

type ModalProps = {
  handleClose: () => void
  onClick: () => void
  open: boolean
  titleText: JSX.Element | string
  infoText: string
  buttonText: string
  buttonStyle: string
  transaction?: string
}

export const WarningModal = ({
  handleClose,
  onClick,
  open,
  titleText,
  infoText,
  buttonText,
  buttonStyle,
  transaction,
}: ModalProps): JSX.Element => {
  const {
    root,
    paperWidthSm,
    title,
    subtitle,
    dialogContent,
    whiteText,
    contentContainer,
    addressBox,
    addressText,
    blackContainer,
  } = useStyles()
  const { t } = useTranslation()

  const copyToClipboard = (content: string) => {
    navigator.clipboard.writeText(content)
    notify(t('modal_transactions_transaction_copied'))
  }

  return (
    <Dialog classes={{ paperWidthSm: paperWidthSm }} maxWidth="sm" onClose={handleClose} open={open}>
      <MuiDialogTitle disableTypography className={root}>
        <Typography classes={{ root: title }}>{titleText}</Typography>
      </MuiDialogTitle>
      <MuiDialogContent classes={{ root: dialogContent }}>
        <div className={contentContainer}>
          <span className={subtitle}>{infoText}</span>
          {transaction && (
            <div className={blackContainer}>
              <div className={addressBox} onClick={() => copyToClipboard(transaction)}>
                <span className={addressText}>{transformAddress(transaction)}</span>
                <IconButton>
                  <img src={CopyIcon} />
                </IconButton>
              </div>
            </div>
          )}

          <Button className={buttonStyle} onClick={onClick}>
            <span>{buttonText}</span>
          </Button>
        </div>
      </MuiDialogContent>
    </Dialog>
  )
}
