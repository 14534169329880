import Button, { ButtonProps as MuiButtonProps } from '@material-ui/core/Button'
import React, { FC } from 'react'
import { useStyles } from './styles'
import Arrow from 'assets/icons/Arrow.svg'
import { useHistory } from 'react-router'

interface ButtonProps extends MuiButtonProps {
  path: string
  title: string
}

const BackButton: FC<ButtonProps> = ({ path, title }: ButtonProps) => {
  const { backButton, backButtonContainer } = useStyles()
  const history = useHistory()

  return (
    <Button
      onClick={() => history.push(path)}
      className={backButton}
      style={{ height: title.length > 22 ? '60px' : '40px' }}
    >
      <div className={backButtonContainer}>
        <img src={Arrow} alt="back-button-icon" />
        <p>{title}</p>
      </div>
    </Button>
  )
}

export default BackButton
