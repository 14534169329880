import React from 'react'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'
import { BackButton } from 'components/buttons'

interface TitleHeaderProps {
  title: string
  info: string
  currentStep: string
  path: string
  buttonTitle: string
  isFee?: boolean
  feeValue?: null | string
}

const TitleHeader = ({
  title,
  info,
  currentStep,
  path,
  isFee,
  feeValue,
  buttonTitle,
}: TitleHeaderProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.contentContainer}>
      <div className={classes.rowContainer}>
        <div className={classes.titleContainer} style={isFee ? { padding: '0px' } : { paddingRight: '300px' }}>
          <span className={classes.titleText}>{title} </span>
          <span className={classes.infoText}>{info} </span>
        </div>

        {isFee && feeValue && (
          <div className={classes.rightContainer}>
            <span>{t('common_pool_creation_fee')}</span>
            <div className={classes.feeContainer}>
              <span className={classes.goldText}> {t('common_pad_value', { amount: feeValue })} </span>
            </div>
          </div>
        )}
      </div>

      <div className={classes.backButtonStepContainer}>
        <BackButton path={path} title={buttonTitle} />
        <span className={classes.stepsText}>{t('common_step', { current: currentStep, all: 6 })}</span>
      </div>
    </div>
  )
}

export default TitleHeader
