/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useContext, useEffect, useState } from 'react'
import { Web3Provider } from '@ethersproject/providers'
import { useWeb3React } from '@web3-react/core'
import { injectedConnector, networkType, NETWORK_TYPE } from 'services/Provider'
import { StoreContext } from 'utils/store'
import { Header } from './header'
import { TransactionModal } from 'components/modals/modal_transaction'
import Footer from 'components/footer'
import { Button } from '@material-ui/core'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const Layout = ({ children }: { children: JSX.Element }) => {
  const { web3Account, setWeb3Account, transactionModalOpen, setTransactionModalOpen } = useContext(StoreContext)
  const { t } = useTranslation()
  const { account, activate, chainId } = useWeb3React<Web3Provider>()
  const { content, contentWrap, defaultButton, defaultTitleBox, defaultTitle, switchNetworkBox } = useStyles()
  const [isMainnet, setMainnet] = useState(networkType(chainId) !== null)
  
  useEffect(() => {
    setMainnet(networkType(chainId) !== null)
  }, [chainId])

  useEffect(() => {
    setWeb3Account(account)
  }, [account])

  return (
    <div className={contentWrap}>
      <Header
        activateWeb3Account={() => activate(injectedConnector)}
        activeWeb3Account={web3Account ? web3Account : ''}
        openModal={setTransactionModalOpen}
      />
      <TransactionModal handleClose={() => setTransactionModalOpen(false)} open={transactionModalOpen} />
      {account && isMainnet ? (
        <div className={content}>{children}</div>
      ) : !isMainnet ? (
        <div className={switchNetworkBox}>
          <span
            className={defaultTitle}
          >{`Hi, there. Switch network to ${NETWORK_TYPE.Mainnet} / ${NETWORK_TYPE.Aurora} / ${NETWORK_TYPE.Polygon}`}</span>
        </div>
      ) : (
        <div className={defaultTitleBox}>
          <>
            <span className={defaultTitle}>{'Hi, there. Connect your wallet!'}</span>
            <Button classes={{ root: defaultButton }} onClick={() => activate(injectedConnector)}>
              {t('common_connect_wallet')}
            </Button>
          </>
        </div>
      )}

      <Footer />
    </div>
  )
}
