import { Button } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import { useWeb3React } from '@web3-react/core'
import { AbiFixedPool } from 'contracts/web3/typings/abi_fixed_pool'
import { AbiLinearPool } from 'contracts/web3/typings/abi_linear_pool'
import { useDeletedPool } from 'hooks/useEditPool'
import { setTransaction } from 'hooks/useTransactions'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Transaction } from 'utils/store'
import { Nullable } from 'utils/tsUtils'
import { useStyles } from './styles'

type ModalProps = {
  handleClose: (tx: Nullable<Transaction>) => void
  open: boolean
  poolContract: AbiFixedPool | AbiLinearPool
}

export const DeleteModal = ({ handleClose, open, poolContract }: ModalProps): JSX.Element => {
  const { root, paperWidthSm, title, subtitle, dialogContent, contentContainer, redButton } = useStyles()
  const { t } = useTranslation()
  const { account, chainId } = useWeb3React()

  const deletePool = useDeletedPool(poolContract)

  const destructPool = async () => {
    if (!account || !chainId) return
    const tx = await deletePool(account, chainId, setTransaction)
    handleClose(tx)
  }
  const onClose = () => {
    handleClose(null)
  }

  return (
    <Dialog classes={{ paperWidthSm: paperWidthSm }} maxWidth="sm" onClose={onClose} open={open}>
      <MuiDialogTitle disableTypography className={root}>
        <Typography classes={{ root: title }}>{t('pool_edit_modal_title')}</Typography>
      </MuiDialogTitle>
      <MuiDialogContent classes={{ root: dialogContent }}>
        <div className={contentContainer}>
          <span className={subtitle}>{t('pool_edit_modal_info')}</span>

          <Button className={redButton} onClick={destructPool}>
            <span>{t('pool_edit_delete_pool_button')}</span>
          </Button>
        </div>
      </MuiDialogContent>
    </Dialog>
  )
}
