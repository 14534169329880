import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  progress: {
    backgroundColor: colors.defaultGrey,
    borderRadius: '20px',
    height: '30px',
    width: '100%',
    position: 'relative',
    margin: '15px 0 40px',
    display: 'flex',
    alignItems: 'center',
  },
  progressDone: {
    background: colors.yellowLinearGradient,
    boxShadow: `0px 2px 2px ${colors.blackShadow_15}`,
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '100%',
    transition: '1s ease 0.3s',
  },
  progressValue: {
    color: colors.defaultWhite,
    fontSize: '0.88rem',
    fontWeight: 700,
    margin: '0 15px',
  },
})
